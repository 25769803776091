import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {FileDetails} from "../file-details.model";
import { Configuration} from "../api/configuration";
import {BASE_PATH} from "../api/variables";

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private  basePath = "http://localhost:8080"
  public configuration = new Configuration();
  constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  upload(file: File, path: String): Observable<FileDetails> {

    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient.put<FileDetails>(`${this.basePath}/${path}`, formData);
  }
}
