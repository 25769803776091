<div class="wrapper">
  <div class="headingContainer">
    <h1>Shortcuts der Organisation</h1>
  </div>
  <h3> Die Shortcuts gelten für Ihre Organisation. Änderungen betreffen auch alle anderen Nutzer*innen.</h3>
<mat-form-field>
  <mat-label>Shourtcuts durchsuchen</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="nach Name oder Nummer suchen">
</mat-form-field>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? toggleAllRows() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
      <td mat-cell *matCellDef="let element"> {{element.number}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Aktionen </th>
        <td mat-cell *matCellDef="let element">
            <button mat-mini-fab color="accent" (click)="edit(element)">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-mini-fab color="warn" (click)="delete(element)">
                <mat-icon>delete</mat-icon>
            </button>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="selection.toggle(row)">
    </tr>
  </table>

<div class="singleButtonContainer">
  <button mat-raised-button color="primary" (click)="createNewSingle()">
      neue Shourtcut erstellen <mat-icon>add</mat-icon>
  </button>
</div>
</div>
