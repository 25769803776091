<div class="wrapper">
    <div class="headingContainer">
    <h1>{{getHeading()}}</h1>
    </div>
    <form #form="ngForm" (ngSubmit)="save()" name="form">
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput required minlength="2" maxlength="50" type="text" #name name="name" ngModel>
            <mat-hint>Zwischen 2 und 50 Zeichen</mat-hint>
            <mat-error *ngIf="form && form.controls['name'] && form.controls['name'].hasError('required')">
                Name <strong>benötigt</strong>
            </mat-error>
            <mat-error *ngIf="form && form.controls['name'] && !form.controls['name'].hasError('required')
            && (form.controls['name'].hasError('minlength') || form.controls['name'].hasError('maxlength'))">
                Stellen Sie sicher, dass zwischen 2 und 50 Zeichen eingegeben wurden
            </mat-error>
        </mat-form-field>
        <div class="buttonContainer">
            <button (click)="cancel()" mat-flat-button color="accent" type="button">
                Abbrechen
            </button>
            <button mat-flat-button color="primary" type="submit">
                Speichern
            </button>
        </div>
    </form>
</div>

