<div class="wrapper">
<div class="headingContainer" xmlns:th="http://www.w3.org/1999/xhtml">
    <h1>Organisation: {{displayName}}</h1>
</div>

<form #orgForm="ngForm" (ngSubmit)="save()" name="orgForm">
    <mat-form-field>
        <mat-label>Organisationsname</mat-label>
        <input matInput required minlength="4" maxlength="24" type="text" #orgName name="orgName" [(ngModel)]="organisation.name">
        <mat-hint>Zwischen 4 und 24 Zeichen benötigt</mat-hint>
        <mat-error *ngIf="form && form.controls['orgName'] && form.controls['orgName'].hasError('required')">
            Name <strong>benötigt</strong>
        </mat-error>
        <mat-error *ngIf="form && form.controls['orgName'] && !form.controls['orgName'].hasError('required')
        && (form.controls['orgName'].hasError('minlength') || form.controls['orgName'].hasError('maxlength'))">
            Zwischen 4 und 24 Zeichen benötigt
        </mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Theme</mat-label>
        <mat-select #theme name="theme" [(ngModel)]="organisation.theme.id">
          <mat-option (click)="changeTheme(theme.scssClassName)" *ngFor="let theme of themes" value="{{theme.id}}">{{theme.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    <div class="buttonContainer">
        <button mat-flat-button color="primary" type="submit">
           Speichern
        </button>
    </div>
</form>



<form #picForm="ngForm" (ngSubmit)="uploadFile()" name="picForm">
  <div>
  <div>
    <mat-label>Organisationslogo</mat-label>
    <input class="form-control form-control-lg" id="selectLogo" type="file" accept="image/*"  #orgLogo name="orgLogo" (change)="selectFile($event)">
    <mat-hint>PNG-Bild mit der Auflösung von 300px x 300px. Maximal 1 MB.</mat-hint>
 </div>
  <div class="buttonPicContainer">
    <button mat-flat-button color="primary" type="submit">
      Speichern
    </button>
  </div>
  </div>
</form>

<div class="container">
    <div class="mt-5 row justify-content-center">
        <div class="col-10 mb-3">
            <ul>
                <li *ngFor="let link of fileUris">
                    <a href="{{ link }}" target="_blank">{{ link }}</a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="functionalityButtons">
    <button mat-raised-button color="accent" type="button" (click)="addUser()">Benutzer hinzufügen</button>
</div>
<app-user-list [organisationId]="organisation.id"></app-user-list>
</div>
