import { Component, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationServiceService } from 'src/app/services/notification-service.service';

@Component({
  selector: 'app-note-add-dialog',
  templateUrl: './note-add-dialog.component.html',
  styleUrls: ['./note-add-dialog.component.scss']
})
export class NoteAddDialogComponent {

  @ViewChild('form') form: NgForm;

  constructor(public dialogRef: MatDialogRef<NoteAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NoteAddDialogData, private notificationService: NotificationServiceService){}


  ngAfterViewInit() {
    if(this.data?.text) {
      setTimeout(() => {
        this.form.controls['text'].setValue(this.data.text)
      }, 0);
    }
  }

  getHeader() {
    return this.data ? 'Notiz bearbeiten' : 'Notiz hinzufügen';
  }

  cancel() {
    this.dialogRef.close(false);
  }

  save() {
    if(!this.form.valid) {
      this.notificationService.open('Bitte stellen Sie sicher, dass alle felder befüllt sind', 'ok', 15);
      return;
    }

    this.dialogRef.close(this.form.controls['text'].value);
  }


}

export interface NoteAddDialogData {
  text?: string
}
