<div class="wrapper">
    <div class="headingContainer">
    <h1>{{getHeading()}}</h1>
    </div>
    <form #form="ngForm" name="form">
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput required minlength="2" maxlength="50" type="text" #name name="name" ngModel>
            <mat-error *ngIf="form && form.controls['name'] && form.controls['name'].hasError('required')">
                Name <strong>benötigt</strong>
            </mat-error>
            <mat-error *ngIf="form && form.controls['name'] && !form.controls['name'].hasError('required')
            && (form.controls['name'].hasError('minlength') || form.controls['name'].hasError('maxlength'))">
                Zwischen 2 und 50 Zeichen eingeben
            </mat-error>
        </mat-form-field>

        <div class="inputWithButton">
            <mat-form-field>
                <mat-label>Kategorien</mat-label>
                <input matInput minlength="2" maxlength="50" type="text" #category name="category" ngModel>
                <mat-error *ngIf="form && form.controls['category'] && form.controls['category'].hasError('required')
                    && categories.length === 0">
                    Mindestens eine Kategorie <strong>benötigt</strong>
                </mat-error>
                <mat-error *ngIf="form && form.controls['category'] && !form.controls['category'].hasError('required')
                && (form.controls['category'].hasError('minlength') || form.controls['category'].hasError('maxlength'))">
                    Zwischen 2 und 50 Zeichen eingeben
                </mat-error>
            </mat-form-field>
            <button mat-mini-fab color="primary" (click)="addCategory(category)">
                <mat-icon>check</mat-icon>
            </button>
            </div>
            <mat-table
            #table
            [dataSource]="categories"
            class="mat-elevation-z8">    
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.name}} </mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef> Aktion </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button mat-mini-fab color="warn" (click)="deleteCategory(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
    
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row
                *matRowDef="let row; columns: displayedColumns;"
            ></mat-row>
            </mat-table>
    
        <div class="buttonContainer">
            <button (click)="cancel()" mat-flat-button color="accent" type="button">
               Abbrechen
            </button>
            <button mat-flat-button color="primary" type="submit" (click)="save()">
               Speichern
            </button>
        </div>
    </form>
    
</div>
