import { Injectable } from '@angular/core';
import { ThemeDto } from '../api/model/themeDto';

@Injectable({
  providedIn: 'root'
})
export class ThemeServiceService {
  private activeTheme: string = "";
  private themeToChose: string = "";
  constructor() { }

  changeTheme(theme){
    document.body.classList.forEach(item=>{
      if(item.endsWith('theme')) {
          document.body.classList.remove(item) ;
      }
    });
    document.querySelectorAll('h2').forEach(x => {
      x.classList.forEach(y => {
        if(y.endsWith('theme')) {
          x.classList.remove(theme);
        }
      });
    });
      
    document.querySelectorAll('h2').forEach(x => x.classList.add(theme));
    this.themeToChose = theme;
    document.body.classList.add(this.themeToChose);
  }

  setGetActiveTheme(newActiveTheme){
    localStorage.setItem('activeTheme', newActiveTheme);
    return newActiveTheme;
  }

  getActiveTheme(){
    localStorage.getItem('activeTheme');
    return (localStorage.getItem('activeTheme')==undefined)?"fair-theme":localStorage.getItem('activeTheme');
  }

  getActiveThemeProfile(){
    return localStorage.getItem('themeProfile');
  }

  setActiveThemeProfile(profile){
    return localStorage.setItem('themeProfile', profile);
  }

  getChosenTheme(){
    return this.themeToChose;
  }
}
