<div class="reqWrapper">
    <p>Passwort benötigt:</p>
    <ul>
        <li>Mindestens ein Großbuchstabe</li>
        <li>Mindestens ein Kleinbuchstabe</li>
        <li>Mindestens eine Zahl</li>
        <li>Mindestens 8 Zeichen lang</li>
    </ul>
</div>


<form #form="ngForm" (ngSubmit)="save()" name="form">
    <mat-form-field>
        <mat-label>Neues Passwort</mat-label>
        <input matInput required minlength="8" type="password" #pw1 name="pw1" ngModel>
        <button matSuffix mat-icon-button aria-label="switch password visibility" type="button" (click)="switchPwVisibility()">
            <mat-icon>{{visibilityIcon}}</mat-icon>
        </button>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Passwort bestätigen</mat-label>
        <input matInput required minlength="8" type="password" #pw2 name="pw2" ngModel>
        <button matSuffix mat-icon-button aria-label="switch password visibility" type="button" (click)="switchPwVisibility()">
            <mat-icon>{{visibilityIcon}}</mat-icon>
        </button>
    </mat-form-field>

    <div class="buttonContainer">
        <button (click)="cancel()" mat-flat-button color="accent" type="reset">
           Abbrechen
        </button>
        <button mat-flat-button color="primary" type="submit">
           Speichern
        </button>
    </div>

</form>
