<div class="wrapper">
    <div class="buttonContainer">
        <h1>Sitzungen</h1>
        <button mat-raised-button color="primary" routerLink="/log/cross-compare">
            alle Sitzungen<mat-icon>bar_chart</mat-icon>
        </button>
    </div>
    <mat-form-field>
        <mat-label>Sitzungen durchsuchen</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Mit Name suchen" #input>
    </mat-form-field>
    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="datasource" #tableSort="matSort" matSort #table>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let meeting"> {{meeting?.name}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Aktionen </th>
                <td mat-cell *matCellDef="let meeting">
                    <button mat-mini-fab color="accent" (click)="createLog(meeting.id, meeting.name)" *ngIf="meeting?.idlog===undefined" matTooltip="Persönliches Log erstellen">
                        <mat-icon>play_circle</mat-icon>
                    </button>
                    <button mat-mini-fab color="accent" [routerLink]="['../meeting',meeting.id]" *ngIf="getAllLogsForMeeting(meeting)>0" matTooltip="Alle Protokolle">
                        <mat-icon>info</mat-icon>
                    </button>
                    <button mat-mini-fab color="accent" [routerLink]="['../log',meeting.idlog]" *ngIf="logIsRunning(meeting.logState)" matTooltip="Mein Protokoll">
                        <mat-icon>border_color</mat-icon>
                    </button>
                    <button mat-mini-fab color="accent" *ngIf="isMeetingPublic(meeting)" (click)="openPublicDialog(meeting)" matTooltip="Öffentlicher Link">
                        <mat-icon>public</mat-icon>
                    </button>
                    <button mat-mini-fab color="accent" *ngIf="getAllLogsForMeeting(meeting)===0" (click)="updateMeeting(meeting.id)" matTooltip="Bearbeiten">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-mini-fab color="warn" *ngIf="getAllLogsForMeeting(meeting)===0" (click)="deleteMeeting(meeting.id, meeting.name)" matTooltip="Löschen">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">Keine Einträge gefunden</td>
            </tr>
        </table>
        <mat-paginator #paginator="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="select page"></mat-paginator>
    </div>
    <div class="singleButtonContainer">
        <button mat-raised-button color="primary" (click)="addMeeting()">
            Neue Sitzung hinzufügen <mat-icon>add</mat-icon>
        </button>
    </div>
</div>
