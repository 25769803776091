import { Component, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UserDetailDto } from 'src/app/api/model/userDetailDto';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NotificationServiceService } from 'src/app/services/notification-service.service';
import { MatDialog } from '@angular/material/dialog';
import { PasswordDialogComponent } from '../password-dialog/password-dialog.component';
import { NgForm } from '@angular/forms';
import { ThemeDto } from 'src/app/api/model/themeDto';
import { CreateAndUpdateUserDto } from 'src/app/api/model/createAndUpdateUserDto';
import { ConfirmDialogComponent, ConfirmDialogData } from '../confirm-dialog/confirm-dialog.component';
import { ValidatorService } from 'src/app/services/validator.service';
import { ThemeServiceService } from 'src/app/services/theme-service.service';
import { LoginEndpointService } from 'src/app/api/api/loginEndpoint.service';
import { UserEndpointService } from 'src/app/api/api/userEndpoint.service';
import { ThemeEndpointService } from 'src/app/api/api/themeEndpoint.service';

@Component({
  selector: 'app-userdetailview',
  templateUrl: './userdetailview.component.html',
  styleUrls: ['./userdetailview.component.scss']
})
export class UserdetailviewComponent {
  mode: UserCreateUpdateMode = UserCreateUpdateMode.CREATE;
  isLastAdminOfOrg: boolean = false;

  selfTheme: ThemeDto;

  defaultTheme: ThemeDto = {
    id: null,
    name: 'notatheme',
    scssClassName: 'notatheme'
  };

  user: UserDetailDto = {
    username: '',
    theme: this.defaultTheme,
    role: UserDetailDto.RoleEnum.USER,
    organisation: {
      id: 'notAnId',
      name: 'Loading...',
      theme: null
    }
  };

  @ViewChild('userForm') form: NgForm;
  @ViewChild('password', {static: false}) pwInput: ElementRef;

  themes: ThemeDto[] = [];

  displayName: string = 'Neuer Benutzer';
  isAdminView: boolean = false;
  isSelf: boolean = false;

  roleEnum = UserDetailDto.RoleEnum;

  pw: string;

  visibilityIcon: string = 'visibility';

  constructor(private loginService: LoginEndpointService,
    private authService: AuthService, private userService: UserEndpointService,
    private route: ActivatedRoute, private notificationService: NotificationServiceService,
    private router: Router, private dialog: MatDialog, private validatorService: ValidatorService,
    private themeEndpointService: ThemeEndpointService, private themeService: ThemeServiceService) {
  }

  reload() {
    let id = this.route.snapshot.params['id'];
    this.themeEndpointService.getAllThemes().subscribe(data => {
      this.themes = data;
    });

    if((this.mode === UserCreateUpdateMode.UPDATE && (id === undefined || id === null)) || this.mode === UserCreateUpdateMode.CREATE) {
      this.loginService.authenticated().subscribe({
        next: data => {
          if(data.theme) {
            this.selfTheme = data.theme;
          } else {
            this.selfTheme = data.organisation.theme;
          }
          if(this.mode === UserCreateUpdateMode.UPDATE) {
            this.user = data;
            if(!this.user.theme) {
              this.themeService.setGetActiveTheme(this.defaultTheme);
              this.form.controls['theme'].setValue('org');
            } else {
              this.themeService.setGetActiveTheme(this.user.theme.scssClassName);
              this.form.controls['theme'].setValue(this.user.theme.id);
            }

            if(data.theme === null) {
              if(data.organisation.theme !== null){
                this.themeService.setGetActiveTheme(data.organisation.theme.scssClassName);
              }else{
                data.theme = this.defaultTheme
              }
            }
            this.changeTheme(this.themeService.getActiveTheme());
            this.displayName = data.username;
            this.isSelf = true;
          } else {
            this.isAdminView = true;
            this.user.organisation = data.organisation;

            if(data.organisation.theme === null) {
              data.organisation.theme = this.defaultTheme;
            }
            this.user.theme = data.organisation.theme;
            this.changeTheme(this.themeService.setGetActiveTheme(data.organisation.theme.scssClassName));
          }
          this.checkLastAdmin();
        },
        error: error => {
          console.error(error);
          this.notificationService.open('Ein unerwarteter Fehler ist aufgetreten', 'ok', 15);
          this.router.navigate(['/']);
        }
      });
    } else {
        this.userService.getUserById(id).subscribe({
          next: data => {
            if(data.role === 'ADMIN') {
              this.notificationService.open('Sie haben nicht die benötigten Rechte um diese Seite zu besuchen', 'ok', 15);
              this.router.navigateByUrl('/');
              return;
            }
            this.user = data;
            if(data.theme === null) {
              if(data.organisation.theme !== null) {
                data.theme = data.organisation.theme;
                this.themeService.changeTheme(this.themeService.setGetActiveTheme(data.organisation.theme.scssClassName));
              } else {
                data.theme = this.defaultTheme
              }
            } else {
              this.themeService.setGetActiveTheme(this.user.theme.scssClassName)
            }

            this.changeTheme(this.themeService.getActiveTheme());
            switch(data.role) {
              case 'USER':
                this.user.role = UserDetailDto.RoleEnum.USER;
                break;
              default:
                this.user.role = UserDetailDto.RoleEnum.DISABLED;
                break;
            }
            this.displayName = data.username;
            this.isAdminView = true;
            this.checkLastAdmin();
          },
          error: error => {
            if(error?.status === 403) {
              this.notificationService.open('Sie haben nicht die benötigten Rechte um diese Seite zu besuchen', 'ok', 15);
              this.authService.logoutUser();
            } else {
              this.notificationService.open('Ein unerwarteter Fehler ist aufgetreten', 'ok', 15);
            }
            this.router.navigate(['/']);
            console.error(error);
          }
        });
    }
  }

  ngOnInit() {
    this.themeService.changeTheme(this.themeService.getActiveTheme());
    this.route.data.subscribe(data => {
      this.mode = data.mode;
      this.reload();
    });
  }

  ngAfterViewInit() {
    if(this.mode === UserCreateUpdateMode.CREATE) {
      setTimeout(() => {
        this.form.controls['password'].addValidators(this.validatorService.pwStrengthValidator());
      }, 0);
    }
  }

  checkLastAdmin() {
    if (this.user.role === this.roleEnum.ADMIN) {
      this.userService.getAllUsersByOrganisationId().subscribe(data => {
        let nrOfAdmins = data.filter(u => u.role === this.roleEnum.ADMIN).length;
        console.log(nrOfAdmins);
        this.isLastAdminOfOrg = nrOfAdmins <= 1;
      })
    }
  }

  setTheme(theme: string) {
    this.user.theme = this.themes.find(x => x.id === theme);
  }

  openPwDialog() {
    this.dialog.open(PasswordDialogComponent, { data: this.user});
  }

  changeTheme(theme){
    this.themeService.changeTheme((theme == "org") ? this.user.organisation.theme.scssClassName : theme);
  }

  save() {
    if(!this.form.valid) {
      this.notificationService.open('Bitte prüfen Sie, ob die Eingabe korrekt ist', 'ok', 15)
      return;
    }

    if(this.form.controls['theme'].value === 'org') {
      this.user.theme = null;
    }
    let theme = this.user.theme === null ? null : this.themes.filter(x => x.scssClassName === this.themeService.getChosenTheme())[0];

    let body: CreateAndUpdateUserDto = {
      username: this.user.username,
      theme: theme,
      role: this.user.role
    }

    if(this.mode === UserCreateUpdateMode.CREATE) {
      body['password'] = this.pw;
      this.userService.createUser(body).subscribe({
        next: data => {
          this.notificationService.open( data.username + ' erfolgreich erstellt', 'ok', 5);
          this.router.navigateByUrl('/user/' + data.id);
          this.changeTheme(theme.scssClassName);
        },
        error: error => {
          console.error(error);
          this.notificationService.open('Ein unerwarteter Fehler ist beim Erstellen von ' + this.user.username +' aufgetreten', 'ok', 15);
        }
      })
    } else {
      body['id'] = this.user.id;
      this.userService.updateUser(body, this.user.id).subscribe({
        next: () => {
          this.notificationService.open('Benutzer erfolgreich aktualisiert', 'ok', 5);
          if(theme === null){
            this.changeTheme(this.user.organisation.theme.scssClassName);
          } else {
            this.changeTheme(theme.scssClassName);
          }
          this.reload();
        },
        error: error => {
          console.error(error);
          this.notificationService.open('Etwas ist beim Bearbeiten des Benutzers schiefgegangen', 'ok', 15);
        }
      });
    }
  }

  ngOnDestroy() {
    if(this.selfTheme) {
      this.themeService.setGetActiveTheme(this.selfTheme.scssClassName);
      this.themeService.changeTheme(this.selfTheme.scssClassName);
    }
  }

  switchPwVisibility() {
    if(this.visibilityIcon === 'visibility') {
      this.visibilityIcon = 'visibility_off';
      this.pwInput.nativeElement.type = 'text';
    } else {
      this.visibilityIcon = 'visibility';
      this.pwInput.nativeElement.type = 'password';
    }
  }

  getThemeId(): string {
    let rv = 'org';
    if(this.user?.theme?.id) {
      rv = this.user.theme.id;
    }
    return rv;
  }

  disableUser() {
    let confirmDialogData: ConfirmDialogData = {
      message: 'Sind Sie sicher, dass ' + this.displayName + ' dauerhaft deaktiviert werden soll?',
      denyButtonText: 'Nein',
      acceptButtonText: 'Ja'
    }

    let ref = this.dialog.open(ConfirmDialogComponent, {data: confirmDialogData});

    ref.afterClosed().subscribe(result => {
      if(result === true) {
        this.userService.anonymizeUser(this.user.id).subscribe({
          next: () => {
            this.notificationService.open('Benutzer deaktiviert', 'ok', 5);
            if(this.isSelf)
              this.authService.logoutUser();
            this.router.navigateByUrl('/');
          },
          error: error => {
            console.error(error);
            this.notificationService.open('Etwas ist beim Deaktiveren des Benutzers schiefgegangen', 'ok', 15);
          }
        });
      }
    });
  }
}

export enum UserCreateUpdateMode {
  CREATE,
  UPDATE
}
