import { Component, Input, SimpleChanges } from '@angular/core';
import { ChartData, Chart } from 'chart.js';

@Component({
  selector: 'app-interruption-chart',
  templateUrl: './interruption-chart.component.html',
  styleUrls: ['./interruption-chart.component.scss']
})
export class InterruptionChartComponent {


  @Input({required: true})
  data: InterruptionChartData = null;

  @Input({required: false})
  canvasId: string = 'chart';

  chartData: ChartData = {
    datasets: []
  };

  chart: Chart = null;

  reload() {
    if(this.chart !== null) {
      this.chart.destroy();
    }
    if(!this.data)
      return;

    this.chartData['labels'] = Object.keys(this.data.data).map(x => this.data.data[x].label);

    if(this.canvasId !== 'chart') {
      //setting timout so that in the event of a reload, all canvases are destroyed before the first one is recreated
      setTimeout(() => {
        let canvases = document.querySelectorAll('canvas');
        let idx = 0;
        while(idx < canvases.length && canvases[idx]?.id !== '') {
          idx++;
        }
        if(idx < canvases.length) {
          canvases[idx].id = this.canvasId;
        }


        this.chartData['datasets'] = [{
          label: this.data.dataName,
          data: <[number, number]>this.data.data,
          hoverOffset: 4,
          pointBackgroundColor: (ctx) => {
            return ctx.raw ? ctx.raw['color'] : '#32a852'
          },
          pointBorderColor: (ctx) => {
            return ctx.raw ? ctx.raw['color'] : '#32a852'
          }
        }];

        if(document.querySelectorAll('.interruptionChartWrapper').length === 0)
          return;
        this.chart = new Chart(this.canvasId, {
          type: 'scatter',
          data: this.chartData,
          options: {
            animation: false,

            scales: {
              y: {type: 'category', labels: this.data.yAxisLabels, ticks: {autoSkip: false}}
            },
            plugins: {
              tooltip: {
                callbacks: {
                    label: function(ctx) {
                        console.log(ctx)
                        return ctx.raw['label2'] + " (" + ctx.parsed.x + ", " + ctx.raw['y'] + ")";
                    }
                }
              },
              legend: {
                display: false
              }
            }
          }
        });
      }, 0);

    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes)
    this.reload();
  }
}

export interface InterruptionChartData {
  data: Object,
  dataName: string,
  yAxisLabels: string[]
}
