import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ParticipantDto } from 'src/app/api/model/participantDto';
import { GroupEndpointService } from 'src/app/api/api/groupEndpoint.service';
import { NotificationServiceService } from 'src/app/services/notification-service.service';
import { GroupDetailDto } from 'src/app/api/model/groupDetailDto';
import { NgForm } from '@angular/forms';
import { ValidatorService } from 'src/app/services/validator.service';
import { ConfirmDialogComponent, ConfirmDialogData } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-participant-dialog',
  templateUrl: './participant-dialog.component.html',
  styleUrls: ['./participant-dialog.component.scss']
})
export class ParticipantDialogComponent {

  groups: GroupDetailDto[] = [];
  @ViewChild('form') form: NgForm;


  constructor(private dialog: MatDialog, private dialogRef: MatDialogRef<ParticipantDialogComponent>, private groupService: GroupEndpointService,
        private notificationService: NotificationServiceService, @Inject(MAT_DIALOG_DATA) public data: ParticipantDialogData,
        private validatorService: ValidatorService) {}

  ngOnInit() {
    this.loadGroups();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.form.controls['number'].addValidators(this.validatorService.numberNotInValidator(this.data.bannedNumbers));
      if(this.data.mode === ParticipantDialogMode.UPDATE) {
        this.form.controls['number'].setValue(this.data.toUpdate.number);
        this.form.controls['name'].setValue(this.data.toUpdate.name);
      } else {
        this.form.controls['number'].setValue(this.findLargestNumber() + 1);
      }
    }, 0);
    this.dialogRef.disableClose = true;
  }

  findLargestNumber(): number {
    let rv = 0;
    this.data.bannedNumbers.forEach(x => x > rv ? rv = x : rv = rv);
    return rv;
  }

  setGroups() {
    this.data.toUpdate.groups.forEach(x => {
      this.form.controls[x.group.id].setValue(x.category.id);
    });
  }

  cancel() {
    let dialogData: ConfirmDialogData = {
      message: 'Wollen Sie das ' + (this.data?.toUpdate ? 'Bearbeiten ' : 'Erstellen') + ' wirklich abbrechen?',
      denyButtonText: 'Nein',
      acceptButtonText: 'Ja'
    }
    let ref = this.dialog.open(ConfirmDialogComponent, {data: dialogData});
    ref.afterClosed().subscribe(result => {
      if (result === true) {
        this.dialogRef.close(false);
      }
    });
  }

  loadGroups() {
    this.groupService.getAll3().subscribe({
      next: data => {
        this.groups = data;
        if(this.data.mode === ParticipantDialogMode.UPDATE) {
          setTimeout(() => {
            this.setGroups();
          }, 0);
        }
      },
      error: error => {
        this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
        console.error(error);
      }
    });
  }

  getTitle() {
    if(this.data.mode === ParticipantDialogMode.CREATE) {
      return 'Neuen Shourtcut erstellen';
    } else if(this.data.mode === ParticipantDialogMode.GENERATE) {
      return 'Mehrere Shourtcuts generieren';
    } else if(this.data.mode === ParticipantDialogMode.UPDATE) {
      return this.data.toUpdate.number + ': ' + this.data.toUpdate.name + ' aktualisieren';
    } else if(this.data.mode === ParticipantDialogMode.GROUPUPDATE) {
      return 'Zugehörigkeiten mehrerer Shourtcuts ändern';
    } else {
      return '';
    }
  }

  save() {
    if(!this.form.valid) {
      this.notificationService.open('Bitte gehen Sie sicher, dass alle Ihre Eingaben valide sind', 'ok', 15);
      return;
    }

    if(this.data.mode === ParticipantDialogMode.CREATE) {
      let categories = this.getSelectedCategories();
      let participant: ParticipantDto = {
        number: this.form.controls['number'].value,
        name: this.form.controls['name'].value === '' ? null : this.form.controls['name'].value,
        groups: categories
      }
      this.dialogRef.close(participant);
    } else if(this.data.mode === ParticipantDialogMode.GENERATE) {

    } else if(this.data.mode === ParticipantDialogMode.UPDATE) {
      let categories = this.getSelectedCategories();
      let participant: ParticipantDto = {
        id: this.data.toUpdate.id,
        number: this.form.controls['number'].value,
        name: this.form.controls['name'].value === '' ? null : this.form.controls['name'].value,
        groups: categories
      }
      this.dialogRef.close(participant);
    } else if(this.data.mode === ParticipantDialogMode.GROUPUPDATE) {

    }
  }

  getSelectedCategories() {
    let result = [];
    this.groups.forEach(x => {
      let value = this.form.controls[x.id].value;
      if(value) {
        let category = this.findCategoryWithId(value);
        result.push({group: {id: x.id, name: x.name}, category: category});
      }
    });
    return result;
  }

  findCategoryWithId(id: string): Object {
    let idx = 0;
    let found;
    while(idx < this.groups.length && found === undefined) {
      found = this.groups[idx].categories.find(x => x.id === id);
      idx++;
    }
    return found;
  }

}

export interface ParticipantDialogData {
  mode: ParticipantDialogMode,
  bannedNumbers: number[],
  toUpdate?: ParticipantDto
}

export enum ParticipantDialogMode {
  CREATE,
  GENERATE,
  UPDATE,
  GROUPUPDATE
}
