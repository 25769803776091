import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationServiceService } from 'src/app/services/notification-service.service';
import { ThemeServiceService } from 'src/app/services/theme-service.service';
import { ConfirmDialogData, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { MeetingTemplatesCreateUpdateDialogComponent, TemplateDialogData } from './meeting-templates-create-update-dialog/meeting-templates-create-update-dialog.component';
import { OrganisationDto } from 'src/app/api/model/organisationDto';
import { MeetingTemplateMinimalDto } from 'src/app/api/model/meetingTemplateMinimalDto';
import { MeetingTemplateEndpointService } from 'src/app/api/api/meetingTemplateEndpoint.service';
import { LoginEndpointService } from 'src/app/api/api/loginEndpoint.service';

@Component({
  selector: 'app-meeting-templates',
  templateUrl: './meeting-templates.component.html',
  styleUrls: ['./meeting-templates.component.scss']
})
export class MeetingTemplatesComponent {
  displayedColumns: string[] = ['name', 'actions'];
  organisation: OrganisationDto = null;

  templates: TableTemplate[] = [];
  templateDtos: MeetingTemplateMinimalDto[] = [];

  datasource = new MatTableDataSource();

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('tableSort') sort: MatSort;

  constructor(private dialog: MatDialog, private notificationService: NotificationServiceService,
    private meetingTemplateService: MeetingTemplateEndpointService, private loginService: LoginEndpointService,
    private themeService: ThemeServiceService) {}


  reload() {
    this.loginService.authenticated().subscribe({
      next: data => {
        this.organisation = data.organisation;
        this.meetingTemplateService.getAllMeetingTemplatesOfOrganisation().subscribe({
          next: data => {
            this.templateDtos = data;
            this.convertTemplates(data);
          },
          error: error => {
            console.error(error)
            this.notificationService.open('Beim Laden ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
          }
        });
      },
      error: error => {
        console.error(error)
        this.notificationService.open('Ein unerwarteter Fehler ist aufgetreten', 'ok', 15);
      }
    });
  }

  convertTemplates(templates: MeetingTemplateMinimalDto[]) {
    this.templates = [];
    templates.forEach(x => {
      this.templates.push({id: x.id, name: x.name});
    });

    this.datasource.data = this.templates;
  }

  ngOnInit() {
    this.themeService.changeTheme(this.themeService.getActiveTheme());
    this.reload();
  }

  ngAfterViewInit() {
    this.datasource.paginator = this.paginator;
    this.datasource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();

    if (this.datasource.paginator) {
      this.datasource.paginator.firstPage();
    }
  }

  addTemplate() {
    let ref = this.dialog.open(MeetingTemplatesCreateUpdateDialogComponent, {data: {organisationId: this.organisation.id}});
    ref.afterClosed().subscribe(result => {
      if(result) {
        this.reload();
      }
    });
  }

  updateTemplate(id: string) {
    let dialogData: TemplateDialogData = {
      template: this.templateDtos.filter(x => x.id === id)[0],
      organisationId: this.organisation.id
    }
    let ref = this.dialog.open(MeetingTemplatesCreateUpdateDialogComponent, {data: dialogData});
    ref.afterClosed().subscribe(result => {
      if(result) {
        this.reload();
      }
    });
  }

  deleteTemplate(id: string, name: string) {
    let dialogData: ConfirmDialogData = {
      message: 'Sind Sie sicher, dass ' + name + ' gelöscht werden soll?',
      denyButtonText: 'Nein',
      acceptButtonText: 'Ja'
    }
    let ref = this.dialog.open(ConfirmDialogComponent, {data: dialogData});
    ref.afterClosed().subscribe(result => {
      if(result === true) {
        this.meetingTemplateService.deleteMeetingTemplate(id).subscribe({
          next: () => {
            this.notificationService.open(name + 'erfoglreich gelöscht', 'ok', 5);
            this.reload();
          },
          error: error => {
            console.error(error);
            this.notificationService.open( name + ' konnte wegen eines unerwarteten Fehlers nicht gelöscht werden', 'ok', 15);
          }
        });
      }
    });
  }
}

interface TableTemplate {
  id: string,
  name: string
}
