<mat-toolbar color="primary" style="height: 5vh">
  <mat-toolbar-row>
    <img  id="logo" [src]="imageUrl" onerror="this.src='assets/img/logo_white.png'" alt="Logo der Organisation">
    <a mat-list-item routerLink="/" >Fair Meeting</a>
    <span class="menu-spacer"></span>
    <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
      <mat-icon>menu</mat-icon>
    </button>
    <div fxShow="true" fxHide.lt-md>
      <app-header></app-header>
    </div>
  </mat-toolbar-row>
</mat-toolbar> 
<mat-sidenav-container style="height: 90vh; overflow: hidden;">
  <mat-sidenav #sidenav mode="over">
    <mat-nav-list>
      <app-side></app-side>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
      <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
