<div class="wrapper">
    <div class="headingContainer">
    <h1>{{getHeading()}}</h1>
    </div>
    <form #form="ngForm" name="form">
        <div #page1 [hidden]="formData[0].display">
            <mat-form-field style="width: 100%;">
                <mat-label>Name</mat-label>
                <input matInput required minlength="2" maxlength="50" type="text" #name name="name" ngModel>
                <mat-hint>Zwischen 2 und 50 Zeichen</mat-hint>
                <mat-error *ngIf="form && form.controls['name'] && form.controls['name'].hasError('required')">
                    Name <strong>benötigt</strong>
                </mat-error>
                <mat-error *ngIf="form && form.controls['name'] && !form.controls['name'].hasError('required')
                && (form.controls['name'].hasError('minlength') || form.controls['name'].hasError('maxlength'))">
                    Stellen Sie sicher, dass zwischen 2 und 50 Zeichen eingegeben wurden
                </mat-error>
            </mat-form-field>

            <mat-form-field style="width: 100%;">
                <mat-label>Sitzungsdatum</mat-label>
                <input matInput [matDatepicker]="picker" [min]="today" required name="picker" ngModel>
                <mat-hint>DD.MM.YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="form && form.controls['picker'] && form.controls['picker'].hasError('required')">
                    Sitzungsdatum <strong>benötigt</strong>
                </mat-error>
            </mat-form-field>

            <mat-form-field style="width: 100%;">
                <mat-label>Sitzungskategorie</mat-label>
                <mat-select required #meetingTemplate name="meetingTemplate" [(ngModel)]="mTemp">
                    <mat-option value="" selected></mat-option>
                <mat-option *ngFor="let mt of meetingTemplates" [value]="mt.id">{{mt.name}}</mat-option>
                </mat-select>
                <mat-hint>Wählen Sie eine Sitzungsvorlage</mat-hint>
            </mat-form-field>

            <div class="checkBoxDiv">
                <mat-checkbox name="public" ngModel>Öffentlich sichtbar</mat-checkbox>
                <mat-hint class="smallText">Wählen Sie aus ob dieses Meeting öffentlich sichtbar gemacht werden soll</mat-hint>
            </div>

        </div>
        <div #page2 [hidden]="formData[1].display">
            <div class="inputWithButton">
                <mat-form-field>
                    <mat-label>Gruppe</mat-label>
                    <mat-select #groupsGrid name="groupsGrid" [(ngModel)]="mGroup">
                        <mat-option value="" selected></mat-option>
                    <mat-option *ngFor="let groupOfGroups of groups" [value]="groupOfGroups">{{groupOfGroups.group.name}}</mat-option>
                    </mat-select>
                    <mat-hint>Wählen Sie eine Gruppe</mat-hint>
                </mat-form-field>
                <mat-form-field *ngIf="mGroup">
                    <mat-label>Kategorie</mat-label>
                    <mat-select #groupsCategoriesGrid name="groupsCategoriesGrid" [(ngModel)]="mGroupCategory">
                        <mat-option value="" selected></mat-option>
                    <mat-option *ngFor="let cat of mGroup.categories" [value]="cat.category">{{cat.category.name}}</mat-option>
                    </mat-select>
                    <mat-hint>Wählen Sie eine Kategorie</mat-hint>
                </mat-form-field>
                <mat-form-field *ngIf="mGroupCategory">
                    <mat-label>Anzahl</mat-label>
                    <input matInput #groupsCategoriesCount name="groupsCategoriesCount" type="number" placeholder="1" min="1" [(ngModel)]="countOfCategory">
                </mat-form-field>
                <button mat-mini-fab color="primary" (click)="addGroupCategory()" [disabled]="formData[1].display">
                    <mat-icon>check</mat-icon>
                </button>
            </div>
            <mat-table
            #categoryTable
            [dataSource]="groupsSelectedForTable">
            <ng-container matColumnDef="gruppe">
                <mat-header-cell *matHeaderCellDef> Gruppe </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.groupName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="kategorie">
                <mat-header-cell *matHeaderCellDef> Kategorie </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.categoryName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="anzahl">
                <mat-header-cell *matHeaderCellDef> Anzahl </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.amount}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef> Aktion </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button mat-mini-fab color="warn" (click)="removeCategory(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumnsCategories"></mat-header-row>
            <mat-row
                *matRowDef="let row; columns: displayedColumnsCategories;"
                [class.hidden]="row.amount<=0"></mat-row>
            </mat-table>
        </div>
        <div #page3 [hidden]="formData[2].display">
            <div class="inputWithButton">
            <mat-form-field>
                <mat-label>Tagesordnungspunkte</mat-label>
                <input matInput minlength="2" maxlength="50" type="text" #talkingpoint name="talkingpoint" ngModel (keydown.enter)="add(talkingpoint)">
                <mat-hint>Bitte fügen sie mindestens einen hinzu (Zwischen 2 und 50 Zeichen)</mat-hint>
                <mat-error *ngIf="form && form.controls['talkingpoint'] && talkingpoints.length === 0">
                    Sie müssen mindestens einen Tagesordnungspunkt angeben
                </mat-error>
                <mat-error *ngIf="talkingpoints.length === 0 && form.controls['talkingpoint'] && !form.controls['talkingpoint'].hasError('required')
                && (form.controls['talkingpoint'].hasError('minlength') || form.controls['talkingpoint'].hasError('maxlength'))">
                    Stellen Sie sicher, dass zwischen 2 und 50 Zeichen eingegeben wurden
                </mat-error>
            </mat-form-field>
            <button mat-mini-fab color="primary" (click)="add(talkingpoint)">
                <mat-icon>check</mat-icon>
            </button>
            </div>
            <mat-table
            #table
            [dataSource]="talkingpoints"
            class="mat-elevation-z8"
            cdkDropList
            (cdkDropListDropped)="drop($event)"
            cdkDropListData="talkingpoints"
            [cdkDropListDisabled]="dragDisabled"
            >
            <ng-container matColumnDef="position" sticky>
                <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                <mat-cell *matCellDef="let element">
                <mat-icon class="dragCursor" (mousedown)="dragDisabled = false;">reorder</mat-icon>
                {{getPosition(element)}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.name}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef> Aktion </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <button mat-mini-fab color="warn" (click)="remove(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row
                *matRowDef="let row; columns: displayedColumns;"
                cdkDrag
                [cdkDragData]="row"
            ></mat-row>
            </mat-table>
        </div>
        <mat-paginator #paginator
        [length]="length"
        [pageSize]="pageSize"
        (page)="pageEvent($event)"
        [hidePageSize]="true"
        class="mat-paginator-container">
        </mat-paginator>
        <div class="buttonContainer">
            <button (click)="cancel()" mat-flat-button color="accent" type="button">
                Abbrechen
            </button>
            <button mat-flat-button color="primary" type="submit" (click)="save()" [disabled]="!isLastPage">
                Speichern
            </button>
        </div>
    </form>
</div>

