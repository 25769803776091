<div class="wrapper">
    <div class="buttonContainer">
        <h1>Protokolle für {{meeting?.name}}</h1>
    </div>
    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="datasource" #tableSort="matSort" matSort #table>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let log"> {{log.user.username}} </td>
            </ng-container>

            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let log"> {{stateDisplayName(log.state)}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Aktionen </th>
                <td mat-cell *matCellDef="let log">
                    <button mat-mini-fab color="accent" [routerLink]="['../../log',log.id]" *ngIf="logIsRunning(log.state) && logBelongsToMe(log.user)">
                        <mat-icon>border_color</mat-icon>
                    </button>
                    <button mat-mini-fab color="accent" (click)="navigateToView(log.id)" *ngIf="logIsViewable(log)">
                        <mat-icon>info</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">Keine Einträge gefunden</td>
            </tr>
        </table>
        <mat-paginator #paginator="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="select page"></mat-paginator>
    </div>
    <div class="buttonContainer">
        <button mat-raised-button color="primary" routerLink="/meeting">
            alle Sitzungen<mat-icon>keyboard_double_arrow_left</mat-icon>
        </button>
        <button mat-raised-button color="primary" routerLink="/log/compare/{{meeting?.id}}">
            Protokolle vergleichen<mat-icon>difference</mat-icon>
        </button>
    </div>
</div>
