import { Component, ViewChild } from '@angular/core';
import { ParticipantDto } from 'src/app/api/model/participantDto';
import { LoginEndpointService } from 'src/app/api/api/loginEndpoint.service';
import { ParticipantEndpointService } from 'src/app/api/api/participantEndpoint.service';
import { NotificationServiceService } from 'src/app/services/notification-service.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { ParticipantDialogComponent, ParticipantDialogData, ParticipantDialogMode } from '../participant-dialog/participant-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogData } from '../confirm-dialog/confirm-dialog.component';
import {ThemeServiceService} from "src/app/services/theme-service.service";

@Component({
  selector: 'app-participant-view',
  templateUrl: './participant-view.component.html',
  styleUrls: ['./participant-view.component.scss']
})
export class ParticipantViewComponent {

  displayedColumns: string[] = ['number', 'name', 'actions'];
  participants: ParticipantDto[] = [];
  bannedNumbers: number[] = [];
  dataSource =  new MatTableDataSource<ParticipantDto>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selection = new SelectionModel<ParticipantDto>(true, []);

  constructor(private notificationService: NotificationServiceService, private participantService: ParticipantEndpointService,
    private loginService: LoginEndpointService, private dialog: MatDialog, private themeService: ThemeServiceService) {}


  reload() {
    this.participantService.getAll().subscribe({
      next: data => {
        this.participants = data;
        this.dataSource.data = this.participants;
        this.bannedNumbers = this.getBannedNumbers();
      },
      error: error => {
        this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
        console.error(error);
      }
    })
  }

  getBannedNumbers(): number[] {
    let result: number[] = [];
    this.participants.forEach(x => result.push(x.number));
    return result;
  }

  ngOnInit() {
    this.themeService.changeTheme(this.themeService.getActiveTheme());
    this.reload();
    this.dataSource.filterPredicate = (data: ParticipantDto, filter: string) => data.name.toLowerCase().indexOf(filter.toLowerCase()) != -1 || data.number === Number(filter);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?: ParticipantDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.number}`;
  }

  createNewSingle() {
    let ref = this.dialog.open(ParticipantDialogComponent, {data: {mode: ParticipantDialogMode.CREATE, bannedNumbers: this.bannedNumbers}});
    ref.afterClosed().subscribe(result => {
      if(result !== false) {
        this.participantService.create(result).subscribe({
          next: () => {
            this.reload();
            this.notificationService.open('Nummer ' + result.number + ' erfolgreich erstellt', 'ok', 5);
          },
          error: error => {
            this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
            console.error(error);
          }
        })
      }
    });
  }

  edit(toUpdate: ParticipantDto) {
    let dialogData: ParticipantDialogData = {
      mode: ParticipantDialogMode.UPDATE,
      bannedNumbers: this.bannedNumbers.filter(x => x !== toUpdate.number),
      toUpdate: toUpdate
    };
    let ref = this.dialog.open(ParticipantDialogComponent, {data: dialogData});
    ref.afterClosed().subscribe(result => {
      if(result !== false) {
        this.participantService.update(result, toUpdate.id).subscribe({
          next: () => {
            this.reload();
            this.notificationService.open('Nummer ' + result.number + ' erfolgreich aktualisiert', 'ok', 5);
          },
          error: error => {
            this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
            console.error(error);
          }
        })
      }
    });
  }

  delete(toDelete: ParticipantDto) {
    let deleteText =
      'Sind Sie sicher, dass Sie den Shourtcut mit der Nummer ' + toDelete.number + ' permanent löschen wollen?';
    if(toDelete.name?.length > 0) {
      deleteText = 'Sind Sie sicher, dass Sie ' + toDelete.name + ' permanent löschen wollen?';
    }
    let dialogData: ConfirmDialogData = {
      message: deleteText,
      denyButtonText: 'Abbrechen',
      acceptButtonText: 'Löschen'
    }
    let ref = this.dialog.open(ConfirmDialogComponent, {data: dialogData});
    ref.afterClosed().subscribe(result => {
      if(result === true) {
        this.participantService._delete(toDelete.id).subscribe({
          next: () => {
            this.reload();
            this.notificationService.open('Nummer ' + toDelete.number + ' erfolgreich gelöscht', 'ok', 5);
          },
          error: error => {
            this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
            console.error(error);
          }
        });
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
