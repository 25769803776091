import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LogEndpointService} from 'src/app/api/api/logEndpoint.service';
import {LoginEndpointService} from 'src/app/api/api/loginEndpoint.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-side',
  templateUrl: './side.component.html',
  styleUrls: ['./side.component.scss']
})
export class SideComponent{
  constructor(public authService: AuthService, private router: Router, private logService: LogEndpointService) {
  }
}
