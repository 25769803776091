import {Component} from '@angular/core';
import {LogDetailDto} from 'src/app/api/model/logDetailDto';
import {LogListDto} from 'src/app/api/model/logListDto';
import {MeetingListDto} from 'src/app/api/model/meetingListDto';
import {} from 'src/app/api/model/logDetailDto';
import {} from 'src/app/api/model/logDetailDto';
import {} from 'src/app/api/model/logDetailDto';
import {LogEndpointService} from 'src/app/api/api/logEndpoint.service';
import {MeetingEndpointService} from 'src/app/api/api/meetingEndpoint.service';
import {MeetingTemplateEndpointService} from 'src/app/api/api/meetingTemplateEndpoint.service';
import {ThemeServiceService} from "../../services/theme-service.service";
import {ActivatedRoute} from "@angular/router";
import {NotificationServiceService} from "../../services/notification-service.service";
import {AuthService} from "../../services/auth.service";
import {MeetingTemplateMinimalDto} from 'src/app/api/model/meetingTemplateMinimalDto';
import {CategoryGroupDataDto} from 'src/app/api/model/categoryGroupDataDto';
import {SimpleSubGroupDto} from 'src/app/api/model/simpleSubGroupDto';
import {MeetingMinimalDto} from 'src/app/api/model/meetingMinimalDto';

@Component({
  selector: 'app-log-multi-sessin-statistic',
  templateUrl: './log-multi-sessin-statistic.component.html',
  styleUrls: ['./log-multi-sessin-statistic.component.scss']
})
export class LogMultiSessinStatisticComponent {


  logsAll: LogDetailDto[][];
  meetings: MeetingMinimalDto[] = [];
  groupsAll: CategoryGroupDataDto[][];
  groups: CategoryGroupDataDto[];
  speakingTimesAll: Object[];
  speakingTimes: Object = {};
  allChartDataAll: Object[] = [{}];
  allChartData: Object = {};
  chartDataName: string = 'Sprechzeit (min)';
  interruptionsAll: Object[] = [{}];
  interruptionsChartDataAll: Object[] = [{}];

  meetingTemplateId: string = null;
  meetingTemplate: MeetingTemplateMinimalDto = {
    name: "",
    id: ""
  }


  constructor(private themeService: ThemeServiceService, private meetingTemplateService: MeetingTemplateEndpointService, private route: ActivatedRoute,
              private logService: LogEndpointService, private notificationService: NotificationServiceService, private authService: AuthService, private meetingService: MeetingEndpointService) {

  }

  ngOnInit() {
    this.themeService.changeTheme(this.themeService.getActiveTheme());
    this.meetingTemplateId = this.route.snapshot.params['id'];
    this.meetingTemplateService.getMeetingTemplate(this.route.snapshot.params['id']).subscribe({
      next: data => {
        this.meetingTemplate = data;
      },
      error: error => {
        this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
        console.error(error);
      }
    })
    this.meetingService.getAll1().subscribe({
      next: data => {
        let meetings: MeetingListDto[] = data;
        meetings = meetings.filter(x => x.meetingTemplate !== null && x.meetingTemplate.id === this.meetingTemplateId)
        this.logsAll = new Array<Array<LogDetailDto>>(meetings.length);
        this.groupsAll = new Array<Array<CategoryGroupDataDto>>(meetings.length);
        this.speakingTimesAll = new Array<{}>(meetings.length);
        this.allChartDataAll = new Array<{}>(meetings.length);
        this.interruptionsAll = new Array<{}>(meetings.length);
        meetings.forEach((meeting, i) => {
          this.logService.getAllByMeeting(meeting.id).subscribe({
            next: data => {
              let logs: LogListDto[] = data;
              logs = logs.filter(log => log.state !== LogDetailDto.StateEnum.RUNNING);
              this.logsAll[i] = []
              logs.forEach(x => {
                this.logService.getById2(x.id).subscribe({
                  next: newLog => {
                    if (newLog.state === "FINISHED") {
                      this.logsAll[i].push(newLog);
                      if(!this.meetings.find(x => (x.id === newLog.meeting.id))){
                        this.meetings.push(newLog.meeting)
                      }
                      if (this.logsAll[i].length === logs.length) {
                        this.sortLogs(i);
                        this.getGroups(i);
                        this.readSpeakingTimes(i);
                        this.readChartData(i);
                        this.getInterruptionsFromLog(i);
                        this.getAllGroups();
                        this.setSpeakingTimes();
                        this.summUpSpeakingTimes();
                        this.readAllCharData();
                        console.log("interruptionsAll", this.interruptionsAll)
                        this.readInterruptionChartData(i);
                        console.log("groups", this.groups)
                        console.log("interuptionsChartDataAll", this.interruptionsChartDataAll)
                        console.log("meetings",this.meetings)
                      }
                    }
                  },
                  error: error => {
                    this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
                    console.error(error);
                  }
                });
              });
            },

            error: error => {
              this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
              console.error(error);
            }
          });
        })


      },
      error: error => {
        this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
        console.error(error);
      }
    });
  }

  sortLogs(index: number) {
    this.logsAll[index].sort((a, b) => this.sortFn(a, b));
  }

  private sortFn(a, b) {
    let nameA = a.user.username.toLowerCase();
    let nameB = b.user.username.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  getGroups(i: number) {
    this.speakingTimesAll[i] = {}
    if (this.logsAll[i].length <= 0)
      return;
    this.groupsAll[i] = this.logsAll[i][0].talkingPoints[0].speakers;
    this.logsAll[i].forEach(x => {
      this.speakingTimesAll[i][x.id] = {};
    });
    this.groupsAll[i].forEach(x => {
      x.categories.forEach(y => {
        this.logsAll[i].forEach(z => {
          this.speakingTimesAll[i][z.id][y.id] = 0;
        });
      });
    });
  }

  getAllGroups() {
    this.groups = [];
    this.groupsAll.forEach(meetingGroups => {
      meetingGroups.forEach(group => {
          var oldG = this.groups.findIndex(x => group.id === x.id);
          if (oldG === -1) { //has not existed
            this.groups.push(group);
          } else {         //exists
            group.categories.forEach(category => {
              var categoryOld = this.groups[oldG].categories.findIndex(oldC => oldC.id === category.id);
              if (categoryOld === -1) { //has not existed
                this.groups[oldG].categories.push(category);
              } else { //exists
                if (category.entry.amount > 0) {
                  this.groups[oldG].categories[categoryOld].entry.amount += category.entry.amount;
                  category.entry.data.forEach(x => {
                    this.groups[oldG].categories[categoryOld].entry.data.push(x);
                  })
                }
              }
            })

          }
        }
      )
    })
  }

  readSpeakingTimes(i: number) {
    if (this.logsAll[i].length <= 0)
      return;
    this.logsAll[i].forEach(log => {
      this.groupsAll[i].forEach(group => {
        group.categories.forEach(category => {
          let totalInMs = 0;
          log.talkingPoints.forEach(tp => {
            if(tp.speakers.find(x => x.id === group.id) && tp.speakers.find(x => x.id === group.id).categories.find(x => x.id === category.id)){
            let data = tp.speakers.find(x => x.id === group.id).categories.find(x => x.id === category.id).entry.data;
            data.forEach(times => {
              if (times.end !== null) {
                totalInMs += new Date(times.end).getTime() - new Date(times.start).getTime();
              } else {
                totalInMs += new Date().getTime() - new Date(times.start).getTime();
              }
            });
          }
          });
          this.speakingTimesAll[i][log.id][category.id] = totalInMs;
        });
      });
    });
  }

  setSpeakingTimes() {
    for (let i = 0; i < this.logsAll.length; i++) {
      this.groups.forEach(groupObjekt => {
        if (this.logsAll[i][0] === undefined) {
        } else {
          if (this.speakingTimes[this.logsAll[i][0].meeting.id] === undefined) {
            this.speakingTimes[this.logsAll[i][0].meeting.id] = {};
          }
        }
        groupObjekt.categories.forEach(categoryObjekt => {
          if (this.logsAll[i][0] === undefined) {
          } else {
            this.speakingTimes[this.logsAll[i][0].meeting.id][categoryObjekt.id] = 0;
          }
        });
      });
    }
  }

  summUpSpeakingTimes() {
    for (let i = 0; i < this.speakingTimesAll.length; i++) {
      this.logsAll[i].forEach(logObjekt => {
        this.groups.forEach(grouObj => {
          grouObj.categories.forEach(categoryObj => {
            if (this.speakingTimesAll[i] === undefined) { //
              console.error("speakingTimesAll[i] is null", this.speakingTimesAll[i])
            } else {
              this.speakingTimes[this.logsAll[i][0].meeting.id][categoryObj.id] +=
                !(this.speakingTimesAll[i][logObjekt.id][categoryObj.id] === undefined) ? //if
                  this.speakingTimesAll[i][logObjekt.id][categoryObj.id] :   //then
                  0;                                                        //else
            }
          })
        })
      })
    }
  }

  readChartData(i: number) {
    this.allChartDataAll[i] = {};
    this.logsAll[i].forEach(log => {
      this.allChartDataAll[i][log.id] = {};
      this.groupsAll[i].forEach(group => {
        this.allChartDataAll[i][log.id][group.id] = this.getChartData(i, log.id, group.id);
      });
    });
  }

  readAllCharData() {
    console.log("logsAll", this.logsAll)
    this.logsAll.forEach(meetingBlob => {
      if(meetingBlob.length > 0) {
        this.allChartData[meetingBlob[0].meeting.id] = {};
        this.groups.forEach(group => {
          this.allChartData[meetingBlob[0].meeting.id][group.id] = this.getAllCharData(meetingBlob[0].meeting.id, group.id);
        });
        console.log("meeting blob ok", meetingBlob.length, meetingBlob)
      } else {
        console.error("meeting empty", meetingBlob.length, meetingBlob)
      }
    })

  }


  readInterruptionChartData(i: number) {
    if (this.logsAll[i].length <= 0)
      return;
    let groupNames: string[] = [];
    this.groupsAll[i].forEach(x => {
      x.categories.forEach(y => groupNames.push(y.name))
    });

    this.interruptionsChartDataAll[i] = {};

    this.logsAll[i].forEach(log => {
      let data = [];
      this.groups.forEach(group => {
        group.categories.forEach(category => {
          let count = 0;
          this.interruptionsAll[i][log.id].forEach(interuption => {
            interuption.byGroups.forEach(subgroup => {
              console.log("subgroup", subgroup, "category", category)
              if (subgroup['name'] === category.name) {
                count++;
              }
            });
          });

          data.push({data: count, label: category.name});

        })
      })
      console.log("char datea", data);
      this.interruptionsChartDataAll[i][log.id] = {
        data: data,
        dataName: 'Unterbrechungen durch die Gruppe ',
        yAxisLabels: groupNames
      };
    });
  }

  shortenString(toShorten: string, shortenTo: number): string {
    return toShorten.slice(0, (toShorten.length - shortenTo) * -1);
  }

  getInterruptionsFromLog(i: number) {
    if (this.logsAll[i].length <= 0)
      return;
    this.interruptionsAll[i] = {};
    this.logsAll[i].forEach(log => {
      let result: InterruptionsPanelData[] = [];

      log.interruptions.forEach(group => {
        group.categories.forEach(category => {
          category.entry.data.forEach(entry => {
            let cousin = result.find(x => {
              let temp = {timestamp: x.timestamp, text: x.text};
              let temp2 = {timestamp: new Date(entry.start), text: entry.value};
              return JSON.stringify(temp) === JSON.stringify(temp2);
            });
            if (cousin) {
              cousin.byGroups.push(category);
            } else {
              let toPush: InterruptionsPanelData = {
                timestamp: new Date(entry.start),
                text: entry.value,
                byGroups: [category]
              }
              result.push(toPush);
            }
          });
        });
      });

      this.interruptionsAll[i][log.id] = result;
    });
  }

  getCategoryNameByid(i: number, id: string): string {
    let rv = null;
    this.groupsAll[i].forEach(group => {
      let found = group.categories.find(category => category.id === id);
      if (found)
        rv = found;
    });
    return rv.name;
  }

  getCategoryNameByidoverAll(id: string): string {
    let rv = null;
    this.groupsAll.forEach(group => {
      group.forEach(group => {
        let found = group.categories.find(category => category.id === id);
        if (found) {
          rv = found;
        }
      });

    })
    return rv.name;
  }

  getChartData(i: number, logId: string, groupId: string) {
    let log = this.logsAll[i].find(x => x.id === logId);
    let group = this.groupsAll[i].find(x => x.id === groupId);
    let chartData = {};


    Object.keys(this.speakingTimesAll[i][log.id]).forEach(x => {
      if (group.categories.find(y => y.id === x))
        chartData[x] = {
          data: Math.round(this.speakingTimesAll[i][log.id][x] / 60000),
          label: this.getCategoryNameByid(i, x)
        };
    });
    let chartOverData = {
      data: chartData,
      dataName: this.chartDataName
    }
    return Object.keys(chartData).length > 0 ? chartOverData : null;
  }

  getAllCharData(meetingId: string, groupId: string) {
    let meetingBlob = this.logsAll.find(meeting => meeting.length > 0 && meeting[0].meeting.id === meetingId);
    let group = this.groups.find(x => x.id === groupId);
    let chartData = {};

    Object.keys(this.speakingTimes[meetingBlob[0].meeting.id]).forEach(x => {
      if (group.categories.find(y => y.id === x))
        chartData[x] = {
          data: Math.round(this.speakingTimes[meetingBlob[0].meeting.id][x] / 60000),
          label: this.getCategoryNameByidoverAll(x)
        };
    });
    let chartOverData = {
      data: chartData,
      dataName: this.chartDataName
    }
    return Object.keys(chartData).length > 0 ? chartOverData : null;

  }


  tabChanged(event: number) {
    for (let i = 0; i < this.logsAll.length; i++) {
      if (event === 0) {
        this.readChartData(i);
        this.readAllCharData();
      } else if (event === 1) {
        this.readInterruptionChartData(i);
      }
    }
  }

  logName(log: LogDetailDto[]) {
    if (log === undefined) {
      return ""
    } else {
      if (log[0] == undefined) {
        return ""
      }
      return log[0].meeting.name;
    }
  }

  groupsAllLength(i: number) {
    if (this.groupsAll[i] === undefined) {
      return 0;
    }
    return this.groupsAll[i].length
  }

  getNamesFromSubgroupArray(subgroups: SimpleSubGroupDto[]): string {
    let rv = '';
    subgroups.forEach(x => {
      rv += x.name + ', '
    });
    if (rv.length > 0)
      rv = rv.slice(0, -2);
    return rv;
  }
}

interface InterruptionsPanelData {
  timestamp: Date,
  text: string,
  byGroups: SimpleSubGroupDto[]
}
