import { Component, Inject, ViewChild } from '@angular/core';
import { NgForm, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CategoryDto } from 'src/app/api/model/categoryDto';
import { CategoryGroupDataDto } from 'src/app/api/model/categoryGroupDataDto';
import { SimpleSubGroupDto } from 'src/app/api/model/simpleSubGroupDto';
import { NotificationServiceService } from 'src/app/services/notification-service.service';

@Component({
  selector: 'app-interruption-add',
  templateUrl: './interruption-add.component.html',
  styleUrls: ['./interruption-add.component.scss']
})
export class InterruptionAddComponent {

  @ViewChild('form') form: NgForm;

  defautlText = "Zwischenruf"

  constructor(public dialogRef: MatDialogRef<InterruptionAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InterruptionAddData,
    private notificationService: NotificationServiceService) {}

  ngOnInit() {
    this.dialogRef.backdropClick().subscribe(() => this.cancel());
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.data.possibleSpeakers.forEach(group => {
        this.form.controls[group.id].addValidators([this.atLeastOneGroupValidator()]);
      });
    }, 0);
  }

  getHeading() {
    return this.data.text ? 'Bearbeiten einer Unterbrechung' : 'Neue Unterbrechung hinzufügen';
  }


  cancel() {
    this.dialogRef.close(false);
  }

  save() {
    if(!this.form.valid) {
      this.form.control.markAllAsTouched();
      this.notificationService.open('Bitte stellen Sie sicher, dass alle benötigten Felder befüllt sind', 'ok', 15);
      return;
    }
    let groupsSelected: SimpleSubGroupDto[] = [];
    this.data.possibleSpeakers.forEach(group => {
      let value = this.form.controls[group.id].value;
      if(value !== '') {
        groupsSelected.push(group.categories.find(x => x.id === value));
      }
    });
    if(groupsSelected.length === 0) {
      this.addGroupValidationErrorToAllControls();
      this.notificationService.open('Bitte stellen Sie sicher, dass alle benötigten Felder befüllt sind', 'ok', 15);
      return;
    }
    let result: InterruptionAddData = {
      text: this.form.controls['text'].value,
      speakers: groupsSelected
    }
    this.dialogRef.close(result);
  }

  atLeastOneGroupValidator(): ValidatorFn {
    return () : ValidationErrors | null => {
      let rv = {noGroupSelected:true};
      this.data.possibleSpeakers.forEach(group => {
        if(this.form.controls[group.id].getRawValue() && this.form.controls[group.id].getRawValue() !== '') {
          rv = null;
          this.removeGroupValidationErrorFromAllControls();
        }
      });
      return rv;
    }
  }

  addGroupValidationErrorToAllControls() {
    this.data.possibleSpeakers.forEach(group => {
      let control = this.form.controls[group.id];
      if(control.errors === null) {
        control.setErrors({noGroupSelected:true})
      }
    });
  }

  removeGroupValidationErrorFromAllControls() {
    this.data.possibleSpeakers.forEach(group => {
      let control = this.form.controls[group.id];
      let errors = {};
      if(control.errors === null)
        return;
      Object.keys(control.errors).forEach(x => {
        if(x !== 'noGroupSelected') {
          errors[x] = control.getError(x);
        }
      });
      if(Object.keys(errors).length === 0)
        errors = null;
      control.setErrors(errors)
    });

  }
}

export interface InterruptionAddData {
  //mandatory as a result of dialog
  text?: string,
  speakers?: SimpleSubGroupDto[],
  //mandatory to send to dialog
  possibleSpeakers?: CategoryGroupDataDto[]
}
