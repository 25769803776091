import { AfterViewInit, Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { UserDetailDto } from 'src/app/api/model/userDetailDto';
import { UserEndpointService } from 'src/app/api/api/userEndpoint.service';
import { NotificationServiceService } from 'src/app/services/notification-service.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements AfterViewInit{
  displayedColumns: string[] = ['name', 'role', 'actions'];
  dataSource = new MatTableDataSource();

  @Input()
  organisationId: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<TableUser>;

  constructor(private notificationService: NotificationServiceService, private userService: UserEndpointService) {
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public load() {
    console.log(this.organisationId)

    this.userService.getAllUsersByOrganisationId().subscribe({
      next: data => {
        this.convertData(data);
      }, 
      error: error => {
        this.notificationService.open('Something went wrong while trying to get all users', 'ok', 15);
        console.error(error);
      }
    })
  }

  convertData(data: UserDetailDto[]) {
    let converted: TableUser[] = [];
    data.forEach(x => {
      let item: TableUser = {
        id: x.id,
        name: x.username,
        role: x.role,
        actions: 'yes'
      }
      converted.push(item);
    });
    this.dataSource.data = converted;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.hasChangedOrgId(changes) && this.organisationId != null) {
      this.load();
    }
  }

  hasChangedOrgId(changes: SimpleChanges): boolean {
    return Object.keys(changes).find(x => x === 'organisationId').length > 0;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  nameDisplay(user: TableUser) {
    let rv = 'loading...';
    if(user) {
      if(user.role === 'DISABLED') {
        rv = 'Disabled user';
      } else {
        rv = user.name;
      }
    }
    return rv;
  }

}

interface TableUser {
  id: string,
  name: string,
  role: string,
  actions: string
}