<h2>{{title}}</h2>
<div class="chartWrapper">
    <canvas id="{{canvasId}}">{{ chart }}</canvas>
    <mat-accordion *ngIf="additionalInfo.length !== 0">
        <mat-expansion-panel *ngFor="let info of additionalInfo">
          <mat-expansion-panel-header>
            <mat-panel-title class="panelTitle">
                <div>
                    {{info.short}}
                </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p *ngFor="let cat of info.long">- {{cat}} </p>
        </mat-expansion-panel>
    </mat-accordion>
</div>
