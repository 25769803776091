import { Component, OnInit } from '@angular/core';
import { LoginEndpointService } from './api/api/loginEndpoint.service';
import { AuthService } from './services/auth.service';
import { Globals } from './global/globals';
import { Subscription } from 'rxjs/internal/Subscription';
import { NewLogoService } from './services/new-logo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Fair Meeting';
  imageVerison = 0;
  imageUrl: string;
  orgaId: string;

  constructor(private loginService: LoginEndpointService, public authService: AuthService, private newLogo: NewLogoService) {
  }

  ngOnInit() {
    if(this.authService.isLoggedIn()){
      this.loginService.authenticated().subscribe({
          next: data => {
              this.orgaId = data.organisation.id;
              const backendUrl = new Globals().backendUri;
              if (this.authService.isLoggedIn()) {
                this.imageVerison ++;
                this.imageUrl = `${backendUrl}/api/v1/organisations/${this.orgaId}/logo?t=${this.imageVerison}`
              }         
            },
          error: error => {
              console.error(error);
          }
      })
    }
  }

  notifierSubscription: Subscription = this.newLogo.subjectNotifier.subscribe(notified => {
    if (this.authService.isLoggedIn()) {
      this.loginService.authenticated().subscribe({
          next: data =>{
            this.orgaId = data.organisation.id;
              const backendUrl = new Globals().backendUri;
              this.imageVerison ++;
              this.imageUrl = `${backendUrl}/api/v1/organisations/${this.orgaId}/logo?t=${this.imageVerison}`
          }
      })
    }

  })
}
