import {Component, ViewChild} from '@angular/core';
import { Router} from '@angular/router';
import {ThemeDto} from 'src/app/api/model/themeDto';
import {OrganisationDto} from 'src/app/api/model/organisationDto';
import {AuthService} from 'src/app/services/auth.service';
import {NgForm} from '@angular/forms';
import {OrganisationEndpointService} from 'src/app/api/api/organisationEndpoint.service';
import {ThemeEndpointService} from 'src/app/api/api/themeEndpoint.service';
import {LoginEndpointService} from 'src/app/api/api/loginEndpoint.service';
import {NotificationServiceService} from 'src/app/services/notification-service.service';
import {ThemeServiceService} from 'src/app/services/theme-service.service';
import {FileDetails} from "../../file-details.model";
import {FileUploadService} from "../../services/file-upload.service";
import {NewLogoService} from "../../services/new-logo.service";

@Component({
  selector: 'app-organisation-detail-view',
  templateUrl: './organisation-detail-view.component.html',
  styleUrls: ['./organisation-detail-view.component.scss']
})
export class OrganisationDetailViewComponent {
  defaultTheme: ThemeDto = {
    id: null,
    name: 'notatheme',
    scssClassName: 'notatheme'
  };

  organisation: OrganisationDto = {
    id: null,
    name: 'Laden...',
    theme: this.defaultTheme
  }
  file!: File;
  fileDetails!: FileDetails;
  fileUris: Array<String> = [];

  @ViewChild('orgForm') form: NgForm;
  @ViewChild('picForm') formPic: NgForm;

  themes: ThemeDto[] = [];
  displayName: string = 'Laden...';

  constructor(private authService: AuthService, private router: Router, private organisationService: OrganisationEndpointService,
              private themeEndpointService: ThemeEndpointService, private loginService: LoginEndpointService,
              private notificationService: NotificationServiceService, private themeService: ThemeServiceService,
              private fileUploadService: FileUploadService, private newLogo: NewLogoService) {
  }

  reload() {
    this.themeEndpointService.getAllThemes().subscribe(data => {
      this.themes = data
    });
    this.changeTheme(this.themeService.getActiveTheme());

    this.loginService.authenticated().subscribe({
      next: data => {
        this.organisation = data.organisation;
        this.displayName = data.organisation.name;
      },
      error: error => {
        console.error(error);
        this.notificationService.open('Ein unerwarteter Fehler ist aufgetreten', 'ok', 15);
        this.router.navigate(['/']);
      }
    })
  }

  ngOnInit() {
    this.reload();
  }

  addUser() {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/user/create']);
    }
  }

  changeTheme(theme) {
    this.themeService.changeTheme(theme);
  }

  save() {
    if (!this.form.valid) {
      this.notificationService.open('Bitte prüfen Sie, ob die Eingaben korrekt sind', 'ok', 15)
      return;
    }
    let body: OrganisationDto = {
      id: this.organisation.id,
      name: this.organisation.name,
      theme: this.themes.filter(x => x.scssClassName === this.themeService.getChosenTheme())[0]
    };

    this.organisationService.updateOrganisation(body, this.organisation.id).subscribe({
      next: () => {
        this.notificationService.open('Organisation erfolgreich bearbeitet', 'ok', 5);
        this.reload();
      },
      error: error => {
        console.error(error);
        this.notificationService.open('Beim Bearbeiten der Organisation ist ein Fehler aufgetreten', 'ok', 15);
      }
    })
  }

  selectFile(event: any) {
    this.file = event.target.files.item(0);
  }

  uploadFile() {
    if (this.file) {
      if (this.file.size < 1000000) {
        console.log(this.file.size)
        this.fileUploadService.upload(this.file, `api/v1/organisations/${this.organisation.id}/logo`).subscribe({
          next: (data) => {
            this.fileDetails = data;
            this.notificationService.open('Logo erfolgreich bearbeitet', 'ok', 5);
            this.newLogo.notifyAboutChange();
          },
          error: (e) => {
            console.error(e);
            this.notificationService.open('Beim Bearbeiten des Logos ist ein Fehler aufgetreten', 'ok', 15);
          }
        })

      } else {
        this.notificationService.open('Datei ist zu groß', 'ok', 15);
      }

    } else {
      this.notificationService.open('Keine Datei ausgewählt', 'ok', 15);
    }
  }
}
