import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ParticipantDto } from 'src/app/api/model/participantDto';
import { ParticipantEndpointService } from 'src/app/api/api/participantEndpoint.service';
import { CategoryGroupDataDto } from 'src/app/api/model/categoryGroupDataDto';
import { NotificationServiceService } from 'src/app/services/notification-service.service';

@Component({
  selector: 'app-speaker-switch-dialog',
  templateUrl: './speaker-switch-dialog.component.html',
  styleUrls: ['./speaker-switch-dialog.component.scss']
})
export class SpeakerSwitchDialogComponent {

  @ViewChild('form') form: NgForm;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  participants: ParticipantDto[] = [];
  filteredOptions: ParticipantDto[] = [];

  constructor(public dialogRef: MatDialogRef<SpeakerSwitchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SpeakerSwitchDialogData, private notificationService: NotificationServiceService, 
    private participantService: ParticipantEndpointService) {}
  

  ngOnInit() {
    this.dialogRef.backdropClick().subscribe(() => this.cancel());
    this.participantService.getAll().subscribe({
      next: data => {
        this.participants = data;
        this.filteredOptions = this.participants.splice(0,0);
      },
      error: error => {
        console.error(error);
        this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
      }
    })
  }

  getDisplayNameOfParticipant(participant: ParticipantDto) {
    let rv = '' + participant.number;
    if(participant.name) {
      rv += ', ' + participant.name;
    }
    return rv;
  }

  filter() {
    const filterValue = this.input.nativeElement.value.toLowerCase();
    this.filteredOptions = this.participants.filter(x => this.getDisplayNameOfParticipant(x).toLowerCase().includes(filterValue));
  }

  deleteSearch() {
    this.input.nativeElement.value = null;
  }

  getDisplayNameOfParticipantWithId(id: string) {
    if(id)
      return this.getDisplayNameOfParticipant(this.participants.find(x => x.id === id));
  }

  applyParticipant() {
    let value = this.input.nativeElement.value;
    if(value.includes(',')) {
      value = value.split(',')[0];
    }
    if(!value || value.length === 0 || isNaN(Number(value))) {
      this.notificationService.open('Bitte gehen Sie sicher, dass Ihre Eingabe korrekt ist');
      return;
    }
    let participant = this.participants.find(x => x.number === Number(value));
    if(!participant) {
      this.notificationService.open('Bitte gehen Sie sicher, dass Ihre Eingabe korrekt ist');
      return;
    }

    this.data.groups.forEach(group => {
      this.form.controls[group.id].setValue('');
    });

    participant.groups.forEach(overgroup => {
      let control = this.form.controls[overgroup.group.id];
      if(control)
        control.setValue(overgroup.category.id);
    });
  }

  selectionChanged() {
    this.applyParticipant();
  }

  hasValue() {
    return this.input?.nativeElement.value?.length > 0
  }
  
  cancel() {
    this.dialogRef.close(false);
  }
  
  save() {
    if(!this.form.valid) {
      this.notificationService.open('Bitte gehen Sie sicher, dass alle Ihre Eingaben valide sind', 'ok', 15);
      return;
    }

    let result = {};
    this.data.groups.forEach(group => {
      result[group.id] = this.form.controls[group.id].value === '' ? null : this.form.controls[group.id].value;
    });
    this.dialogRef.close(result);
  }
}

export interface SpeakerSwitchDialogData {
  groups: CategoryGroupDataDto[]
}
