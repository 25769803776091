import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {LogEndpointService} from 'src/app/api/api/logEndpoint.service';
import {LoginEndpointService} from 'src/app/api/api/loginEndpoint.service';
import {NewLogoService} from "../../services/new-logo.service";
import {Subscription} from "rxjs";
import {Globals} from "../../global/globals";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']

})
export class HeaderComponent implements OnInit {
  orgaId: string;

  constructor(private loginService: LoginEndpointService, public authService: AuthService, private router: Router, private logService: LogEndpointService,
              private newLogo: NewLogoService) {
  }


  ngOnInit() {

  }
}
