<div class="wrapper">
  <div class="headingContainer">
  <h2>{{meetingTemplate.name}} Vergleichsansicht</h2>
  </div>
  <div class="tabWrapper">
      <mat-tab-group (selectedIndexChange)="tabChanged($event)" #tabGroup>
          <mat-tab label="Sprechzeiten">
            <div class="timesWrapper">
              <div class="timesContainer" *ngFor="let meeting of meetings" id="times:{{meeting.id}}">

                <div class="checkBoxDiv">
                  <label><strong>{{meeting.name}}</strong></label>
                <!--  <mat-slide-toggle (click)="addRemoveLogSelection(log)" [ngModel]="getToggleState(log)">Akzeptieren</mat-slide-toggle>-->
                </div>
                <div class="chartContainer" *ngIf="groups.length > 0 && allChartData[meeting.id]">
                  <label><strong>{{groups[0]?.name}}</strong></label>
                  <app-basic-chart [data]="allChartData[meeting.id][groups[0].id]" [canvasId]="meeting.id + '5'"></app-basic-chart>
                </div>
                <div class="chartContainer" *ngIf="groups.length > 1 && allChartData[meeting.id]">
                  <label><strong>{{groups[1]?.name}}</strong></label>
                  <app-basic-chart [data]="allChartData[meeting.id][groups[1].id]" [canvasId]="meeting.id + '6'"></app-basic-chart>
                </div>
                <div class="chartContainer" *ngIf="groups.length > 2 && allChartData[meeting.id]">
                  <label><strong>{{groups[2]?.name}}</strong></label>
                  <app-basic-chart [data]="allChartData[meeting.id][groups[2].id]" [canvasId]="meeting.id + '7'"></app-basic-chart>
                </div>
              </div>
            </div>
       </mat-tab>

        <mat-tab label="Unterbrechungen">
          <div class="interruptionsWrapper">
            <div class="timesContainerContainer" *ngFor="let logMeeting of logsAll;let i = index">

              <h3><strong>{{logName(logMeeting)}}</strong></h3>
              <div class="interruptionContainer" *ngFor="let log of logMeeting">
                <label><strong>Protokoll von {{log.user.username}}</strong></label>
                <app-interruption-bar-char *ngIf="interruptionsAll[i][log.id]?.length !== 0" [data]="interruptionsChartDataAll[i][log.id]" [canvasId]="log.id + 'interruption'"></app-interruption-bar-char>
                <p *ngIf="interruptionsAll[i][log.id]?.length === 0">Keine Unterbrechungen aufgezeichnet</p>
                <mat-accordion>
                  <mat-expansion-panel *ngFor="let interruption of interruptionsAll[i][log.id] | orderBy: 'timestamp'">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{interruption.text | shorten: 8: '...'}}
                      </mat-panel-title>
                      <mat-panel-description>
                        Erstellt um {{interruption.timestamp | date:'HH:mm'}} | Verursacht von {{getNamesFromSubgroupArray(interruption.byGroups) | shorten: 8: '...'}}
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <p>Verursacht von {{getNamesFromSubgroupArray(interruption.byGroups) | shorten: 30: '...'}}</p>
                    <p>{{interruption.text | shorten: 50: '...'}}</p>
                  </mat-expansion-panel>
                </mat-accordion>
                <div class="interruptionsPanelDiv">
                </div>
              </div>
            </div>
          </div>
        </mat-tab>

          <mat-tab label="Notizen">
            <div class="notesWrapperWrapper">
                <div class="notesContainerContainer" *ngFor="let logMeeting of logsAll">
                    <h3><strong>{{logName(logMeeting)}}</strong></h3>
                    <div class="notesWrapper">
                        <div class="notesContainer" *ngFor="let log of logMeeting">
                            <label>Protokoll von {{log.user.username}}</label>
                            <mat-accordion>
                                <mat-expansion-panel *ngFor="let note of log.notes | orderBy: '-updatedAt'">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            {{note.note | shorten: 20: '...'}}
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            Erstellt um {{note.createdAt | date:'HH:mm'}} | Aktualisiert
                                            um {{note.updatedAt | date:'HH:mm'}}
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <p>{{note.note | shorten: 50: '...'}}</p>
                                </mat-expansion-panel>
                            </mat-accordion>
                            <p *ngIf="log.notes.length === 0">Keine Notizen vorhanden</p>
                        </div>
                    </div>
                </div>
              </div>
          </mat-tab>
      </mat-tab-group>
  </div>
</div>
