<div class="wrapper">
    <div class="headingContainer">
    <h1>Protokoll Vergleichsansicht</h1>
    </div>
    <div class="tabWrapper">
        <mat-tab-group (selectedIndexChange)="tabChanged($event)" #tabGroup>
            <mat-tab label="Sprechzeiten">
                <div class="timesWrapper">
                    <div class="timesContainer" [ngClass]="log.state === 'IGNORED' ? 'grayScale' : ''" *ngFor="let log of logs" id="times:{{log.id}}">
                        
                        <div class="checkBoxDiv">
                            <label><strong>Protokoll von {{log.user.username | shorten: 12: '...'}}</strong></label>
                            <mat-slide-toggle *ngIf="canEditLog(log.user.id)" (click)="addRemoveLogSelection(log)" [ngModel]="getToggleState(log)">Akzeptieren</mat-slide-toggle>
                        </div>
                        <div class="chartContainer" *ngIf="groups.length > 0 && allChartData[log.id]">
                            <label><strong>{{groups[0]?.name}}</strong></label>
                            <app-basic-chart [data]="allChartData[log.id][groups[0].id]" [canvasId]="log.id + '0'"></app-basic-chart>
                            <hr>
                        </div>
                        <div class="chartContainer" *ngIf="groups.length > 1 && allChartData[log.id]">
                            <label><strong>{{groups[1]?.name}}</strong></label>
                            <app-basic-chart [data]="allChartData[log.id][groups[1].id]" [canvasId]="log.id + '1'"></app-basic-chart>
                            <hr>
                        </div>
                        <div class="chartContainer" *ngIf="groups.length > 2 && allChartData[log.id]">
                            <label><strong>{{groups[2]?.name}}</strong></label>
                            <app-basic-chart [data]="allChartData[log.id][groups[2].id]" [canvasId]="log.id + '2'"></app-basic-chart>
                            <hr>
                        </div>
                    </div>
                </div>
            </mat-tab>
    
            <mat-tab label="Unterbrechungen">
                <div class="interruptionsWrapper">
                    <div class="interruptionContainer" *ngFor="let log of logs">
                        <label><strong>Unterbrechungen im Protokoll von {{log.user.username | shorten: 12: '...'}}</strong></label>
                        <app-interruption-chart *ngIf="interruptions[log.id]?.length !== 0" [data]="interruptionsChartData[log.id]" [canvasId]="log.id + 'interruption'"></app-interruption-chart>
                        <hr *ngIf="interruptions[log.id]?.length !== 0">
                        <div class="interruptionsPanelDiv">
                            <p *ngIf="interruptions[log.id]?.length === 0">Keine Unterbrechungen vorhanden</p>
                            <mat-accordion>
                                <mat-expansion-panel *ngFor="let interruption of interruptions[log.id] | orderBy: 'timestamp'">
                                  <mat-expansion-panel-header>
                                    <mat-panel-title class="panelTitle">
                                        <div>
                                            {{interruption.text | shorten: 8: '...'}}
                                        </div>
                                      
                                        <div class="colorRectangle" [style]="'background-color:' + interruption.color"></div>
                                    </mat-panel-title>
                                    <mat-panel-description>
                                      Erstellt um {{interruption.timestamp | date:'HH:mm'}} | Verursacht von {{getNamesFromSubgroupArray(interruption.byGroups) | shorten: 8: '...'}}
                                    </mat-panel-description>
                                  </mat-expansion-panel-header>
                                  <p>Verursacht von {{getNamesFromSubgroupArray(interruption.byGroups) | shorten: 30: '...'}}</p>
                                  <p>{{interruption.text | shorten: 50: '...'}}</p>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                </div>
            </mat-tab>
            
            <mat-tab label="Notizen">
                <div class="notesWrapper">
                    <div class="notesContainer" *ngFor="let log of logs">
                        <label><strong>Protokoll von {{log.user.username | shorten: 12: '...'}}</strong></label>
                        <mat-accordion>
                            <mat-expansion-panel *ngFor="let note of log.notes | orderBy: '-updatedAt'">
                              <mat-expansion-panel-header>
                                <mat-panel-title>
                                  {{note.note | shorten: 8: '...'}}
                                </mat-panel-title>
                                <mat-panel-description>
                                  Erstellt um {{note.createdAt | date:'HH:mm'}} | Aktualisiert um {{note.updatedAt | date:'HH:mm'}}
                                </mat-panel-description>
                              </mat-expansion-panel-header>
                              <p>{{note.note | shorten: 50: '...'}}</p>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <p *ngIf="log.notes.length === 0">Keine Notizen vorhanden</p>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    </div>