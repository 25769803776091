<div class="wrapper">
    <div class="headingContainer">
    <h1>Daten alter Meetings verwenden</h1>
    </div>
    <form #form="ngForm" name="form">
        <mat-form-field class="upperField">
            <mat-label>Meeting</mat-label>
            <input #input
                   type="text"
                   placeholder="Suchen Sie nach Name des Meetings"
                   matInput
                   name="meetingInput"
                   ngModel
                   [matAutocomplete]="auto"
                   (input)="filter()"
                   (focus)="filter()"
                   autofocus="false">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="getDisplayNameOfMeetingWithId.bind(this)">
                <mat-option (click)="selectionChanged()" *ngFor="let meeting of filteredOptions | orderBy: 'number'" [value]="meeting.id">{{meeting.name}}</mat-option>
            </mat-autocomplete>
            <button *ngIf="hasValue()" (click)="deleteSearch()" matSuffix mat-icon-button aria-label="delete text in meeting input field" type="button">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <div class="buttonContainer">
        <button (click)="cancel()" mat-flat-button color="accent" type="button">
            Abbrechen
        </button>
        <button mat-flat-button color="primary" type="submit" (click)="openWithoutMeeting()" cdkFocusInitial>
            ohne Vorlage weiter
        </button>        
        <button mat-flat-button color="primary" type="submit" (click)="openWithMeeting()" [disabled]="!hasValue()">
            weiter mit Vorlage
        </button>
        </div>
    </form>
</div>

