import { Component } from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {NotificationServiceService} from "../../services/notification-service.service";
import {MeetingDto} from 'src/app/api/model/meetingDto';
import {ThemeServiceService} from "../../services/theme-service.service";
import {AuthService} from "../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import { LogDetailDto } from 'src/app/api/model/logDetailDto';
import { MeetingEndpointService } from 'src/app/api/api/meetingEndpoint.service';
import { LoginEndpointService } from 'src/app/api/api/loginEndpoint.service';
import { LogEndpointService } from 'src/app/api/api/logEndpoint.service';
import { LogListDto } from 'src/app/api/model/logListDto';
import { LogCompareViewMode } from '../log-compare-view/log-compare-view.component';

@Component({
  selector: 'app-log-detail-view',
  templateUrl: './log-detail-view.component.html',
  styleUrls: ['./log-detail-view.component.scss']
})
export class LogDetailViewComponent {
  displayedColumns: string[] = ['name', 'state', 'actions'];

  meetingId: string = null;
  meeting: MeetingDto = null;

  logDtos: LogListDto[] = [];

  datasource = new MatTableDataSource();
  constructor(private route: ActivatedRoute, private dialog: MatDialog, private notificationService: NotificationServiceService,
              private meetingService: MeetingEndpointService, private loginService: LoginEndpointService,
              private themeService: ThemeServiceService, private logService: LogEndpointService,
              private  authService: AuthService, private router: Router) {
  }

  reload() {
    this.meetingId = this.route.snapshot.params['id'];
    this.loginService.authenticated().subscribe({
      next: data => {
        this.meetingService.getById1(this.meetingId).subscribe({
          next: data => {
            this.meeting = data;
          },
          error: error => {
            console.error(error)
            this.notificationService.open('Beim Laden ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
          }
        });
        this.logService.getAllByMeeting(this.meetingId).subscribe({
          next: data => {
            this.logDtos = data;
            this.datasource.data = this.logDtos;
          },
          error: error => {
            console.error(error)
            this.notificationService.open('Beim Laden ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
          }
        });
      },
      error: error => {
        console.error(error)
        this.notificationService.open('Ein unerwarteter Fehler ist aufgetreten', 'ok', 15);
      }
    });
  }

  logIsRunning(logState){
    if(logState == LogDetailDto.StateEnum.RUNNING) return true;
    return false;
  }

  logBelongsToMe(user){
    return this.authService.getId()==user.id;
  }
  
  ngOnInit() {
    this.themeService.changeTheme(this.themeService.getActiveTheme());
    this.reload();
  }

  logIsViewable(log: LogListDto) {
    return log.state !== LogDetailDto.StateEnum.RUNNING;
  }

  navigateToView(logId: string) {
    this.router.navigateByUrl('/log/view/' + logId, {state: {mode:  LogCompareViewMode.SINGLE}})
  }

  stateDisplayName(state: LogDetailDto.StateEnum) {
    if(state === LogDetailDto.StateEnum.FINISHED)
      return 'Beendet';
    if(state === LogDetailDto.StateEnum.RUNNING)
      return 'Protokoll läuft';
    if(state === LogDetailDto.StateEnum.IGNORED)
      return 'Ausgeblendet';
  }
}
