import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CategoryEndpointService } from 'src/app/api/api/categoryEndpoint.service';
import { MeetingTemplateEndpointService } from 'src/app/api/api/meetingTemplateEndpoint.service';
import { MeetingTemplateMinimalDto } from 'src/app/api/model/meetingTemplateMinimalDto';
import { ConfirmDialogComponent, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { NotificationServiceService } from 'src/app/services/notification-service.service';
import { ThemeServiceService } from 'src/app/services/theme-service.service';

@Component({
  selector: 'app-meeting-templates-create-update-dialog',
  templateUrl: './meeting-templates-create-update-dialog.component.html',
  styleUrls: ['./meeting-templates-create-update-dialog.component.scss']
})
export class MeetingTemplatesCreateUpdateDialogComponent {
  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('form') form: NgForm;

  isUpdate: boolean = false;

  constructor(private dialog: MatDialog, public dialogRef: MatDialogRef<MeetingTemplatesCreateUpdateDialogComponent>,
    private notificationService: NotificationServiceService, private categoryService: CategoryEndpointService,
    private meetingTemplateService: MeetingTemplateEndpointService,
    @Inject(MAT_DIALOG_DATA) public data: TemplateDialogData, private themeService: ThemeServiceService) {
    
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if(this.isUpdate) {
        this.form.controls["name"].setValue(this.data.template.name);
      }
    }, 0);

    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.themeService.changeTheme(this.themeService.getActiveTheme());
    this.isUpdate = this.data.template ? true : false;
    this.meetingTemplateService.getAllMeetingTemplatesOfOrganisation().subscribe(data => {
    })
  }

  cancel() {
    let dialogData: ConfirmDialogData = {
      message: 'Wollen Sie das ' + (this.isUpdate ? 'Bearbeiten ' : 'Erstellen') + ' wirklich abbrechen?',
      denyButtonText: 'Nein',
      acceptButtonText: 'Ja'
    }
    let ref = this.dialog.open(ConfirmDialogComponent, {data: dialogData});
    ref.afterClosed().subscribe(result => {
      if (result === true) {
        this.dialogRef.close(false);
      }
    });  
  }

  save() {
    if(!this.form.valid) {
      this.notificationService.open('Die Eingaben entsprechen nicht den Vorgaben', 'ok', 15);
      return;
    }

    let body: MeetingTemplateMinimalDto = {
      id: this.isUpdate ? this.data.template.id : null,
      name: this.form.controls['name'].value
    }

    if(this.isUpdate) {
      this.meetingTemplateService.updateMeetingTemplate(body, this.data.template.id).subscribe({
        next: () => {
          this.notificationService.open(  body.name + ' erfolgreich bearbeitet', 'ok', 5);
          this.dialogRef.close(true);
        },
        error: error => {
          console.error(error);
          this.notificationService.open('Ein unerwarteter Fehler ist beim Bearbeiten von ' + body.name +' aufgetreten', 'ok', 15);
        }
      });
    } else {
      this.meetingTemplateService.createMeetingTemplate(body.name).subscribe({
        next: () => {
          this.notificationService.open(body.name + " wurde erfolgreich erstellt", 'ok', 5);
          this.dialogRef.close(true);
        },
        error: error => {
          console.error(error);
          this.notificationService.open('Ein unerwarteter Fehler ist beim Erstellen aufgetreten', 'ok', 15);
        }
      });
    }
  }

  getHeading() {
    return this.isUpdate ? 'bearbeite ' + this.data.template.name : 'neue Sitzungsvorlage erstellen';
  }

}


export interface TemplateDialogData {
  template?: MeetingTemplateMinimalDto,
  organisationId: string
}
