import { Component, OnInit } from '@angular/core';
import { ThemeServiceService } from 'src/app/services/theme-service.service';

@Component({
  selector: 'app-meeting-view',
  templateUrl: './meeting-view.component.html',
  styleUrls: ['./meeting-view.component.scss']
})
export class MeetingViewComponent implements OnInit{
  constructor(private themeService: ThemeServiceService) {
  }

  ngOnInit(){
    this.themeService.changeTheme(this.themeService.getActiveTheme());
  }
}
