import { Component, Input, SimpleChanges } from '@angular/core';
import { Chart, ChartData } from 'chart.js';

@Component({
  selector: 'app-cross-interruption-chart',
  templateUrl: './cross-interruption-chart.component.html',
  styleUrls: ['./cross-interruption-chart.component.scss']
})
export class CrossInterruptionChartComponent {
  @Input({required: true})
  data1: BasicChartData = null;

  @Input({required: true})
  data2: BasicChartData = null;

  @Input({required: false})
  canvasId: string = 'chart';

  @Input({required: false})
  title: string = 'chart';

  @Input({required: false})
  additionalInfo = [];

  @Input({required: false})
  oneTimeLoad: boolean = false;

  chartData: ChartData = {
    datasets: []
  };

  chart: Chart = null;

  reload() {
    if(this.chart !== null) {
      this.chart.destroy();
    }
    console.log(this.data1)
    console.log(this.data2)
    if(!this.data1 || Object.keys(this.data1).length == 0 || !this.data2 || Object.keys(this.data2).length == 0)
      return;
    this.chartData['labels'] = Object.keys(this.data1.data).map(x => this.data1.data[x].label);

    if(this.canvasId.includes("mixedchart")) {
      //setting timout so that in the event of a reload, all canvases are destroyed before the first one is recreated
      setTimeout(() => {
        let canvases = document.querySelectorAll('canvas');
        let idx = 0;
        while(idx < canvases.length && canvases[idx]?.id !== '') {
          idx++;
        }
        if(idx < canvases.length) {
          canvases[idx].id = this.canvasId;
        }

        console.log(Object.keys(this.data2.data).map(x => this.data2.data[x].data))
        console.log(Object.keys(this.data1.data).map(x => this.data1.data[x].data))
        this.chart = new Chart(this.canvasId, {
          type: 'bar',
          data: {
            datasets: [{
              label: "Anzahl Unterbrechungen",
              type: 'bar',
              data: Object.keys(this.data1.data).map(x => this.data1.data[x].data)
            },{
              label: "Meeting Sprecher*in",
              type: 'line',
              data: Object.keys(this.data2.data).map(x => this.data2.data[x].data),
            }],
            labels: Object.keys(this.data1.data).map(x => this.data1.data[x].label)
          },
          options: {
            animation: false
          }
        });
      }, 0);

    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.reload();
  }
}

export interface BasicChartData {
  data: Object,
  dataName: string
}
