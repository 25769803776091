import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import { LoginEndpointService } from 'src/app/api/api/loginEndpoint.service';
import { UserLoginDto } from 'src/app/api/model/userLoginDto';
import { ThemeServiceService } from 'src/app/services/theme-service.service';
import {NewLogoService} from "../../services/new-logo.service";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup;
  // After first submission attempt, form validation will start
  submitted = false;
  // Error flag
  error = false;
  errorMessage = '';

  constructor(private formBuilder: UntypedFormBuilder, private loginService: LoginEndpointService, private authService: AuthService,
              private router: Router, private themeService: ThemeServiceService) {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(4)]]
    });
  }

  /**
   * Form validation will start after the method is called, additionally an AuthRequest will be sent
   */
  loginUser() {
    this.submitted = true;
    if (this.loginForm.valid && !this.authService.isLoggedIn()) {
      const authRequest: UserLoginDto = {
        username: this.loginForm.controls.username.value,
        password: this.loginForm.controls.password.value
      };
      this.authenticateUser(authRequest);
    } else {
      if (!this.loginForm.valid)
        console.log('Invalid input');
      else
        console.error('already logged in')
    }
  }

  /**
   * Send authentication data to the authService. If the authentication was successfully, the user will be forwarded to the message page
   *
   * @param authRequest authentication data from the user login form
   */
  authenticateUser(authRequest: UserLoginDto) {
    console.log('Try to authenticate user: ' + authRequest.username);
    this.loginForm.reset();

    this.authService.logoutUser();
    this.loginService.login(authRequest).subscribe({
      next: (data) => {
        console.log('Successfully logged in user: ' + authRequest.username);
        this.authService.setToken(data.token);
        this.loginService.authenticated().subscribe({
          next: data => {
            console.log(data);
            document.body.classList.forEach(item=>{
              if(item.endsWith('theme')) {
                  document.body.classList.remove(item) ;
              }
            })
              if(data.theme !== null) {
                document.body.classList.add(data.theme.scssClassName);
                this.themeService.setGetActiveTheme(data.theme.scssClassName)
                this.themeService.setActiveThemeProfile("USER");
              } else{
                document.body.classList.add(data.organisation.theme.scssClassName);
                this.themeService.setGetActiveTheme(data.organisation.theme.scssClassName)
                this.themeService.setActiveThemeProfile("ORG");
              }
          }
        });
        this.router.navigate(['/']);
      },
      error: error => {
        console.log('Could not log in due to:');
        console.log(error);
        this.error = true;
        /*
        if (typeof error.error === 'object') {
          this.errorMessage = error.error.error;
        } else {
          this.errorMessage = error.error;
        }
        */
       this.errorMessage = 'Benutzername oder Passwort sind falsch. Bitte noch einmal versuchen!';
      }
    });
  }

  /**
   * Error flag will be deactivated, which clears the error message
   */
  vanishError() {
    this.error = false;
  }

  ngOnInit() {
    this.themeService.changeTheme('fair-theme');
    if (this.authService.isLoggedIn()) {
      console.log('user already logged in');
      this.error = true
      this.errorMessage = 'Sie sind schon angemeldet. Bitte zuerst abmelden!';
    }
  }

  public checkIfLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  public logout() {
    this.authService.logoutUser();
  }

}
