import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GroupEndpointService } from 'src/app/api/api/groupEndpoint.service';
import { LogEndpointService } from 'src/app/api/api/logEndpoint.service';
import { LoginEndpointService } from 'src/app/api/api/loginEndpoint.service';
import { CategoryGroupDataDto } from 'src/app/api/model/categoryGroupDataDto';
import { LogDetailDto } from 'src/app/api/model/logDetailDto';
import { LogListDto } from 'src/app/api/model/logListDto';
import { UserDetailDto } from 'src/app/api/model/userDetailDto';
import { NotificationServiceService } from 'src/app/services/notification-service.service';
import { ThemeServiceService } from 'src/app/services/theme-service.service';

@Component({
  selector: 'app-cross-type-statistics',
  templateUrl: './cross-type-statistics.component.html',
  styleUrls: ['./cross-type-statistics.component.scss']
})
export class CrossTypeStatisticsComponent implements OnInit, AfterViewInit{
  user: UserDetailDto;
  logs: LogDetailDto[] = [];
  groups: CategoryGroupDataDto[] = [];
  speakingTimes = {};
  sortedLogsByMeeting = [];
  groupDistribution = [];
  speakerAmount = {};
  interruptionsPerMeeting = {};
  speakertimechartdata: Object  = {};
  groupdistributiondata: Object  = {};
  data1CrossInterruption: Object  = {};
  data2CrossInterruption: Object  = {};
  groupsDistributionMeetings: Object = {};
  adInfoGroupDist = [];

  constructor(private notificationService: NotificationServiceService, private themeService: ThemeServiceService,
    private logService: LogEndpointService, private route: ActivatedRoute, private loginService: LoginEndpointService,
    private groupService: GroupEndpointService) {}

ngOnInit() {
this.themeService.changeTheme(this.themeService.getActiveTheme());
this.loginService.authenticated().subscribe({
  next: data => {
    this.user = data;
  },
  error: error => {
    this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
    console.error(error);
  }
})
this.groupService.getAll3().subscribe(data =>{
  this.groups = data;
})

this.logService.getAll2().subscribe({
  next: data => {
    let logs: LogListDto[] = data;
    logs = logs.filter(x => x.state !== LogDetailDto.StateEnum.RUNNING);
    this.logs = [];
    logs.forEach(x => {
      this.logService.getById2(x.id).subscribe({
        next: newLog => {
          this.logs.push(newLog);
        },
        error: error => {
          this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
          console.error(error);
        },
        complete: () => {
          if(this.logs.length === logs.length) {
            this.speakingTimes = this.calculateTalkingTime();
            this.groupsDistributionMeetings = this.groupLogsByCategoryAndMeeting();
            this.calculateInterruptions();
            this.sortedLogsByMeeting = this.sortLogs();
            this.groupDistribution = this.getGroups();
            this.interruptionsPerMeeting = this.calculateInterruptionsByMeeting();
            this.speakerAmount = this.countSpeakersPerMeeting();
            this.readChartData();
          }
        }
      });
    });
  },
  error: error => {
    this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
    console.error(error);
  }
});
}

  ngAfterViewInit() {
  }

readChartData() {
  this.speakertimechartdata = this.getChartData();
  this.groupdistributiondata = this.getBarChartData();
  this.data1CrossInterruption = this.getBarChartDataInter();
  this.data2CrossInterruption = this.getLineChartData();
}

getChartData() {
  let chartData = {};
  Object.keys(this.speakingTimes).forEach(x => {
      chartData[x] = {data: Math.round(this.speakingTimes[x] / 60000), label: this.getCategoryNameByid(x)};
  });

  let chartOverData = {
    data: chartData,
    dataName: "Sprechzeit (min)"
  }
  return Object.keys(chartData).length > 0 ? chartOverData : null;
}

getBarChartData() {
  let chartData = {};
  Object.keys(this.groupsDistributionMeetings).forEach(x => {
    this.adInfoGroupDist.push({
      short: x,
      long: this.groupsDistributionMeetings[x].categories
    })
    chartData[x] = {data: this.groupsDistributionMeetings[x].amount, label: x};
  });

  let chartOverData = {
    data: chartData,
    dataName: "Gruppe in verschiedenen Meetings"
  }
  return Object.keys(chartData).length > 0 ? chartOverData : null;
}

getMeetingNameForId(x: string): string{
  let res = null;
  res = this.logs.find(log => log.meeting.id == x);
  return res.meeting.name;
}

getBarChartDataInter() {
  let chartData = {};
  Object.keys(this.interruptionsPerMeeting).forEach(x => {
    chartData[x] = {data: this.interruptionsPerMeeting[x].length, label: this.getMeetingNameForId(x)};
  });

  let chartOverData = {
    data: chartData,
    dataName: "Gruppe in verschiedenen Meetings"
  }
  return Object.keys(chartData).length > 0 ? chartOverData : null;
}

getLineChartData() {
  let chartData = {};
  Object.keys(this.speakerAmount).forEach(x => {
    if(this.speakerAmount[x].overall!=undefined){
      chartData[x] = {data: this.speakerAmount[x].overall, label: this.getMeetingNameForId(x)};
    }
  });

  let chartOverData = {
    data: chartData,
    dataName: "Gruppe in verschiedenen Meetings"
  }
  return Object.keys(chartData).length > 0 ? chartOverData : null;
}

getCategoryNameByid(id: string): string {
  let rv = null;
  this.groups.forEach(group => {
    let found = group.categories.find(category => category.id === id);
    if(found)
      rv = found;
  });
  return rv.name;
}

sortLogs() {
  this.logs.forEach(x => {
    if(this.sortedLogsByMeeting.find(y => y.meeting.id == x.meeting.id)){
      this.sortedLogsByMeeting.find(y => y.meeting.id == x.meeting.id).logs.push(x);
    }else{
      this.sortedLogsByMeeting.push({
        meeting: x.meeting,
        logs: [x]
      })
    }
  })
  return this.sortedLogsByMeeting;
}

getGroups() {
  if(this.sortedLogsByMeeting.length <= 0) 
    return;

  let groupshelper = [];
  this.sortedLogsByMeeting.forEach(x => {
    x.logs[0].talkingPoints.forEach(talkingE => {
      talkingE.speakers.forEach(speakerE => {
        if(groupshelper.find(y => (y.group[0].id == speakerE.id))){
          if(groupshelper.find(y => y.group[0].id == speakerE.id).meeting != x.meeting.id){
            groupshelper.find(y => y.group[0].id == speakerE.id).amount++;
            groupshelper.find(y => y.group[0].id == speakerE.id).group.push(speakerE);
            groupshelper.find(y => y.group[0].id == speakerE.id).meeting = x.meeting.id;
          }else{
            groupshelper.find(y => y.group[0].id == speakerE.id).group.push(speakerE);
          }
        }else{
          groupshelper.push({ 
            meeting: x.meeting.id,
            group: [speakerE],
            amount: 1
          });
        }
      });
    });
  });
  groupshelper.forEach(x => {
    this.groupDistribution.push({
      group: x.group,
      amount: x.amount
    });
  })
  return this.groupDistribution;
}

calculateTalkingTime() {
    let categoryTimes = {};
    let alreadyCheckedMeetingForTalkingTime = [];

    this.logs.forEach(log => {
        log.talkingPoints.forEach(point => {
            point.speakers.forEach(speaker => {
                speaker.categories.forEach(category => {
                  if(category.entry.amount > 0){
                    category.entry.data.forEach(talking => {
                      const startTime = new Date(talking.start).getTime();
                      const endTime = new Date(talking.end).getTime();
                      const duration = endTime - startTime;

                      if (categoryTimes[category.id]) {
                        if(!alreadyCheckedMeetingForTalkingTime.find(x => x == log.meeting.id)){
                        categoryTimes[category.id] += duration;
                        }
                      } else {
                          categoryTimes[category.id] = duration;
                      }
                    })
                  }
                });
            });
        });
        alreadyCheckedMeetingForTalkingTime.push(log.meeting.id)
    });

    return categoryTimes;
}

groupLogsByCategoryAndMeeting() {
  const groupedLogs = {};
  let alreadyCheckedMeetingForGroup = [];
  this.logs.forEach(log => {
    log.talkingPoints.forEach(point => {
      point.speakers.forEach(speaker => {
        if (!groupedLogs[speaker.name]) {
          groupedLogs[speaker.name] = {};
        }

        if (!groupedLogs[speaker.name].amount) {
          groupedLogs[speaker.name].amount = 1;
          groupedLogs[speaker.name].categories = speaker.categories.map(x=>x.name);
          alreadyCheckedMeetingForGroup.push(log.meeting.id + speaker.id);
        }else{
          if(!alreadyCheckedMeetingForGroup.find(x => x == log.meeting.id+speaker.id)){
            groupedLogs[speaker.name].amount++;
            alreadyCheckedMeetingForGroup.push(log.meeting.id + speaker.id);
          }
        }
      });
    });
  });
  return groupedLogs;
}

calculateInterruptions() {
    if(this.logs.length <= 0) 
      return;
    let interruptions = {};
    this.logs.forEach(log => {
      let result = [];

      log.interruptions.forEach(group => {
        group.categories.forEach(category => {
          category.entry.data.forEach(entry => {
            let cousin = result.find(x => {
              let temp = {timestamp: x.timestamp, text: x.text};
              let temp2 = {timestamp: new Date(entry.start), text: entry.value};
              return JSON.stringify(temp) === JSON.stringify(temp2);
            });
            if(cousin) {
              cousin.byGroups.push(category);
            } else {
              let toPush = {
                timestamp: new Date(entry.start),
                text: entry.value,
                byGroups: [category],
                color: '#ffffff'
              }
              result.push(toPush);
            }
          });
        });
      });

      interruptions[log.id] = result;
    }); 
  }

  calculateInterruptionsByMeeting() {
    if (this.logs.length <= 0) 
      return;
  
    let meetingInterruptions = {};
  
    this.logs.forEach(log => {
      if (!meetingInterruptions[log.meeting.id]) {
        meetingInterruptions[log.meeting.id] = [];
      }
  
      log.interruptions.forEach(group => {
        group.categories.forEach(category => {
          category.entry.data.forEach(entry => {
            let interruptionEntry = {
              timestamp: new Date(entry.start),
              text: entry.value,
              byGroups: [category],
              color: '#ffffff'
            };
  
            let existingInterruption = meetingInterruptions[log.meeting.id].find(interruption => 
              interruption.timestamp.getTime() === interruptionEntry.timestamp.getTime() && 
              interruption.text === interruptionEntry.text
            );
  
            if (existingInterruption) {
              existingInterruption.byGroups.push(category);
            } else {
              meetingInterruptions[log.meeting.id].push(interruptionEntry);
            }
          });
        });
      });
    });
  
    return meetingInterruptions;
  }

  countSpeakersPerMeeting(){
    const meetingParticipantsCount = {};
  
    this.logs.forEach(log => {
      if (!meetingParticipantsCount[log.meeting.id]) {
        meetingParticipantsCount[log.meeting.id] = [];
      }
  
      log.talkingPoints.forEach(point => {
        point.speakers.forEach(speaker => {
          speaker.categories.forEach(cat => {
            if(cat.entry.amount > 0){
              if(meetingParticipantsCount[log.meeting.id].find(x => x.category.id == cat.id)){
                  meetingParticipantsCount[log.meeting.id].find(x => x.category.id == cat.id).amount += cat.entry.amount                
                }else{
                  meetingParticipantsCount[log.meeting.id].push({
                    category: cat,
                    amount: cat.entry.amount                
                  });
                }
              }
          })
        });
      });
    });
  
    let overoverall = 0;
    for (let meetingId in meetingParticipantsCount) {
      let overall = 0;
      for(let parts in meetingParticipantsCount[meetingId]){
        overall += meetingParticipantsCount[meetingId][parts].amount;
      }
      meetingParticipantsCount[meetingId].overall = overall;
      overoverall += overall;
    }
    meetingParticipantsCount["sumupspeakers"] = overoverall
    return meetingParticipantsCount;
  }
}
