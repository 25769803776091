/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { LogCategoryUpdateDto } from '../model/logCategoryUpdateDto';
import { LogDetailDto } from '../model/logDetailDto';
import { LogListDto } from '../model/logListDto';
import { LogTalkingPointUpdateDto } from '../model/logTalkingPointUpdateDto';
import { LogUpdateStateDto } from '../model/logUpdateStateDto';
import { UpdateTalkingPointTimesDto } from '../model/updateTalkingPointTimesDto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class LogEndpointService {

    protected basePath = 'http://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param meetingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create1(meetingId: string, observe?: 'body', reportProgress?: boolean): Observable<LogDetailDto>;
    public create1(meetingId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LogDetailDto>>;
    public create1(meetingId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LogDetailDto>>;
    public create1(meetingId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meetingId === null || meetingId === undefined) {
            throw new Error('Required parameter meetingId was null or undefined when calling create1.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<LogDetailDto>('post',`${this.basePath}/api/v1/logs/meeting/${encodeURIComponent(String(meetingId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param logId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public delete1(logId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public delete1(logId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public delete1(logId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public delete1(logId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (logId === null || logId === undefined) {
            throw new Error('Required parameter logId was null or undefined when calling delete1.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/v1/logs/${encodeURIComponent(String(logId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAll2(observe?: 'body', reportProgress?: boolean): Observable<Array<LogListDto>>;
    public getAll2(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LogListDto>>>;
    public getAll2(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LogListDto>>>;
    public getAll2(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<LogListDto>>('get',`${this.basePath}/api/v1/logs`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param meetingId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllByMeeting(meetingId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<LogListDto>>;
    public getAllByMeeting(meetingId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LogListDto>>>;
    public getAllByMeeting(meetingId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LogListDto>>>;
    public getAllByMeeting(meetingId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (meetingId === null || meetingId === undefined) {
            throw new Error('Required parameter meetingId was null or undefined when calling getAllByMeeting.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<LogListDto>>('get',`${this.basePath}/api/v1/logs/meeting/${encodeURIComponent(String(meetingId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById2(id: string, observe?: 'body', reportProgress?: boolean): Observable<LogDetailDto>;
    public getById2(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LogDetailDto>>;
    public getById2(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LogDetailDto>>;
    public getById2(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getById2.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<LogDetailDto>('get',`${this.basePath}/api/v1/logs/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCategory(body: Array<LogCategoryUpdateDto>, id: string, observe?: 'body', reportProgress?: boolean): Observable<LogDetailDto>;
    public updateCategory(body: Array<LogCategoryUpdateDto>, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LogDetailDto>>;
    public updateCategory(body: Array<LogCategoryUpdateDto>, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LogDetailDto>>;
    public updateCategory(body: Array<LogCategoryUpdateDto>, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateCategory.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateCategory.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<LogDetailDto>('patch',`${this.basePath}/api/v1/logs/${encodeURIComponent(String(id))}/category`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateLogState(body: LogUpdateStateDto, id: string, observe?: 'body', reportProgress?: boolean): Observable<LogDetailDto>;
    public updateLogState(body: LogUpdateStateDto, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LogDetailDto>>;
    public updateLogState(body: LogUpdateStateDto, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LogDetailDto>>;
    public updateLogState(body: LogUpdateStateDto, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateLogState.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateLogState.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<LogDetailDto>('patch',`${this.basePath}/api/v1/logs/${encodeURIComponent(String(id))}/state`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTalkingPoint(body: Array<LogTalkingPointUpdateDto>, id: string, observe?: 'body', reportProgress?: boolean): Observable<LogDetailDto>;
    public updateTalkingPoint(body: Array<LogTalkingPointUpdateDto>, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LogDetailDto>>;
    public updateTalkingPoint(body: Array<LogTalkingPointUpdateDto>, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LogDetailDto>>;
    public updateTalkingPoint(body: Array<LogTalkingPointUpdateDto>, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateTalkingPoint.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateTalkingPoint.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<LogDetailDto>('patch',`${this.basePath}/api/v1/logs/${encodeURIComponent(String(id))}/talkingpoint`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTalkingPointTimes(body: UpdateTalkingPointTimesDto, id: string, observe?: 'body', reportProgress?: boolean): Observable<LogDetailDto>;
    public updateTalkingPointTimes(body: UpdateTalkingPointTimesDto, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LogDetailDto>>;
    public updateTalkingPointTimes(body: UpdateTalkingPointTimesDto, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LogDetailDto>>;
    public updateTalkingPointTimes(body: UpdateTalkingPointTimesDto, id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateTalkingPointTimes.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateTalkingPointTimes.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            '*/*'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<LogDetailDto>('patch',`${this.basePath}/api/v1/logs/${encodeURIComponent(String(id))}/talkingpoint/times`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
