import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationServiceService } from 'src/app/services/notification-service.service';

@Component({
  selector: 'app-meeting-public-dialog',
  templateUrl: './meeting-public-dialog.component.html',
  styleUrls: ['./meeting-public-dialog.component.scss']
})
export class MeetingPublicDialogComponent {

  link: string = '';

  constructor(private dialogRef: MatDialogRef<MeetingPublicDialogComponent>, private notificationService: NotificationServiceService, 
    @Inject(MAT_DIALOG_DATA) public data: MeetingPublicDialogData) {}

  ngOnInit() {
    this.link = window.location.origin + '/#/public/meeting/' + this.data.meetingId;
  }
  
  cancel() {
    this.dialogRef.close();
  }

  copy() {
    this.notificationService.open('Link erfolgreich kopiert');
  }
}

export interface MeetingPublicDialogData {
  meetingId: string,
  meetingName: string
}
