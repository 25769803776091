<div class="jumbotron mt-5 wrapper">
  <h1 class="display-4">Willkommen bei Fair Meeting!</h1>
  <hr class="my-4">
  <a *ngIf="!authService.isLoggedIn()" class="btn btn-primary btn-lg" routerLink="/login" role="button">Login</a>
  <div *ngIf="authService.isLoggedIn()" style="height: 70vh">
    <div #jumpingdiv>
      <div>
        <img src="../../../assets/img/logo_white.png" alt="" width="200px" height="200px" style="margin: 2rem; float: right;">
        </div>
          <h2>Fairness in der Organisation</h2>
          <p>In der heutigen schnelllebigen Welt ist es unerlässlich, dass jeder Mensch Gehör findet und gleichwertig behandelt wird. 
            Fairness ist nicht nur ein ethisches Ideal, sondern ein praktischer Weg, um Effizienz und Zufriedenheit am Arbeitsplatz zu steigern. 
            Unsere neu entwickelte Webanwendung bietet eine innovative Lösung, um Fairness in Organisationen zu fördern und zu erhalten.</p>
          <h2>Definition von Fairness</h2>
          <p>Fairness bedeutet, allen Teammitgliedern unabhängig von ihrer Position, ihrem Geschlecht oder ihrer Herkunft gleiche Chancen zu geben. 
            Es geht darum, eine ausgewogene Diskussionskultur zu schaffen, in der jede Stimme zählt und berücksichtigt wird.
            </p>
            <h2>Wie unsere App Fairness fördert</h2>
            <p>Unsere App bietet eine einzigartige Funktion, die es ermöglicht, die Redezeit jedes Mitglieds während Meetings genau zu erfassen. 
              Dies stellt sicher, dass alle Teilnehmer*innen gleichmäßig zu Wort kommen können. 
              Unterbrechungen werden ebenfalls erkannt, was hilft, eine respektvolle Diskussionskultur zu etablieren.
              </p>
            <h2>Protokollierung und Analyse von Sitzungen</h2>
            <p>Die Erstellung von Protokollen für jede Sitzung ist ein Schlüsselelement unserer App. 
              Diese Protokolle bieten eine transparente Übersicht über den Verlauf der Diskussionen. 
              Die anschließenden Auswertungen durch Statistiken ermöglichen es, Muster in der Kommunikation zu erkennen und gegebenenfalls anzupassen.
              </p>
              
            <h2>Vergleich von Protokollen</h2>
            <p>Die einzigartige Funktion, Protokolle von verschiedenen Benutzer*innen derselben Organisation miteinander zu vergleichen, ermöglicht eine objektive Bewertung der Fairness in Diskussionen.</p>
      
            <h2>Schlussfolgerung</h2>
            <p>Die ständige Überprüfung und Verbesserung der Kommunikationsmuster ist entscheidend für die Aufrechterhaltung von Fairness in einer Organisation. 
              Unsere Webanwendung bietet die notwendigen Werkzeuge, um diese Herausforderung anzugehen und ein ausgewogeneres, respektvolleres und letztendlich produktiveres Umfeld zu schaffen. 
              Indem wir Fairness immer wieder vor Augen führen, schaffen wir eine stärkere, vereinte und zufriedene Organisation. 
              Zusammenfassend bietet unsere App eine innovative Plattform, die es Organisationen ermöglicht, ihre Kommunikationsdynamik zu analysieren und zu verbessern, um ein faires und effektives Umfeld zu schaffen.
            </p>
    </div>
  </div>
</div>
