<div class="wrapper">
<div class="headingContainer">
    <h1>Benutzer: {{displayName}}</h1>
    <h1>Organisation: {{user.organisation?.name}}</h1>
</div>

<p *ngIf="user.role === roleEnum.DISABLED">Dieser Benutzer ist deaktiviert</p>

<form *ngIf="user.role !== roleEnum.DISABLED" #userForm="ngForm" (ngSubmit)="save()" name="userForm">
    <mat-form-field>
        <mat-label>Benutzername</mat-label>
        <input matInput required minlength="4" maxlength="12" type="text" #username name="username" [(ngModel)]="user.username">
        <mat-hint>Zwischen 4 und 12 Zeichen</mat-hint>
        <mat-error *ngIf="form && form.controls['username'] && form.controls['username'].hasError('required')">
            Benutzername wird <strong>benötigt</strong>
        </mat-error>
        <mat-error *ngIf="form && form.controls['username'] && !form.controls['username'].hasError('required')
        && (form.controls['username'].hasError('minlength') || form.controls['username'].hasError('maxlength'))">
            Der Benutzername muss zwischen 4 und 12 Zeichen haben
        </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="mode === 0">
        <mat-label>Passwort</mat-label>
        <input matInput required minlength="8" type="password" #password name="password" [(ngModel)]="pw">
        <button matSuffix mat-icon-button aria-label="switch password visibility" type="button" (click)="switchPwVisibility()">
            <mat-icon>{{visibilityIcon}}</mat-icon>
        </button>
        <mat-hint>8 characters, 1+ uppercase, 1+ lowercase, 1+ numeric</mat-hint>
        <mat-error *ngIf="form && form.controls['password'] && (form.controls['password'].hasError('passwordStrength') && !form.controls['password'].hasError('required'))">
            Das Passwort muss folgende Kriterien erfüllen: > Zeichen, > Großbuchstabe, > Kleinbuchstabe, > Zahl
        </mat-error>
        <mat-error *ngIf="form && form.controls['password'] && form.controls['password'].hasError('required')">
            Passwort wird <strong>benötigt</strong>
        </mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Theme</mat-label>
        <mat-select #theme name="theme" (ngModelChange)="setTheme($event)" [ngModel]="getThemeId()">
          <mat-option (click)="changeTheme(theme.scssClassName)" *ngFor="let theme of themes" value="{{theme.id}}">{{theme.name}}</mat-option>
          <mat-option (click)="changeTheme('org')" value="org">My Organisation's</mat-option>
        </mat-select>
    </mat-form-field>
    <div class="buttonContainer">
        <button mat-flat-button color="primary" type="submit">
           Speichern
        </button>
    </div>
</form>

<div *ngIf="mode === 1 && user.role !== roleEnum.DISABLED" class="buttonContainer">
    <button mat-raised-button color="primary" (click)="openPwDialog()">
       Passwort ändern
    </button>
  <div matTooltip="Cannot anonymize last admin of organisation"
       [matTooltipDisabled]="!isLastAdminOfOrg">
    <button mat-raised-button color="accent"
            (click)="disableUser()"
            [disabled]="isLastAdminOfOrg">
      Benutzer deaktivieren
    </button>
  </div>
</div>
</div>
