<div class="wrapper">
    <h2>{{data.meetingName}}</h2>
    <div>
        <qrcode
            [qrdata]="link"
            [width]="300"
            [errorCorrectionLevel]="'M'"
            class="qrcode"
        ></qrcode>
    </div>
    <div class="textWrapper">
        <p><strong>Link zur öffentlichen Seite für dieses Meeting:</strong></p>
        <p>{{link}}</p>
    </div>
    <div class="buttonContainer">
        <button (click)="cancel()" mat-flat-button color="accent" type="reset">
            Zurück
        </button>
        <button (click)="copy()" [cdkCopyToClipboard]="link" mat-flat-button color="primary">
            Link kopieren
        </button>
    </div>
</div>
