<div class="wrapper">
    <h2>Live-Feed für die Protokolle des Meetings: {{meeting?.name}}</h2>
    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="datasource" #tableSort="matSort" matSort #table>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Von </th>
                <td mat-cell *matCellDef="let log"> {{log.user.username}} </td>
            </ng-container>

            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let log"> {{stateDisplayName(log.state)}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Aktionen </th>
                <td mat-cell *matCellDef="let log">
                    <button mat-mini-fab color="accent" (click)="navigateToView(log.id)">
                        <mat-icon>info</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">Keine Einträge gefunden</td>
            </tr>
        </table>
        <mat-paginator #paginator="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="select page"></mat-paginator>
    </div>
</div>
