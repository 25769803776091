import { Component, Input, SimpleChanges } from '@angular/core';
import { Chart, ChartData } from 'chart.js';

@Component({
  selector: 'app-speaker-time-chart',
  templateUrl: './speaker-time-chart.component.html',
  styleUrls: ['./speaker-time-chart.component.scss']
})
export class SpeakerTimeChartComponent {

  @Input({required: true})
  data: BasicChartData = null;

  @Input({required: false})
  canvasId: string = 'chart';

  @Input({required: false})
  title: string = 'chart';

  @Input({required: false})
  additionalInfo = [];

  @Input({required: false})
  oneTimeLoad: boolean = false;

  chartData: ChartData = {
    datasets: []
  };

  chart: Chart = null;

  reload() {
    if(this.chart !== null) {
      this.chart.destroy();
    }
    console.log(this.data)
    if(!this.data || Object.keys(this.data).length == 0)
      return;
    this.chartData['labels'] = Object.keys(this.data.data).map(x => this.data.data[x].label);
    
    if(this.canvasId.includes("piechart")) {
      //setting timout so that in the event of a reload, all canvases are destroyed before the first one is recreated
      setTimeout(() => {
        let canvases = document.querySelectorAll('canvas');
        let idx = 0;
        while(idx < canvases.length && canvases[idx]?.id !== '') {
          idx++;
        }
        if(idx < canvases.length) {
          canvases[idx].id = this.canvasId;
        }

        this.chartData['datasets'] = [{
          label: this.data.dataName,
          data: Object.keys(this.data.data).map(x => this.data.data[x].data),
          hoverOffset: 4
        }];
    
        this.chart = new Chart(this.canvasId, {
          type: 'pie',
          data: this.chartData,
          options: {
            animation: false
          }
        });
      }, 0);
      
    } else if(this.canvasId.includes("barchart")) {
      this.chartData['datasets'] = [{
        label: this.data.dataName,
        data: Object.keys(this.data.data).map(x => this.data.data[x].data),
        hoverOffset: 4
      }];
  
      this.chart = new Chart(this.canvasId, {
        type: 'bar',
        data: this.chartData,
        options: {
          animation: false
        }
      });
    } else {
      this.chartData['datasets'] = [{
        label: this.data.dataName,
        data: Object.keys(this.data.data).map(x => this.data.data[x].data),
        hoverOffset: 4
      }];
  
      this.chart = new Chart(this.canvasId, {
        type: 'bar',
        data: this.chartData,
        options: {
          animation: false
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.reload();
  }
}

export interface BasicChartData {
  data: Object,
  dataName: string
}