<div class="wrapper">
    <h2>{{getHeading()}}</h2>
    <form #form="ngForm" (ngSubmit)="save()" name="form">
        <mat-form-field>
            <mat-label>Text</mat-label>
            <input matInput required minlength="2" maxlength="50" type="text" #text name="text" [(ngModel)]="defautlText">
            <mat-error *ngIf="form && form.controls['text'] && form.controls['text'].hasError('required')">
                Text <strong>benötigt</strong>
            </mat-error>
            <mat-error *ngIf="form && form.controls['text'] && !form.controls['text'].hasError('required')
            && (form.controls['text'].hasError('minlength') || form.controls['text'].hasError('maxlength'))">
                Bitte geben Sie zwischen 2 und 50 Zeichen ein
            </mat-error>
        </mat-form-field>

        <mat-form-field *ngFor="let group of data.possibleSpeakers">
            <mat-label>{{group.name}}</mat-label>
            <mat-select name="{{group.id}}" ngModel>
                <mat-option *ngFor="let category of group.categories" [value]="category.id">{{category.name}}</mat-option>
                <mat-option selected></mat-option>
            </mat-select>
            <mat-error *ngIf="form && form.controls[group.id] && form.controls[group.id].hasError('noGroupSelected')">
                Bitte wählen Sie mindestens aus einer Gruppe eine Kategorie aus
            </mat-error>
        </mat-form-field>

        <div class="buttonContainer">
            <button (click)="cancel()" mat-flat-button color="accent" type="reset">
               Abbrechen
            </button>
            <button mat-flat-button color="primary" type="submit">
               Erstellen
            </button>
        </div>
    </form>

</div>
