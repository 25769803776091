<div class="wrapper">
    <div class="headingContainer">
    <h1>{{getTitle()}}</h1>
    </div>
    <form #form="ngForm" (ngSubmit)="save()" name="form">

        <mat-form-field>
            <mat-label>Nummer</mat-label>
                <input matInput required type="number" #number name="number" ngModel>
                <mat-hint>
                    Die Nummer die dem Shortcut zugewiesen wird
                </mat-hint>
                <mat-error *ngIf="form && form.controls['number'] && form.controls['number'].hasError('required')">
                    Bitte geben Sie eine Zahl ein
                </mat-error>
                <mat-error *ngIf="form && form.controls['number'] && !form.controls['number'].hasError('required')
                && form.controls['number'].hasError('bannedNumber')">
                    Diese Zahl ist bereits in Verwendung, bitte wählen Sie eine andere
                </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Name</mat-label>
                <input matInput maxlength="20" type="text" #name name="name" ngModel>
                <mat-hint>
                    Optional | Maximal 20 Zeichen
                </mat-hint>
                <mat-error *ngIf="form && form.controls['name'] && form.controls['name'].hasError('maxlength')">
                    Bitte gebens Sie maximal 20 Zeichen ein
                </mat-error>
        </mat-form-field>

        <mat-form-field *ngFor="let group of groups">
            <mat-label>{{group.name}}</mat-label>
            <mat-select name="{{group.id}}" ngModel>
                <mat-option *ngFor="let category of group.categories" [value]="category.id">{{category.name}}</mat-option>
                <mat-option selected></mat-option>
            </mat-select>
        </mat-form-field>

        <div class="buttonContainer">
            <button (click)="cancel()" mat-flat-button color="accent" type="button">
                Abbrechen
            </button>
            <button mat-flat-button color="primary" type="submit">
            Speichern
            </button>
        </div>
    </form>
</div>
