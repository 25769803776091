<div class="wrapper">
<div class="headingContainer">
    <h1>Protokoll für {{log?.meeting.name}} <strong *ngIf="isProtocolFinished()">(Beendet)</strong></h1>
    <h1>Meeting läuft für (min:s): {{convertTimeInMsToString(time)}}</h1>
</div>
    <div class="growDiv">
        <mat-horizontal-stepper linear [@.disabled] = "true" #stepper>
            <mat-step *ngFor="let tp of log?.talkingPoints | orderBy: 'order'" [editable]="isTpEditable(tp)" [completed]="false">
                <ng-template matStepLabel>{{tp.name}}</ng-template>
                <!--
                <mat-chip-listbox [(ngModel)]="currentSpeaker" (ngModelChange)="switchSpeaker($event)">
                    <mat-chip-option *ngFor="let group of groups" value="{{group.id}}">{{group.name}}</mat-chip-option>
                    <mat-chip-option color="accent" value="pause" [disabled]="currentSpeakers.length === 0">Pause</mat-chip-option>
                </mat-chip-listbox>
                -->
                <div class="insideStepper">
                  <div class="groupBox" *ngFor="let group of groups | orderBy: 'name'">
                    <p><strong>{{group.name}}</strong></p>
                    <mat-chip-listbox class="mat-mdc-chip-set-stacked" [ngModel]="currentSpeakerOfGroup[group.id]">
                      <mat-chip-option *ngFor="let category of group.categories" value="{{category.id}}" disabled>{{category.name}}</mat-chip-option>
                    </mat-chip-listbox>
                  </div>
                </div>

                <div class="buttonContainer" *ngIf="!isViewMode">
                  <button mat-raised-button color="accent" (click)="changeSpeakers(tp)">Sprecher*in ändern<mat-icon>multiple_stop</mat-icon></button>
                  <button mat-raised-button color="accent" (click)="pauseSpeakers(tp)">Pause/Moderation<mat-icon>pause</mat-icon></button>
                  <button mat-raised-button color="accent" (click)="finishTalkingPoint(tp)">{{getStepperButtonText(tp)}}</button>
                </div>
            </mat-step>
          </mat-horizontal-stepper>
    </div>
    <div class="tripleWrapper growDiv">
        <div class="growDiv">
            <div class="headerWrapper">
                <h3>Gesamte Sprechzeiten</h3>
                <mat-slide-toggle [(ngModel)]="displayChart" (ngModelChange)="updateChartData()">Diagramm Anzeigen</mat-slide-toggle>
            </div>
            <!--
            <ul *ngIf="!displayChart">
                <li *ngFor="let group of groups | orderBy: 'name'">
                    <span matListItemTitle>{{group.name}}</span>
                    <mat-list>
                      <mat-list-item *ngFor="let category of group.categories | orderBy: 'name'">
                        <span matListItemTitle>{{category.name}}</span>
                        <span matListItemLine>Sprechzeit: {{convertTimeInMsToString(speakingTimes[category.id])}}</span>
                      </mat-list-item>
                    </mat-list>
                </li>
            </ul>
            -->
            <mat-tree *ngIf="!displayChart" [dataSource]="treeDataSource" [treeControl]="treeControl">
              <!-- This is the tree node template for leaf nodes -->
              <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
                <!-- use a disabled button to provide padding for tree leaf -->
                <button mat-icon-button disabled></button>
                {{node.name}}: {{convertTimeInMsToString(speakingTimes[node.id])}}
              </mat-tree-node>
              <!-- This is the tree node template for expandable nodes -->
              <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                <button mat-icon-button matTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.name">
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                  </mat-icon>
                </button>
                {{node.name}}
              </mat-tree-node>
            </mat-tree>

            <app-basic-chart *ngIf="displayChart" [data]="totalChartData[groups[0].id]" [canvasId]="'chart1'"></app-basic-chart>
        </div>
        <div class="notesSection growDiv">
          <div *ngIf="displayChart && groups.length > 1">
            <br>
            <br>
            <app-basic-chart [data]="totalChartData[groups[1].id]" [canvasId]="'chart2'"></app-basic-chart>
          </div>

            <div *ngIf="!displayChart">
                <h2>Notizen</h2>
                <mat-accordion>
                    <mat-expansion-panel *ngFor="let note of observableNotes | async | orderBy: '-updatedAt'">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          {{note.note | shorten: 8: '...'}}
                        </mat-panel-title>
                        <mat-panel-description>
                          Erstellt um {{note.createdAt | date:'HH:mm'}} | Aktualisiert um {{note.updatedAt | date:'HH:mm'}}
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <p>{{note.note | shorten: 50: '...'}}</p>
                      <button *ngIf="!isViewMode" mat-mini-fab color="warn" class="floater" (click)="deleteNote(note.id)">
                        <mat-icon>delete</mat-icon>
                      </button>
                      <button *ngIf="!isViewMode" mat-mini-fab color="accent" class="floater" (click)="updateNote(note.id)">
                        <mat-icon>edit</mat-icon>
                      </button>
                    </mat-expansion-panel>
                </mat-accordion>
                <mat-paginator #paginatorNote="matPaginator" [pageSizeOptions]="[5]" aria-label="Seite der Notizen auswählen"></mat-paginator>
            </div>
            <div class="singleButtonContainer" *ngIf="!displayChart && !isViewMode">
                <button mat-raised-button color="primary" (click)="addNote()">Notiz hinzufügen <mat-icon>add</mat-icon></button>
            </div>
        </div>
        <div class="growDiv">
          <div *ngIf="displayChart && groups.length > 1">
            <br>
            <br>
            <app-basic-chart *ngIf="displayChart && groups.length > 2" [data]="totalChartData[groups[2].id]" [canvasId]="'chart3'"></app-basic-chart>
          </div>

            <div *ngIf="!displayChart">
                <h2>Unterbrechungen</h2>
                <mat-accordion>
                    <mat-expansion-panel *ngFor="let interruption of observableInterruptions | async | orderBy: 'timestamp'">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          {{interruption.text | shorten: 8: '...'}}
                        </mat-panel-title>
                        <mat-panel-description>
                          Erstellt um {{interruption.timestamp | date:'HH:mm'}} | Verursacht von {{interruption.byGroupName | shorten: 8: '...'}}
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <p>Verursacht von {{interruption.byGroupName | shorten: 30: '...'}}</p>
                      <p>{{interruption.text | shorten: 50: '...'}}</p>
                    </mat-expansion-panel>
                </mat-accordion>
                <mat-paginator #paginatorInterruption="matPaginator" [pageSizeOptions]="[5]" aria-label="Seite der Unterbrechungen auswählen"></mat-paginator>
            </div>
            <div class="singleButtonContainer" *ngIf="!displayChart && !isViewMode">
                <button mat-raised-button color="primary" (click)="addInterruption()">Unterbrechung hinzufügen <mat-icon>add</mat-icon></button>
            </div>
        </div>
    </div>
</div>
