import {NgModule} from '@angular/core';
import { RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {LoginComponent} from './components/login/login.component';
import { UserCreateUpdateMode, UserdetailviewComponent } from './components/userdetailview/userdetailview.component';
import { OrganisationDetailViewComponent } from './components/organisation-detail-view/organisation-detail-view.component';
import { LogviewComponent } from './components/logview/logview.component';
import {LogDetailViewComponent} from "./components/log-detail-view/log-detail-view.component";
import { DataManagementViewComponent } from './components/data-management-view/data-management-view.component';
import { MeetingViewComponent } from './components/meeting-view/meeting-view.component';
import { ParticipantViewComponent } from './components/participant-view/participant-view.component';
import { LogCompareViewComponent, LogCompareViewMode } from './components/log-compare-view/log-compare-view.component';
import { MeetingPublicViewComponent } from './components/meeting-public-view/meeting-public-view.component';
import {
  LogMultiSessinStatisticComponent
} from "./components/log-multi-sessin-statistic/log-multi-sessin-statistic.component";
import { CrossTypeStatisticsComponent } from './components/charts/cross-type-statistics/cross-type-statistics.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'login', component: LoginComponent},
  {path: 'user', component: UserdetailviewComponent, data: {mode: UserCreateUpdateMode.UPDATE}},
  {path: 'user/create', component: UserdetailviewComponent, data: {mode: UserCreateUpdateMode.CREATE}},
  {path: 'user/:id', component: UserdetailviewComponent, data: {mode: UserCreateUpdateMode.UPDATE}},
  {path: 'organisation', component: OrganisationDetailViewComponent},
  {path: 'data', component: DataManagementViewComponent},
  {path: 'log/compare/:id', component: LogCompareViewComponent, data: {mode: LogCompareViewMode.COMPARE}},
  {path: 'log/cross-compare', component: CrossTypeStatisticsComponent},
  {path: 'log/view/:id', component: LogviewComponent, data: {mode: LogCompareViewMode.SINGLE}},
  {path: 'log/:id', component: LogviewComponent},
  {path: 'meeting', component: MeetingViewComponent},
  {path: 'meeting/:id', component: LogDetailViewComponent},
  {path: 'meetingTemplate/:id', component: LogMultiSessinStatisticComponent},
  {path: 'participants', component: ParticipantViewComponent},
  {path: 'public/meeting/:id', component: MeetingPublicViewComponent},
  {path: 'public/log/:id', component: LogviewComponent, data: {mode: LogCompareViewMode.COMPARE}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
