<div class="wrapper">
    <div class="headingContainer">
        <h1>Sitzungskategorien</h1>
    </div>
    <mat-form-field>
        <mat-label>Sitzungkategorien durchsuchen</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Mit Name suchen" #input>
    </mat-form-field>
    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="datasource" #tableSort="matSort" matSort #table>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let template"> {{template?.name}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> Aktionen </th>
                <td mat-cell *matCellDef="let template">
                    <button mat-mini-fab color="accent" (click)="updateTemplate(template.id)" matTooltip="Bearbeiten">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-mini-fab color="accent" [routerLink]="['../meetingTemplate',template.id]" matTooltip="Statistiken">
                        <mat-icon>bar_chart</mat-icon>
                    </button>
                    <button mat-mini-fab color="warn" (click)="deleteTemplate(template.id, template.name)" matTooltip="Löschen">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">Keine Einträge gefunden</td>
            </tr>
        </table>
        <mat-paginator #paginator="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="select page"></mat-paginator>
    </div>
    <div class="singleButtonContainer">
        <button mat-raised-button color="primary" (click)="addTemplate()">
            neue Vorlage erstellen<mat-icon>add</mat-icon>
        </button>
    </div>
</div>
