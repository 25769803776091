import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LogListDto } from 'src/app/api/model/logListDto';
import { LogEndpointService } from 'src/app/api/api/logEndpoint.service';
import { LogDetailDto } from 'src/app/api/model/logDetailDto';
import { NotificationServiceService } from 'src/app/services/notification-service.service';
import { ThemeServiceService } from 'src/app/services/theme-service.service';
import { CategoryGroupDataDto } from 'src/app/api/model/categoryGroupDataDto';
import { SimpleSubGroupDto } from 'src/app/api/model/simpleSubGroupDto';
import { LoginEndpointService } from 'src/app/api/api/loginEndpoint.service';
import { UserDetailDto } from 'src/app/api/model/userDetailDto';

@Component({
  selector: 'app-log-compare-view',
  templateUrl: './log-compare-view.component.html',
  styleUrls: ['./log-compare-view.component.scss']
})
export class LogCompareViewComponent {

  user: UserDetailDto;
  logs: LogDetailDto[] = [];
  groups: CategoryGroupDataDto[] = [];
  speakingTimes: Object = {};
  allChartData: Object  = {};
  interruptions: Object = {};
  interruptionsChartData: Object = {};
  chartDataName: string = 'Sprechzeit (min)';
  meetingId: string = null;

  @ViewChild('tabGroup') tabGroup;


  constructor(private notificationService: NotificationServiceService, private themeService: ThemeServiceService,
        private logService: LogEndpointService, private route: ActivatedRoute, private loginService: LoginEndpointService) {}

  ngOnInit() {
    this.themeService.changeTheme(this.themeService.getActiveTheme());
    this.meetingId = this.route.snapshot.params['id'];
    this.loginService.authenticated().subscribe({
      next: data => {
        this.user = data;
      },
      error: error => {
        this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
        console.error(error);
      }
    })
    this.logService.getAllByMeeting(this.meetingId).subscribe({
      next: data => {
        let logs: LogListDto[] = data;
        logs = logs.filter(x => x.state !== LogDetailDto.StateEnum.RUNNING);
        this.logs = [];
        logs.forEach(x => {
          this.logService.getById2(x.id).subscribe({
            next: newLog => {
              this.logs.push(newLog);
              if(this.logs.length === logs.length) {
                this.sortLogs();
                this.getGroups();
                this.readSpeakingTimes();
                this.readChartData();
                this.getInterruptionsFromLog();
                this.readInterruptionChartData();
              }
            },
            error: error => {
              this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
              console.error(error);
            }
          });
        });
      },
      error: error => {
        this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
        console.error(error);
      }
    });
  }

  sortLogs() {
    this.logs.sort((a, b) => this.sortFn(a, b));
  }

  private sortFn(a, b) {
    let nameA = a.user.username.toLowerCase();
    let nameB = b.user.username.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  getGroups() {
    if(this.logs.length <= 0) 
      return;
    this.groups = this.logs[0].talkingPoints[0].speakers;
    this.logs.forEach(x => {
      this.speakingTimes[x.id] = {};
    });
    this.groups.forEach(x => {
      x.categories.forEach(y => {
        this.logs.forEach(z => {
          this.speakingTimes[z.id][y.id] = 0;
        });
      });
    });
  }

  readSpeakingTimes() {
    if(this.logs.length <= 0) 
      return;
    this.logs.forEach(log => {
      this.groups.forEach(group => {
        group.categories.forEach(category => {
          let totalInMs = 0;
          log.talkingPoints.forEach(tp => {
            let data = tp.speakers.find(x => x.id === group.id).categories.find(x => x.id === category.id).entry.data;
            data.forEach(times => {
              if(times.end !== null) {
                totalInMs += new Date(times.end).getTime() - new Date(times.start).getTime();
              } else {
                totalInMs += new Date().getTime() - new Date(times.start).getTime();
              }
            });
          });
          this.speakingTimes[log.id][category.id] = totalInMs;
        });
      });
    });
  }

  readChartData() {
    this.logs.forEach(log => {
      this.allChartData[log.id] = {};
      this.groups.forEach(group => {
        this.allChartData[log.id][group.id] = this.getChartData(log.id, group.id);
      });
    });
  }

  readInterruptionChartData() {
    if(this.logs.length <= 0) 
      return;
    let groupNames: string[] = [];
    this.groups.forEach(x => {
      x.categories.forEach(y => groupNames.push(y.name))
    });

    this.interruptionsChartData = {};
    
    this.logs.forEach(log => {
      let data = [];
      this.interruptions[log.id].forEach(x => {
        let at = Math.round((new Date(x.timestamp).getTime() - new Date(log.createdAt).getTime()) / 60000);
        let color = this.generateColorHex(6);
        x.color = color;
        x.byGroups.forEach(subgroup => {
          data.push({x: at, y: subgroup.name, label2: this.shortenString(x.text, 20) + ' (min, Gruppe)', color: color});
        });
      });
      this.interruptionsChartData[log.id] = {data: data, dataName: 'Unterbrechung (min, Gruppe)', yAxisLabels: groupNames};
    });
  }

  generateColorHex(size: number) {
    return '#' + [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
  }

  shortenString(toShorten: string, shortenTo: number): string {
    let rv = toShorten.slice(0, (toShorten.length - shortenTo) * -1);
    if(rv.length < toShorten.length)
      rv += '...';
    return rv;
  }

  getInterruptionsFromLog() {
    if(this.logs.length <= 0) 
      return;
    this.interruptions = {};
    this.logs.forEach(log => {
      let result: InterruptionsPanelData[] = [];

      log.interruptions.forEach(group => {
        group.categories.forEach(category => {
          category.entry.data.forEach(entry => {
            let cousin = result.find(x => {
              let temp = {timestamp: x.timestamp, text: x.text};
              let temp2 = {timestamp: new Date(entry.start), text: entry.value};
              return JSON.stringify(temp) === JSON.stringify(temp2);
            });
            if(cousin) {
              cousin.byGroups.push(category);
            } else {
              let toPush: InterruptionsPanelData = {
                timestamp: new Date(entry.start),
                text: entry.value,
                byGroups: [category],
                color: '#ffffff'
              }
              result.push(toPush);
            }
          });
        });
      });

      this.interruptions[log.id] = result;
    }); 
  }

  ngAfterViewInit() {
  }

  addRemoveLogSelection(log: LogDetailDto) {
    if(log.state === LogDetailDto.StateEnum.FINISHED) {
      log.state = LogDetailDto.StateEnum.IGNORED;
      
    } else {
      log.state = LogDetailDto.StateEnum.FINISHED;
    }
    this.logService.updateLogState({state: log.state}, log.id).subscribe({
      next: () => {
        this.notificationService.open('Status erfolgreich aktualisiert', 'ok', 5);
      },
      error: error => {
        this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
        console.error(error);
      }
    });
  }

  getCategoryNameByid(id: string): string {
    let rv = null;
    this.groups.forEach(group => {
      let found = group.categories.find(category => category.id === id);
      if(found)
        rv = found;
    });
    return rv.name;
  }

  getChartData(logId: string, groupId: string) {
    let log = this.logs.find(x => x.id === logId);
    let group = this.groups.find(x => x.id === groupId);
    let chartData = {};

    Object.keys(this.speakingTimes[log.id]).forEach(x => {
      if(group.categories.find(y => y.id === x))
        chartData[x] = {data: Math.round(this.speakingTimes[log.id][x] / 60000), label: this.getCategoryNameByid(x)};
    });

    let chartOverData = {
      data: chartData,
      dataName: this.chartDataName
    }
    return Object.keys(chartData).length > 0 ? chartOverData : null;
  }

  getToggleState(log: LogDetailDto) {
    return log.state === LogDetailDto.StateEnum.FINISHED;
  }

  tabChanged(event: number) {
    if(event === 0) {
      this.readChartData();
    } else if(event === 1) {
      this.readInterruptionChartData();
    }
  }

  isInterruptionTab() {
    return this.tabGroup?.selectedIndex === 1
  }

  getNamesFromSubgroupArray(subgroups: SimpleSubGroupDto[]): string {
    let rv = '';
    subgroups.forEach(x => {
      rv += x.name + ', '
    });
    if(rv.length > 0)
      rv = rv.slice(0, -2);
    return rv;
  }

  canEditLog(userId: string) {
    return this.user?.id === userId || this.user?.role === UserDetailDto.RoleEnum.ADMIN;
  }
}

export enum LogCompareViewMode {
  SINGLE,
  COMPARE
}

interface InterruptionsPanelData {
  timestamp: Date,
  text: string,
  byGroups: SimpleSubGroupDto[],
  color: string
}