import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MeetingEndpointService } from 'src/app/api/api/meetingEndpoint.service';
import { MeetingTemplateEndpointService } from 'src/app/api/api/meetingTemplateEndpoint.service';
import { ThemeServiceService } from 'src/app/services/theme-service.service';
import { MeetingCreateDialogComponent, MeetingDialogData } from '../meeting-create-dialog.component';
import { MeetingMinimalDto } from 'src/app/api/model/meetingMinimalDto';
import { NotificationServiceService } from 'src/app/services/notification-service.service';

@Component({
  selector: 'app-clone-existing-meeting-dialog',
  templateUrl: './clone-existing-meeting-dialog.component.html',
  styleUrls: ['./clone-existing-meeting-dialog.component.scss']
})
export class CloneExistingMeetingDialogComponent implements OnInit, AfterViewInit{
  @ViewChild('form') form: NgForm;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;

  meetings: MeetingMinimalDto[] = [];
  filteredOptions: MeetingMinimalDto[] = [];
  meeting: MeetingMinimalDto;

  constructor(private dialog: MatDialog, private themeService: ThemeServiceService, private meetingService: MeetingEndpointService,
    public dialogRef: MatDialogRef<CloneExistingMeetingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MeetingDialogData, private notificationService: NotificationServiceService) {

  }

  ngOnInit() {
    this.themeService.changeTheme(this.themeService.getActiveTheme());
    this.meetingService.getAll1().subscribe({
      next: data => {
        this.meetings = data;
        this.filteredOptions = this.meetings.splice(0,0);
      },
      error: error => {
        console.error(error);
        this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
      }
    })
  }

  ngAfterViewInit() {
    this.dialogRef.disableClose = true;
  }

  getDisplayNameOfMeeting(m: MeetingMinimalDto) {
    return m.name;
  }

  filter() {
    const filterValue = this.input.nativeElement.value.toLowerCase();
    this.filteredOptions = this.meetings.filter(x => this.getDisplayNameOfMeeting(x).toLowerCase().includes(filterValue));
  }

  deleteSearch() {
    this.input.nativeElement.value = null;
  }

  getDisplayNameOfMeetingWithId(id: string) {
    if(id)
      return this.getDisplayNameOfMeeting(this.meetings.find(x => x.id === id));
  }

  selectionChanged() {
    let value = this.input.nativeElement.value;
    this.meeting = this.meetings.find(x => x.name === value);
    if(!this.meeting) {
      this.notificationService.open('Bitte gehen Sie sicher, dass Ihre Eingabe korrekt ist');
      return;
    }  
  }

  cancel(){
    this.dialogRef.close();
  }

  openWithoutMeeting(){
    let ref = this.dialog.open(MeetingCreateDialogComponent, {data: {organisationId: this.data.organisationId}});
    ref.afterClosed().subscribe(result => {
      this.dialogRef.close();
    });
  }

  hasValue() {
    return this.input?.nativeElement.value?.length > 0
  }
  

  openWithMeeting() {
    if(this.hasValue() && this.meeting){
    this.meetingService.getById1(this.meeting.id).subscribe(data => {
      let meetingData: MeetingDialogData = {
        meeting: data,
        organisationId: this.data.organisationId,
        templateData: true
      }
      let ref = this.dialog.open(MeetingCreateDialogComponent, {data: meetingData});
      ref.afterClosed().subscribe(result => {
        this.dialogRef.close();
      });
    }); 
   }
  }
}
