import { Component } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent {
  ref: MatSnackBarRef<TextOnlySnackBar>;

  constructor(private snackBar: MatSnackBar) {}

  openSnackBar(message: string, action: string, durationInSeconds: number): MatSnackBarRef<TextOnlySnackBar> {
    this.ref = this.snackBar.open(message, action, {duration: durationInSeconds * 1000});
    return this.ref;
  }
}
