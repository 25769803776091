<div class="wrapper">
    <div class="headingContainer">
        <h1>Sprecher*in wechseln</h1>
        </div>
    <form (ngSubmit)="applyParticipant()">
        <mat-form-field class="upperField">
            <mat-label>Shourtcut</mat-label>
            <input #input
                   type="text"
                   placeholder="Suchen Sie nach Name oder Nummer"
                   matInput
                   name="participantInput"
                   ngModel
                   [matAutocomplete]="auto"
                   (input)="filter()"
                   (focus)="filter()">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="getDisplayNameOfParticipantWithId.bind(this)">
                <mat-option (click)="selectionChanged()" *ngFor="let participant of filteredOptions | orderBy: 'number'" [value]="participant.id">{{getDisplayNameOfParticipant(participant)}}</mat-option>
            </mat-autocomplete>
            <button *ngIf="hasValue()" (click)="deleteSearch()" matSuffix mat-icon-button aria-label="delete text in participant input field" type="button">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </form>
    <hr>
    <form #form="ngForm" (ngSubmit)="save()" name="form" class="lowerForm">
        <mat-form-field *ngFor="let group of data?.groups | orderBy: 'name'">
            <mat-label>{{group.name}}</mat-label>
            <mat-select name="{{group.id}}" ngModel>
                <mat-option *ngFor="let category of group.categories" [value]="category.id">{{category.name}}</mat-option>
                <mat-option selected></mat-option>
            </mat-select>
        </mat-form-field>

        <div class="buttonContainer">
            <button (click)="cancel()" mat-flat-button color="accent" type="button">
                Abbrechen
            </button>
            <button mat-flat-button color="primary" type="submit">
               Speichern
            </button>
        </div>

    </form>
</div>
