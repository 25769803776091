import { Component } from '@angular/core';
import { NotificationServiceService } from 'src/app/services/notification-service.service';
import { LoginEndpointService } from 'src/app/api/api/loginEndpoint.service';
import { MatDialog } from '@angular/material/dialog';
import { ThemeServiceService } from 'src/app/services/theme-service.service';
import { GroupCreateUpdateDialogComponent } from './groups/group-create-update-dialog/group-create-update-dialog.component';

@Component({
  selector: 'app-data-management-view',
  templateUrl: './data-management-view.component.html',
  styleUrls: ['./data-management-view.component.scss']
})
export class DataManagementViewComponent {
  constructor(private notificationService: NotificationServiceService, private loginService: LoginEndpointService, 
    private dialog: MatDialog, private themeService: ThemeServiceService) {}

  reload() {
    this.loginService.authenticated().subscribe({
      next: data => {
        this.themeService.changeTheme(this.themeService.getActiveTheme())
      },
      error: error => {
        this.notificationService.open('Etwas ist schiefgegangen', 'ok', 15);
        console.error(error);
      }
    });
  }

  createNewOfType() {
    let ref = this.dialog.open(GroupCreateUpdateDialogComponent);

    ref.afterClosed().subscribe(result => {
      if(result)
        this.reload();
    });
  }
}
