import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationServiceService } from 'src/app/services/notification-service.service';
import { ThemeServiceService } from 'src/app/services/theme-service.service';
import { MatChipInput, MatChipInputEvent } from '@angular/material/chips';
import { MatTable } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MeetingDto } from 'src/app/api/model/meetingDto';
import { TalkingPointsInMeetingDto } from 'src/app/api/model/talkingPointsInMeetingDto';
import { MeetingEndpointService } from 'src/app/api/api/meetingEndpoint.service';
import { MeetingTemplateEndpointService } from 'src/app/api/api/meetingTemplateEndpoint.service';
import { MeetingTemplateMinimalDto } from 'src/app/api/model/meetingTemplateMinimalDto';
import { Observable } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatPaginator } from '@angular/material/paginator';
import { MeetingGroupGroup } from 'src/app/api/model/meetingGroupGroup';
import { MeetingGroupMapEntry } from 'src/app/api/model/meetingGroupMapEntry';
import { GroupEndpointService } from 'src/app/api/api/groupEndpoint.service';
import { ConfirmDialogComponent, ConfirmDialogData } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-meeting-create-dialog',
  templateUrl: './meeting-create-dialog.component.html',
  styleUrls: ['./meeting-create-dialog.component.scss']
})
export class MeetingCreateDialogComponent {
  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  length: number = 3;
  pageSize: number = 1;
  pageIndex: number = 0;
  isLastPage: boolean = false;
  formData = [];

  @ViewChild('form') form: NgForm;
  @ViewChild('table', { static: true }) table: MatTable<TalkingPointsInMeetingDto>;
  @ViewChild('categoryTable', { static: true }) categoryTable: MatTable<TalkingPointsInMeetingDto>;
  @ViewChild('groupInput') groupInput: ElementRef<HTMLInputElement>;

  groupsAll: MeetingGroupGroup[] = [];
  groupsSelected: MeetingGroupGroup[] = [];
  groupsSelectedForTable: GroupCategoryTable[] = [];
  selectedCategories: MeetingGroupMapEntry[] = [];
  countOfCategory: number = 1;

  displayedColumns: string[] = ['position', 'name', 'action'];
  dragDisabled = true;
  talkingpoints: TalkingPointsInMeetingDto[] = [];
  groups: MeetingGroupGroup[] = [];
  mGroup: MeetingGroupGroup;
  mGroupCategory: MeetingGroupMapEntry;
  displayedColumnsCategories: string[] = ['gruppe', 'kategorie', 'anzahl', 'action'];

  meetingTemplates: MeetingTemplateMinimalDto[] = [];
  mTemp: string = "";
  meetingTemplate: string;

  date: Date;
  today = new Date();

  isUpdate: boolean = false;

  constructor(private dialog: MatDialog, public dialogRef: MatDialogRef<MeetingCreateDialogComponent>,
    private notificationService: NotificationServiceService,
    private authService: AuthService, private router: Router, private meetingEndpointService: MeetingEndpointService,
    private themeService: ThemeServiceService, private meetingTemplateEndpointService: MeetingTemplateEndpointService,
    @Inject(MAT_DIALOG_DATA) public data: MeetingDialogData, private groupEndpointService: GroupEndpointService) {

  }

  ngOnInit() {
    this.isUpdate = this.data.meeting ? (!(this.data.templateData) ? true : false) : false;

    this.themeService.changeTheme(this.themeService.getActiveTheme());
    this.meetingTemplateEndpointService.getAllMeetingTemplatesOfOrganisation().subscribe(data => {
      this.meetingTemplates = data
    });
    this.groupEndpointService.getAll3().subscribe(data => {
      let cats: MeetingGroupMapEntry[] = [];
      data.forEach(x => {
        cats = [];
        x.categories.forEach(c => {
          cats.push({
            category: c,
            value: 0
          });
        });
        this.groups.push({
          group: x,
          categories: cats
        });
        this.groupsAll.push({
          group:x,
          categories: cats
        });
      });
    });

    this.formData.push(this.createFormData("page1", 1));
    this.formData.push(this.createFormData("page2", 2));
    this.formData.push(this.createFormData("page3", 3));
    this.setPageFormFieldPaging(this.pageIndex, this.pageSize);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if(this.isUpdate || this.data.templateData) {
        this.applyData();
      }
    }, 0);
    this.dialogRef.disableClose = true;
  }

  applyData() {
    this.form.controls['name'].setValue(this.data.meeting.name);
    this.form.controls['picker'].setValue(this.data.meeting.date);
    this.form.controls['public'].setValue(this.data.meeting["public"]);
    if(this.data.templateData){
      this.data.meeting.talkingPoints.forEach(x => {
        let newTalkingpoint: TalkingPointsInMeetingDto = {
          name: x.name
        }
        this.talkingpoints.push(newTalkingpoint);
      });
    }else{
      this.talkingpoints = this.data.meeting.talkingPoints;
    }
    this.mTemp = this.data.meeting.template?.id;
    this.groupsSelected = this.data.meeting.groups;

    this.mapSelectedGroupsToTable();
    
    this.table.renderRows();
  }


  getPosition(tp) {
    return this.talkingpoints.findIndex(x => x == tp) + 1;
  }

  add(event: MatChipInputEvent): void {
    if(event.value != ""){
      if (!this.form.controls["talkingpoint"].valid) {
        this.notificationService.open("Tagesordnungspunkt überprüfen!", "ok", 5);
        return;
      }
      let newTalkingpoint: TalkingPointsInMeetingDto = {
        name: event.value
      }
      this.talkingpoints.push(newTalkingpoint);
      this.form.controls['talkingpoint'].setValue("");
      this.table.renderRows();
    }
  }

  addGroupCategory(): void {
    if(this.countOfCategory<=0){
      this.notificationService.open("Die Anzahl für Kategorien sollte größer Null sein");
    }else{
      if(this.mGroup && this.mGroup != undefined && this.mGroup != null && this.mGroupCategory && this.mGroupCategory != undefined && this.mGroupCategory != null) {
        this.addIfNoDuplicate(this.mGroup, this.mGroupCategory, this.countOfCategory);
        this.categoryTable.renderRows();
        
      }else{
        this.notificationService.open("Selektiere zuerst Gruppe UND Kategorie!", "ok", 5);
      }
    }
  }

  addIfNoDuplicate(group, category, count) {
    if (this.groupsSelected.find(x => x.group.id == group.group.id)){
      if(this.groupsSelected.find(x => x.group.id == group.group.id).categories.find(c => c.category.id == category.id)){
        this.groupsSelected.find(x => x.group.id == group.group.id).categories.find(c => c.category.id == category.id).value = count;
      }else{
        this.groupsSelected.find(x => x.group.id == group.group.id).categories.push({
          category: category,
          value: count
        });
      }
    }else{
      let catsToSend = [];
      group.categories.forEach(element => {
        if(element.category == category){
          catsToSend.push({
            category: element.category,
            value: count
          })
        }else{
          catsToSend.push({
            category: element.category,
            value: 1
          })
        }
      });

      this.groupsSelected.push({
        group: group.group,
        categories: catsToSend
      })
    }
    this.mapSelectedGroupsToTable();
  }
  mapSelectedGroupsToTable(){
    this.groupsSelectedForTable = [];
    this.groupsSelected.forEach(x => {
      x.categories.forEach(c => {
        this.groupsSelectedForTable.push({
          groupName: x.group.name,
          categoryName: c.category.name,
          amount: c.value
        })
      })
    });
  }

  drop(event: CdkDragDrop<TalkingPointsInMeetingDto[]>) {
    this.dragDisabled = true;
    const previousIndex = this.talkingpoints.findIndex((d) => d === event.item.data);
    moveItemInArray(this.talkingpoints, previousIndex, event.currentIndex);
    this.table.renderRows();
  }

  remove(element: TalkingPointsInMeetingDto): void {
    const index = this.talkingpoints.indexOf(element);
    if (index >= 0) {
      this.talkingpoints.splice(index, 1);
    }
    this.table.renderRows();
  }

  removeCategory(element: GroupCategoryTable): void {
    const index = this.groupsSelectedForTable.indexOf(element);
    if (index >= 0) {
      this.groupsSelectedForTable.splice(index, 1);
      let idxG = this.groupsSelected.findIndex(x => x.group.name == element.groupName);
      let idxC = this.groupsSelected[idxG].categories.findIndex(c => c.category.name == element.categoryName);
      this.groupsSelected[idxG].categories.splice(idxC, 1);
      if(this.groupsSelected[idxG].categories.length == 0){
        this.groupsSelected.splice(idxG, 1);
      }
    }
    this.categoryTable.renderRows();
  }

  cancel() {
    let dialogData: ConfirmDialogData = {
      message: 'Wollen Sie das ' + (this.isUpdate ? 'Bearbeiten ' : 'Erstellen') + ' wirklich abbrechen?',
      denyButtonText: 'Nein',
      acceptButtonText: 'Ja'
    }
    let ref = this.dialog.open(ConfirmDialogComponent, {data: dialogData});
    ref.afterClosed().subscribe(result => {
      if (result === true) {
        this.dialogRef.close(false);
      }
    });
  
  }

  save() {
    let fillErrors = "Eintragungsfehler: Es wurde ";
    let fillErrorCount = 0;
    let pageidx = -1;
    if(this.form.controls["name"].value==""){
      fillErrors+="kein Name auf Seite 1 ";
      fillErrorCount++;
    }
    if(this.form.controls["picker"].value==""){
      fillErrors+="kein Datum auf Seite 1 ";
      fillErrorCount++;
    }
    if(this.mTemp==""){
      fillErrors+="keine Vorlage auf Seite 1 ";
      fillErrorCount++;
    }
    if(fillErrorCount>0){
      fillErrors += "ausgewählt.\n"
      pageidx=0;
    }
    if(this.groupsSelected.length <= 0){
      fillErrors += "Es wurden keine Gruppen auf Seite 2 selektiert.\n"
      fillErrorCount++;
      if(pageidx == -1) {
        pageidx=1;
      }
    }
    if(this.talkingpoints.length <= 0){
      fillErrors += "Es wurden keine Sitzungspunkte auf Seite 3 definiert.\n"
      fillErrorCount++;
      if(pageidx==-1){
        pageidx=2;        
      }
    }
    if(fillErrorCount>0){
      this.notificationService.open(fillErrors, "ok", 15);
      this.pageIndex=pageidx;
      if(this.pageIndex==0){
        this.paginator.firstPage();
      }else if(this.pageIndex == 1){
        this.paginator.previousPage();
      }
      return;
    }
    if (!this.form.valid || this.talkingpoints.length <= 0 || this.mTemp == undefined || this.groupsSelected.length <= 0) {
      this.notificationService.open('Die Eingaben entsprechen nicht den Vorgaben', 'ok', 15);
      return;
    }

    this.talkingpoints.forEach((tp, idx) => {
      tp.position = idx + 1;
    });


    let body: MeetingDto = {
      name: this.form.controls['name'].value,
      date: this.form.controls['picker'].value,
      talkingPoints: this.talkingpoints,
      template: this.meetingTemplates.find(x => x.id == this.mTemp),
      groups: this.groupsSelected,
      _public: this.form.controls['public'].value
    }
    body['public'] = body._public;

    if(this.isUpdate) {
      body.id = this.data.meeting.id;
      this.meetingEndpointService.updateMeeting(body, this.data.meeting.id).subscribe({
        next: () => {
          this.notificationService.open(  body.name + ' erfolgreich bearbeitet', 'ok', 5);
          this.dialogRef.close(true);
        },
        error: error => {
          console.error(error);
          this.notificationService.open('Ein unerwarteter Fehler ist beim Bearbeiten von ' + body.name +' aufgetreten', 'ok', 15);
        }
      });
    } else {
      this.meetingEndpointService.createMeeting(body).subscribe({
        next: () => {
          this.notificationService.open(body.name + " wurde erfolgreich erstellt", 'ok', 5);
          this.dialogRef.close(true);
        },
        error: error => {
          console.error(error);
          this.notificationService.open('Ein unerwarteter Fehler ist beim Erstellen aufgetreten', 'ok', 15);
        }
      });
    }
  }

  getHeading() {
    return this.isUpdate ? 'Bearbeite ' + this.data.meeting.name : 'Neue Sitzung erstellen';
  }

  createFormData(prop: string, pageNumber: number) {
    return { 
      name: prop, 
      page: pageNumber,  
      display: true 
    };
  }

  setPageFormFieldPaging(pageIndex: number, pageSize: number) {
    let lowerBound: number = ((pageIndex + 1) * pageSize) - 
                               pageSize;
     
    let upperBound: number = ((pageIndex + 1) * pageSize) - 1;
    this.formData.forEach((item, index) => {
    item.display = true;
    if (index >= lowerBound && index <= upperBound) {
         item.display = false;
       }
     });
  }

  pageEvent($event) {
    this.pageSize = $event.pageSize;
    this.pageIndex = $event.pageIndex;
    this.setPageFormFieldPaging(this.pageIndex, this.pageSize);
    this.isLastPage = !this.paginator.hasNextPage();
  }
}

export interface MeetingDialogData {
  meeting?: MeetingDto,
  organisationId: string,
  templateData: boolean
}
export interface GroupCategoryTable {
  groupName: string,
  categoryName: string,
  amount: number
}
