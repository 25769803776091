<mat-form-field>
    <mat-label>Suche Benutzer</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Suche (nach Name, Rolle...)" #input>
  </mat-form-field>

  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort #table>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let user"> {{nameDisplay(user)}} </td>
          </ng-container>

          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Rolle </th>
            <td mat-cell *matCellDef="let user"> {{user.role}} </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Bearbeiten</th>
            <td mat-cell *matCellDef="let User">
              <button *ngIf="User.role === 'USER'" routerLink="{{'/user/' + User.id}}" mat-mini-fab color="accent">
                <mat-icon>account_circle</mat-icon>
              </button>
            </td>
          </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">Keine Daten für "{{input.value}}" gefunden</td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Seite der Benutzer auswählen"></mat-paginator>
  </div>
