<div class="wrapper">
<div *ngIf="error" class="alert alert-warning alert-dismissible fade show" role="alert">
  <strong>Anmeldeprobleme!</strong> {{ errorMessage }}
  <button class="alertButtons" (click)="vanishError()" mat-mini-fab color="accent" data-dismiss="alert">
    <mat-icon>highlight_off</mat-icon>
  </button>
  <button *ngIf="checkIfLoggedIn()" class="alertButtons" (click)="logout(); vanishError()" mat-mini-fab color="accent" data-dismiss="alert">
    <mat-icon>power_settings_new</mat-icon>
  </button>
</div>

<div class="pt-5" *ngIf="checkIfLoggedIn()">
  <span class="aliMessage">Sie sind schon angemeldet</span>
</div>

<div class="pt-5" *ngIf="!checkIfLoggedIn()">
  <div class="row">
    <div class="col-md-6 mx-auto">
      <span class="anchor" id="formLogin"></span>
      <div class="card rounded-0">
        <span id="loginform"></span>
        <div class="card-header">
          <h3 class="mb-0">Anmelden</h3>
        </div>

        <div class="card-body">
          <form class="form" [formGroup]="loginForm" (ngSubmit)="loginUser()">

            <div class="form-group">
              <label for="inputUsername">Benutzername</label>
              <input type="text" name="username" formControlName="username" class="form-control" id="inputUsername"
                     aria-describedby="usernameHelp" placeholder="Benutzername">
              <div *ngIf="submitted && loginForm.controls.username.errors" class="error">
                <small class="form-text text-warning" *ngIf="loginForm.controls.username.errors.required">Benutzername benötigt!
                </small>
              </div>
            </div>

            <div class="form-group">
              <label for="password">Passwort</label>
              <input type="password" name="password" formControlName="password" class="form-control" id="password"
                     placeholder="Password">
              <div *ngIf="submitted && loginForm.controls.password.errors" class="error">
                <small class="form-text text-warning" *ngIf="loginForm.controls.password.errors.required">Passwort benötigt!
                </small>
                <small class="form-text text-warning" *ngIf="loginForm.controls.password.errors.minlength">Das Passwort muss mindestens 8 Zeichen lang sein!
                </small>
              </div>
            </div>

            <button mat-raised-button color="accent" class="loginButton" type="submit">Login</button>

          </form>
        </div>

      </div>

    </div>
  </div>
</div>
</div>