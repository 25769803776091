/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CategoryGroupDataDto } from './categoryGroupDataDto';
import { MeetingMinimalDto } from './meetingMinimalDto';
import { NoteDetailDto } from './noteDetailDto';
import { TalkingPointDataDto } from './talkingPointDataDto';
import { UserMinimalDto } from './userMinimalDto';

export interface LogDetailDto { 
    id?: string;
    state?: LogDetailDto.StateEnum;
    createdAt?: Date;
    user?: UserMinimalDto;
    meeting?: MeetingMinimalDto;
    interruptions?: Array<CategoryGroupDataDto>;
    talkingPoints?: Array<TalkingPointDataDto>;
    notes?: Array<NoteDetailDto>;
}
export namespace LogDetailDto {
    export type StateEnum = 'RUNNING' | 'FINISHED' | 'IGNORED';
    export const StateEnum = {
        RUNNING: 'RUNNING' as StateEnum,
        FINISHED: 'FINISHED' as StateEnum,
        IGNORED: 'IGNORED' as StateEnum
    };
}