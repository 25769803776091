import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { AbstractControl, NgForm, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CreateAndUpdateUserDto } from 'src/app/api/model/createAndUpdateUserDto';
import { UserEndpointService } from 'src/app/api/api/userEndpoint.service';
import { UserDetailDto } from 'src/app/api/model/userDetailDto';
import { NotificationServiceService } from 'src/app/services/notification-service.service';
import { ValidatorService } from 'src/app/services/validator.service';

@Component({
  selector: 'app-password-dialog',
  templateUrl: './password-dialog.component.html',
  styleUrls: ['./password-dialog.component.scss']
})
export class PasswordDialogComponent {

  @ViewChild('form') form: NgForm;
  @ViewChild('pw1') pw1Input: ElementRef;
  @ViewChild('pw2') pw2Input: ElementRef;

  visibilityIcon: string = 'visibility';
  
  constructor(public dialogRef: MatDialogRef<PasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserDetailDto, private notificationService: NotificationServiceService,
    private userService: UserEndpointService, private validatorService: ValidatorService){
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.form.controls['pw1'].addValidators([this.pwCompareValidator(), this.validatorService.pwStrengthValidator()]);
      this.form.controls['pw2'].addValidators([this.pwCompareValidator(), this.validatorService.pwStrengthValidator()]);
    }, 0);
  }

  save() {
    if(!this.form.valid) {
      this.notificationService.open('Bitte stellen Sie sicher, dass das Passwort allen Anforderungen entspricht', 'ok', 15);
      return;
    }

    let body: CreateAndUpdateUserDto = {
      id: this.data.id,
      password: this.form.controls['pw2'].value,
      username: this.data.username,
      role: this.data.role
    };
    this.userService.updateUser(body, this.data.id).subscribe({
      next: () => {
        this.dialogRef.close(true);
        this.notificationService.open('Passwort geändert', 'ok', 5);
      },
      error: error => {
        console.error(error);
        this.notificationService.open('Passwort ändern ist fehlgeschlagen', 'ok', 15);
        this.dialogRef.close(false);
      }
    })
  }

  cancel() {
    this.dialogRef.close(null);
  }

  switchPwVisibility() {
    if(this.visibilityIcon === 'visibility') {
      this.visibilityIcon = 'visibility_off';
      this.pw1Input.nativeElement.type = 'text';
      this.pw2Input.nativeElement.type = 'text';
    } else {
      this.visibilityIcon = 'visibility';
      this.pw1Input.nativeElement.type = 'password';
      this.pw2Input.nativeElement.type = 'password';
    }
    
  }


  pwCompareValidator(): ValidatorFn {
    return () : ValidationErrors | null => {
      let rv = {pwNotTheSame:true};
      if(this.form.controls['pw1'].value === this.form.controls['pw2'].value) {
        rv = null;
        this.removePwNotTheSameErrorFromFormControl('pw1');
        this.removePwNotTheSameErrorFromFormControl('pw2');
      }
      return rv;
    }
  }

  removePwNotTheSameErrorFromFormControl(controlName: string) {
    let control = this.form.controls[controlName];
    let errors = {};
    if(control.errors === null)
      return;
    Object.keys(control.errors).forEach(x => {
      if(x !== 'pwNotTheSame') {
        errors[x] = control.getError(x);
      }
    });
    if(Object.keys(errors).length === 0)
      errors = null;
    control.setErrors(errors)
  }
}
