import { Injectable } from '@angular/core';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';
import { MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationServiceService {

  constructor(private snackbar: SnackbarComponent) { }

  ref: MatSnackBarRef<TextOnlySnackBar>;

  /**
   * Opens the global notification with the given parameters
   * 
   * @param message the message to be displayed on the snackbar. Default: 'default (change this text!)'
   * @param action the text displayed on the close button of the snackbar. Default: 'ok'
   * @param durationInSeconds the duration for which the snackbar should be shown in seconds. Default: 5
   */
  public open(message: string = 'default (change this text!)', action: string = 'ok', durationInSeconds: number = 5) {
    this.close();
    this.ref = this.snackbar.openSnackBar(message, action, durationInSeconds);
  }

  public close() {
    if(this.ref !== undefined && this.ref !== null)
      this.ref.dismiss();
  }
}
