<div class="wrapper">
    <div class="headingContainer">
        <h1>Protokolle aller Sitzungen vergleichen</h1>
        </div>
        <div class="chartWrapper">
            <app-speaker-time-chart style="width: 33%;" [data]="speakertimechartdata" [canvasId]="'speakertimechart piechart 1'" [title]="'Sprechzeit über alle Meetings'"></app-speaker-time-chart>
            <app-speaker-time-chart style="width: 33%;" [data]="groupdistributiondata" [canvasId]="'groupdistributiondata barchart 1'" [title]="'Gruppenvorkommen in Meetings'" [additionalInfo]="adInfoGroupDist"></app-speaker-time-chart>
            <app-cross-interruption-chart style="width: 33%;" [data1]="data1CrossInterruption" [data2]="data2CrossInterruption" [canvasId]="'mixedchart'" [title]="'Verteilung zwischen Unterbrechungen und Anzahl an Sprechern*innen'"></app-cross-interruption-chart>
        </div>
    </div>
