<div class="wrapper">
    <h2>{{getHeader()}}</h2>
    <form #form="ngForm" (ngSubmit)="save()" name="form">
        <mat-form-field>
            <mat-label>Text</mat-label>
            <input matInput required minlength="2" maxlength="50" type="text" #text name="text" ngModel>
            <mat-error *ngIf="form && form.controls['text'] && form.controls['text'].hasError('required')">
                Text <strong>benötigt</strong>
            </mat-error>
            <mat-error *ngIf="form && form.controls['text'] && !form.controls['text'].hasError('required')
            && (form.controls['text'].hasError('minlength') || form.controls['text'].hasError('maxlength'))">
                Bitte geben Sie zwischen 2 und 50 Zeichen ein
            </mat-error>
        </mat-form-field>
    
        <div class="buttonContainer">
            <button (click)="cancel()" mat-flat-button color="accent" type="button">
               Abbrechen
            </button>
            <button mat-flat-button color="primary" type="submit">
               {{data ? 'Aktualisieren' : 'Hinzufügen'}}
            </button>
        </div>
    </form>    
</div>
