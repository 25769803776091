import { Component, ViewChild } from '@angular/core';
import { NotificationServiceService } from 'src/app/services/notification-service.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent, ConfirmDialogData } from '../confirm-dialog/confirm-dialog.component';
import { ThemeServiceService } from 'src/app/services/theme-service.service';
import { CategoryEndpointService } from 'src/app/api/api/categoryEndpoint.service';
import { LoginEndpointService } from 'src/app/api/api/loginEndpoint.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent {
  organisationId: string = '';

  displayedColumns: string[] = ['name', 'actions'];

  groupDataSource = new MatTableDataSource();
  metadataDataSource = new MatTableDataSource();
  interruptionDataSource = new MatTableDataSource();

  groups: TableCategory[] = [];
  metaData: TableCategory[] = [];
  interruptions: TableCategory[] = [];


  @ViewChild('paginatorGroup') groupPaginator: MatPaginator;
  @ViewChild('paginatorMetadata') metadataPaginator: MatPaginator;
  @ViewChild('paginatorInterruption') interruptionPaginator: MatPaginator;


  @ViewChild('groupTableSort') groupTableSort: MatSort;
  @ViewChild('metadataTableSort') metadataTableSort: MatSort;
  @ViewChild('interruptionTableSort') interruptionTableSort: MatSort;

  @ViewChild(MatTable) groupTable: MatTable<TableCategory>;
  @ViewChild(MatTable) metadataTable: MatTable<TableCategory>;
  @ViewChild(MatTable) interruptionTable: MatTable<TableCategory>;


  constructor(private notificationService: NotificationServiceService, private loginService: LoginEndpointService, 
    private categoryService: CategoryEndpointService, private dialog: MatDialog, private themeService: ThemeServiceService) {}

  reload() {
    this.loginService.authenticated().subscribe({
      next: data => {
        this.organisationId = data.organisation.id;
        this.categoryService.filterCategories({organisation: data.organisation}).subscribe({
          next: categories => {
            console.log(categories)
          },
          error: error => {
            this.notificationService.open('Etwas ist schiefgegangen', 'ok', 15);
            console.error(error);
          }
        });
      },
      error: error => {
        this.notificationService.open('Etwas ist schiefgegangen', 'ok', 15);
        console.error(error);
      }
    });
  }

  ngOnInit() {
    this.themeService.changeTheme(this.themeService.getActiveTheme());
    this.reload();
  }

  ngAfterViewInit() {
    this.groupDataSource.paginator = this.groupPaginator;
    this.groupDataSource.sort = this.groupTableSort;

    this.metadataDataSource.paginator = this.metadataPaginator;
    this.metadataDataSource.sort = this.metadataTableSort;

    this.interruptionDataSource.paginator = this.interruptionPaginator;
    this.interruptionDataSource.sort = this.interruptionTableSort;
  }

  applyFilter(event: Event, categoryType: CategoryType) {
    if(categoryType === CategoryType.GROUP) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.groupDataSource.filter = filterValue.trim().toLowerCase();

      if (this.groupDataSource.paginator) {
        this.groupDataSource.paginator.firstPage();
      }
    }

    if(categoryType === CategoryType.METADATA) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.metadataDataSource.filter = filterValue.trim().toLowerCase();

      if (this.metadataDataSource.paginator) {
        this.metadataDataSource.paginator.firstPage();
      }
    }


    if(categoryType === CategoryType.INTERRUPTION) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.interruptionDataSource.filter = filterValue.trim().toLowerCase();

      if (this.interruptionDataSource.paginator) {
        this.interruptionDataSource.paginator.firstPage();
      }
    }

  }


  deleteCategory(id: string, name: string) {
    let dialogData: ConfirmDialogData = {
      message: 'Wollen Sie ' + name + ' dauerhaft löschen?',
      denyButtonText: 'Nein',
      acceptButtonText: 'Ja'
    }
    let ref = this.dialog.open(ConfirmDialogComponent, {data: dialogData});

    ref.afterClosed().subscribe(result => {
      if(result === true) {
        this.categoryService.deleteCategories(id).subscribe({
          next: () => {
            this.notificationService.open(name + ' wurde gelöscht', 'ok', 5);
            this.reload();
          },
          error: error => {
            console.error(error);
            this.notificationService.open('Beim Löschen von ' + name +' ist etwas schiefgegangen', 'ok', 15);
          }
        });
      }
    });
  }
}

interface TableCategory {
  id: string,
  name: string
}

export enum CategoryType {
  GROUP,
  METADATA,
  INTERRUPTION
}
