import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import { ThemeServiceService } from 'src/app/services/theme-service.service';
import {NewLogoService} from "../../services/new-logo.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{

  constructor(public authService: AuthService, private newLogo: NewLogoService, private themeService: ThemeServiceService) {
  }
  ngOnInit(){
        this.themeService.changeTheme(this.themeService.getActiveTheme());
    this.newLogo.notifyAboutChange();
  }
}
