import {Component, Input, SimpleChanges} from '@angular/core';
import {Chart, ChartData} from "chart.js";

@Component({
  selector: 'app-interruption-bar-char',
  templateUrl: './interruption-bar-char.component.html',
  styleUrls: ['./interruption-bar-char.component.scss']
})
export class InterruptionBarCharComponent {

  @Input({required: true})
  data: InterruptionChartData = null;

  @Input({required: false})
  canvasId: string = 'chart';

  chartData: ChartData = {
    datasets: []
  };

  chart: Chart = null;

  reload() {
    if (this.chart !== null) {
      this.chart.destroy();
    }
    if (!this.data) {
      console.error("no data",this.canvasId)
      return;
    }
    this.chartData['labels'] = Object.keys(this.data.data).map(x => this.data.data[x].label);
    var interuptionCount= Object.keys(this.data.data).map(x => this.data.data[x].data);
    console.log("this.charData",this.chartData)
    if (this.canvasId !== 'chart') {
      //setting timout so that in the event of a reload, all canvases are destroyed before the first one is recreated
      setTimeout(() => {

        this.chartData['datasets'] = [{
          label: this.data.dataName,
          data: interuptionCount,
        }];

        this.chart = new Chart(this.canvasId, {
          type: "bar",
          data: this.chartData
        })

      }, 0);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes)
    this.reload();
  }

}

export interface InterruptionChartData {
  data: Object,
  dataName: string,
  yAxisLabels: string[]
}
