import { Component, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserDetailDto } from 'src/app/api/model/userDetailDto';
import { NotificationServiceService } from 'src/app/services/notification-service.service';
import { LogDetailDto } from 'src/app/api/model/logDetailDto';
import { TalkingPointDataDto } from 'src/app/api/model/talkingPointDataDto';
import { MatStepper } from '@angular/material/stepper';
import { UpdateTalkingPointTimesDto } from 'src/app/api/model/updateTalkingPointTimesDto';
import { LogTalkingPointUpdateDto } from 'src/app/api/model/logTalkingPointUpdateDto';
import { MatDialog } from '@angular/material/dialog';
import { InterruptionAddComponent, InterruptionAddData } from '../interruption-add/interruption-add.component';
import { LogCategoryUpdateDto } from 'src/app/api/model/logCategoryUpdateDto';
import { CategoryDto } from 'src/app/api/model/categoryDto';
import { NoteAddDialogComponent } from '../note-add-dialog/note-add-dialog.component';
import { Observable, Subscription, firstValueFrom, interval, map } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent, ConfirmDialogData } from '../confirm-dialog/confirm-dialog.component';
import { ThemeServiceService } from 'src/app/services/theme-service.service';
import { NoteDetailDto } from 'src/app/api/model/noteDetailDto';
import { LoginEndpointService } from 'src/app/api/api/loginEndpoint.service';
import { NoteEndpointService } from 'src/app/api/api/noteEndpoint.service';
import { LogEndpointService } from 'src/app/api/api/logEndpoint.service';
import { CategoryGroupDataDto } from 'src/app/api/model/categoryGroupDataDto';
import { SpeakerSwitchDialogComponent } from '../speaker-switch-dialog/speaker-switch-dialog.component';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { LogCompareViewMode } from '../log-compare-view/log-compare-view.component';
import { PublicEndpointService } from 'src/app/api/api/publicEndpoint.service';

@Component({
  selector: 'app-logview',
  templateUrl: './logview.component.html',
  styleUrls: ['./logview.component.scss']
})
export class LogviewComponent {

  canEdit: boolean = false;
  user: UserDetailDto = null;
  id: string = null;

  groups: CategoryGroupDataDto[] = [];
  currentSpeakerOfGroup: Object = {};
  categories: Object = {};
  time: number = 0;
  totalPauseTime: number = 0;

  speakingTimes: Object = {};
  displayChart: boolean = false;

  totalChartData: Object = {};
  chartDataName: string = 'Sprechzeit (min)';



  dataInterval: Subscription;
  chartInterval: Subscription;
  fetchInterval: Subscription;

  isViewMode: boolean = true;
  isPublic: boolean = false;


  log: LogDetailDto = null;
  logETag: LogDetailDto = null;
  @ViewChild('stepper') stepper: MatStepper;

  currentSpeakers: string[] = [];
  previousSpeaker: string[] = [];
  currentTalkingPoint: TalkingPointDataDto = null;
  interruptions: CategoryDto[] = [];

  private _transformer = (node: CategoryGroupDataDto, level: number) => {
    return {
      expandable: node.categories !== undefined,
      name: node.name,
      id: node.id,
      level: level,
    };
  };

  treeControl = new FlatTreeControl<GroupTreeNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.categories,
  );

  treeDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


  interruptionsYData: InterruptionsPanelData[] = [];
  @ViewChild('paginatorNote') notePaginator: MatPaginator;
  @ViewChild('paginatorInterruption') interruptionPaginator: MatPaginator;
  datasourceNote = new MatTableDataSource();
  datasourceInterruption = new MatTableDataSource();
  observableNotes: Observable<any>;
  observableInterruptions: Observable<any>;


  constructor(private route: ActivatedRoute, private loginService: LoginEndpointService,
    private logService: LogEndpointService, private notificationService: NotificationServiceService,
    private renderer: Renderer2, private dialog: MatDialog, private noteService: NoteEndpointService,
    private router: Router, private publicService: PublicEndpointService, private themeService: ThemeServiceService) { }


  getTalkinpointWithOrder(order: number) {
    return this.log.talkingPoints.filter(x => x.order === order)[0];
  }

  getCurrentTimeRunning(): number {
    let total: number = null;
    let startpoint: Date = new Date(this.getTalkinpointWithOrder(1).times.start);
    if (this.log.state === LogDetailDto.StateEnum.RUNNING) {
      total = new Date().getTime() - startpoint.getTime();
    } else if (this.log.state === LogDetailDto.StateEnum.FINISHED) {
      total = new Date(this.getTalkinpointWithOrder(this.log.talkingPoints.length).times.end).getTime() - startpoint.getTime();
    }
    return total;
  }

  leaveSite() {
    this.router.navigateByUrl('/');
  }

  async reload() {
    this.id = this.route.snapshot.params['id'];
    let state = await firstValueFrom(this.route.paramMap.pipe(map(() => window.history.state)));
    this.isViewMode = state['mode'] === LogCompareViewMode.SINGLE || state['mode'] === LogCompareViewMode.COMPARE;
    this.isPublic = state['mode'] === LogCompareViewMode.COMPARE;
    if (!this.isPublic) {
      this.loginService.authenticated().subscribe({
        next: data => {
          this.user = data;
          if (this.user.theme) {
            this.themeService.changeTheme(this.user.theme.scssClassName);
          } else {
            this.themeService.changeTheme(this.user.organisation.theme.scssClassName);
          }
          this.logService.getById2(this.id).subscribe({
            next: data => {
              this.log = data;
              this.updateNotesView();
              if ((this.log.state === LogDetailDto.StateEnum.FINISHED || this.log.user.id !== this.user.id) && !this.isViewMode) {
                this.leaveSite();
                return;
              }
              this.getGroups();
              this.treeDataSource.data = this.groups;
              this.getInterruptionsFromLog();
              if (this.log.talkingPoints?.length > 0 && !this.getTalkinpointWithOrder(1).times?.start && !this.isViewMode) {
                let body: UpdateTalkingPointTimesDto = {
                  tId: this.getTalkinpointWithOrder(1).id,
                  start: new Date()
                }
                this.logService.updateTalkingPointTimes(body, this.log.id).subscribe({
                  next: data => {
                    this.log = data;
                    this.updateGroupSpeakingTimes();
                    this.updateChartData();
                  },
                  error: error => {
                    console.error(error);
                    this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
                  }
                });
              }
              setTimeout(() => {
                if (this.getTalkinpointWithOrder(1).times?.start) {
                  this.currentTalkingPoint = this.findCurrentTalkingPoint();
                  this.currentSpeakers = this.findCurrentSpeaker(this.currentTalkingPoint);
                  this.groups.forEach(group => this.currentSpeakerOfGroup[group.id] = this.getCurrentSpeakerOfGroup(group));
                  this.previousSpeaker = this.currentSpeakers;
                  this.setStepperIndex(this.currentTalkingPoint?.order - 1);
                  this.updateGroupSpeakingTimes();
                  this.updateChartData();
                }
              }, 0);
            },
            error: error => {
              console.error(error);
              this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
            }
          });
        },
        error: error => {
          console.error(error);
          this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
        }
      });
    } else {
      //public view
      this.publicService.getPublicLogById(this.id).subscribe({
        next: data => {
          this.log = data;
          this.logETag = JSON.parse(JSON.stringify(data));
          this.updateNotesView();
          this.getGroups();
          this.treeDataSource.data = this.groups;
          this.getInterruptionsFromLog();
          setTimeout(() => {
            if (this.getTalkinpointWithOrder(1).times?.start) {
              this.currentTalkingPoint = this.findCurrentTalkingPoint();
              this.currentSpeakers = this.findCurrentSpeaker(this.currentTalkingPoint);
              this.groups.forEach(group => this.currentSpeakerOfGroup[group.id] = this.getCurrentSpeakerOfGroup(group));
              this.previousSpeaker = this.currentSpeakers;
              this.setStepperIndex((this.currentTalkingPoint?.order - 1 > 0 && this.currentTalkingPoint?.order - 1 < this.log.talkingPoints.length) ? this.currentTalkingPoint?.order - 1 : 0);
              this.updateGroupSpeakingTimes();
              this.updateChartData();
            }
          }, 0);
        },
        error: error => {
          console.error(error);
          this.notificationService.open('Dieses Protokoll existiert nicht', 'ok', 15);
        }
      });
      this.fetchInterval = interval(5000).subscribe(() => this.publicLogLoader());
    }
  }

  publicLogLoader() {
    this.publicService.getPublicLogById(this.id).subscribe({
      next: data => {
        try {
          if (this.deepEqual(this.logETag, data)) {
            return;
          }
        } catch (err) { }

        this.log = data;
        this.logETag = JSON.parse(JSON.stringify(data));
        this.updateNotesView();
        this.getInterruptionsFromLog();
        setTimeout(() => {
          if (this.getTalkinpointWithOrder(1).times?.start) {
            this.currentTalkingPoint = this.findCurrentTalkingPoint();
            this.currentSpeakers = this.findCurrentSpeaker(this.currentTalkingPoint);
            this.groups.forEach(group => this.currentSpeakerOfGroup[group.id] = this.getCurrentSpeakerOfGroup(group));
            this.setStepperIndex((this.currentTalkingPoint?.order - 1 > 0 && this.currentTalkingPoint?.order - 1 < this.log.talkingPoints.length) ? this.currentTalkingPoint?.order - 1 : 0);
          }
        }, 0);
        if (this.log.state === LogDetailDto.StateEnum.FINISHED) {
          this.fetchInterval.unsubscribe();
          this.fetchInterval = null;
        }
      },
      error: error => {
        console.error(error);
        this.notificationService.open('Dieses Protokoll existiert nicht', 'ok', 15);
      }
    });
  }

  deepEqual(obj1, obj2) {
    if (obj1 == null && obj2 == null) {
      return true;
    }

    if ((obj1 === null || obj1 === undefined) && obj2 !== null && obj2 !== undefined) {
      return false;
    }

    if ((obj2 === null || obj2 === undefined) && obj1 !== null && obj1 !== undefined) {
      return false;
    }

    if (typeof obj1 !== typeof obj2) {
      return false;
    }

    if (typeof obj1 !== 'object') {
      return obj1 === obj2;
    }

    if (Array.isArray(obj1)) {
      if (obj1.length !== obj2.length) {
        return false;
      }

      obj1 = this.getIdMapOfArray(obj1);
      obj2 = this.getIdMapOfArray(obj2);
    }

    const keys1 = Object.keys(obj1).sort();
    const keys2 = Object.keys(obj2).sort();

    if (!this.compareKeys(keys1, keys2)) {
      return false;
    }

    for (let key of keys1) {
      if (!this.deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  compareKeys(keys1, keys2) {
    if (keys1.length != keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key)) {
        return false;
      }
    }

    return true;
  }

  getIdMapOfArray(obj) {
    let map = {};
    for(let idx = 0; idx < obj.length; idx++) {
        let o = obj[idx];
        if (o.id === undefined) {
            o.id = o.start;
        }
        map[o.id] = o;
    }
    return map;
  }

  setStepperIndex(index: number) {
    this.stepper.linear = false;
    this.stepper.selectedIndex = index;
    setTimeout(() => {
      this.stepper.linear = true;
    }, 0);
  }

  getInterruptionsFromLog() {
    let result: InterruptionsPanelData[] = [];
    this.interruptions = [];

    this.log.interruptions.forEach(group => {
      group.categories.forEach(category => {
        category.entry.data.forEach(entry => {
          let cousin = result.find(x => {
            let temp = { timestamp: x.timestamp, text: x.text };
            let temp2 = { timestamp: new Date(entry.start), text: entry.value };
            return JSON.stringify(temp) === JSON.stringify(temp2);
          });
          if (cousin) {
            cousin.byGroupName += ', ' + category.name;
          } else {
            let toPush: InterruptionsPanelData = {
              timestamp: new Date(entry.start),
              text: entry.value,
              byGroupName: category.name
            }
            result.push(toPush);
          }
        });
      });
    });
    this.interruptionsYData = result;
    this.datasourceInterruption = new MatTableDataSource<InterruptionsPanelData>(this.interruptionsYData);
    this.datasourceInterruption.paginator = this.interruptionPaginator;
    this.observableInterruptions = this.datasourceInterruption.connect();
  }

  findCurrentTalkingPoint() {
    this.log.talkingPoints = this.log.talkingPoints.sort((x, y) => x.order - y.order);
    let result = this.log.talkingPoints.find(x => !x.times || !x.times.end);
    return result ? result : this.getTalkinpointWithOrder(this.log.talkingPoints.length);
  }

  findCurrentSpeaker(tp: TalkingPointDataDto) {
    let result = [];
    tp?.speakers.forEach(x => {
      x.categories.forEach(y => {
        if (y.entry.data.find(z => !z.end)) {
          result.push(y.id);
        }
      });
    });
    return result;
  }

  getGroups() {
    this.groups = this.log.talkingPoints[0].speakers;
    this.sortGroups();
    this.groups.forEach(x => {
      this.totalChartData[x.id] = { data: {}, dataName: this.chartDataName }
      this.categories[x.id] = [];
      x.categories.forEach(y => {
        this.totalChartData[x.id].data[y.id] = { data: 4000, label: y.name };
        this.categories[x.id].push({ id: y.id, name: y.name });
        this.speakingTimes[y.id] = 0;
      });
    });
  }

  sortGroups() {
    this.groups = this.groups.sort((a, b) => this.sortFn(a, b));
    this.groups.forEach(group => {
      group.categories = group.categories.sort((a, b) => this.sortFn(a, b));
    });
  }

  private sortFn(a, b) {
    let nameA = a.name.toLowerCase();
    let nameB = b.name.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  convertTimeInMsToString(time: number): string {
    let seconds = Math.trunc(time / 1000);
    let minutes = Math.trunc(seconds / 60);
    seconds = seconds % 60;
    let secondsString = (seconds < 10) ? '0' + seconds : seconds;
    let minutesString = (minutes < 10) ? '0' + minutes : minutes;
    return minutesString + ':' + secondsString;
  }

  updateGroupSpeakingTimes() {
    this.groups.forEach(group => {
      group.categories.forEach(category => {
        let totalInMs = 0;
        this.log.talkingPoints.forEach(tp => {
          let data = tp.speakers.find(x => x.id === group.id).categories.find(x => x.id === category.id).entry.data;
          data.forEach(times => {
            if (times.end !== null) {
              totalInMs += new Date(times.end).getTime() - new Date(times.start).getTime();
            } else {
              totalInMs += new Date().getTime() - new Date(times.start).getTime();
            }
          });
        });
        this.speakingTimes[category.id] = totalInMs;
      })
    });
  }

  ngOnInit() {
    this.themeService.changeTheme(this.themeService.getActiveTheme());
    this.reload();
    setTimeout(() => {
      this.updateChartData();
      const dataIntervalSource = interval(1000);
      const chartIntervalSource = interval(60000);
      this.dataInterval = dataIntervalSource.subscribe(() => {
        this.time = this.getCurrentTimeRunning();
        if (this.currentSpeakers.length !== 0 && this.log.talkingPoints) {
          //speakingtimes don't change if nobody is talking so we can save a bit of looping time
          this.updateGroupSpeakingTimes();
        }
        this.updateTotalPausedTime();
        if (this.log.state === LogDetailDto.StateEnum.FINISHED) {
          this.dataInterval.unsubscribe();
          this.dataInterval = null;
        }
      });
      this.chartInterval = chartIntervalSource.subscribe(() => {
        this.updateChartData();
        if (this.log.state === LogDetailDto.StateEnum.FINISHED) {
          this.chartInterval.unsubscribe();
          this.chartInterval = null;
        }
      });
    }, 1000);
  }

  ngOnDestroy() {
    if (this.dataInterval && this.chartInterval) {
      this.dataInterval.unsubscribe();
      this.chartInterval.unsubscribe();
    }
    if (this.fetchInterval)
      this.fetchInterval.unsubscribe();
  }

  updateTotalPausedTime() {
    let sumOfSpeakingTimes = 0;
    Object.keys(this.speakingTimes).forEach(key => sumOfSpeakingTimes += this.speakingTimes[key]);
    this.totalPauseTime = this.getCurrentTimeRunning() - sumOfSpeakingTimes;
  }

  getGroupNameById(id: string): string {
    return this.groups.find(x => x.id === id).name;
  }

  getCategoryNameByid(id: string): string {
    let rv = null;
    this.groups.forEach(group => {
      let found = group.categories.find(category => category.id === id);
      if (found)
        rv = found;
    });
    return rv.name;
  }

  updateChartData() {
    let newChartData = {};
    this.groups.forEach(group => {
      let newData = {};
      Object.keys(this.speakingTimes).forEach(x => {
        if (group.categories.find(y => y.id === x))
          newData[x] = { data: Math.round(this.speakingTimes[x] / 60000), label: this.getCategoryNameByid(x) };
      });
      newChartData[group.id] = {
        data: newData,
        dataName: this.chartDataName
      }
    });
    this.totalChartData = newChartData;
  }


  finishTalkingPoint(tp: TalkingPointDataDto) {
    if (this.isViewMode)
      return;
    let isFinish = false;
    let dialogData: ConfirmDialogData = {
      message: 'Wenn Sie zum nächsten Tagesordnungspunkt wechseln, können sie den derzeitigen nicht mehr bearbeiten.',
      denyButtonText: 'Bei diesem bleiben',
      acceptButtonText: 'zum nächsten Tagesordnungspunkt'
    }
    if (tp.order === this.log.talkingPoints.length) {
      isFinish = true;
      dialogData = {
        message: 'Wenn Sie das Protokoll beenden können Sie es nicht mehr bearbeiten.',
        denyButtonText: 'Nicht beenden',
        acceptButtonText: 'Protokoll beenden'
      }
    }

    let ref = this.dialog.open(ConfirmDialogComponent, { data: dialogData });
    ref.afterClosed().subscribe(result => {
      if (result === true) {
        if (isFinish === true) {
          this.finishProtocol(tp);
          return;
        }
        this.setStepperIndex(this.findCurrentTalkingPoint().order);
        let body: any = {
          tId: this.getTalkinpointWithOrder(tp.order + 1).id,
          start: new Date()
        }
        this.logService.updateTalkingPointTimes(body, this.log.id).subscribe({
          next: data => {
            this.log = data;
            this.currentSpeakers = [];
            this.groups.forEach(group => this.currentSpeakerOfGroup[group.id] = this.getCurrentSpeakerOfGroup(group));
          },
          error: error => {
            console.error(error);
            this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
          }
        });
      }
    });
  }

  finishProtocol(tp: TalkingPointDataDto) {
    if (this.isViewMode)
      return;
    let body: any = {
      tId: tp.id,
      end: new Date()
    }
    this.logService.updateTalkingPointTimes(body, this.log.id).subscribe({
      next: () => {
        body = {
          state: 'FINISHED'
        }
        this.logService.updateLogState(body, this.log.id).subscribe({
          next: () => {
            this.notificationService.open('Protokoll erfolgreich beendet', 'ok', 5);
            this.leaveSite();
          },
          error: error => {
            console.error(error);
            this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
          }
        })
      },
      error: error => {
        console.error(error);
        this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
      }
    })
  }

  getStepperButtonText(tp: TalkingPointDataDto): string {
    return tp.order === this.log.talkingPoints.length ? 'Protokoll beenden' : 'Nächster Tagesordnungspunkt';
  }


  isTpEditable(tp: TalkingPointDataDto): boolean {
    return tp.times?.start && !tp.times?.end;
  }

  hideSteps() {
    let steps = document.querySelectorAll('.mat-horizontal-stepper-content');
    steps.forEach(x => this.renderer.setStyle(x, 'display', 'none'));
  }

  changeSpeakers(tp: TalkingPointDataDto) {
    if (this.isViewMode)
      return;
    let ref = this.dialog.open(SpeakerSwitchDialogComponent, { data: { groups: this.groups } });
    ref.afterClosed().subscribe(result => {
      if (!result)
        return;
      this.previousSpeaker = this.currentSpeakers;
      this.currentSpeakers = [];
      Object.keys(result).forEach(key => {
        if (result[key])
          this.currentSpeakers.push(result[key])
      });
      let body: LogTalkingPointUpdateDto[] = [];
      let timestamp = new Date();
      if (this.currentSpeakers.length > 0) {
        this.currentSpeakers.forEach(speaker => {
          body.push({
            entry: {
              start: timestamp
            },
            tId: tp.id,
            cId: speaker
          });
        });
      } else if (this.previousSpeaker.length > 0) {
        this.previousSpeaker.forEach(speaker => {
          body.push({
            entry: {
              end: timestamp
            },
            tId: tp.id,
            cId: speaker
          });
        });
      } else {
        return;
      }

      this.logService.updateTalkingPoint(body, this.log.id).subscribe({
        next: data => {
          this.log = data;
          this.groups.forEach(group => this.currentSpeakerOfGroup[group.id] = this.getCurrentSpeakerOfGroup(group));
        },
        error: error => {
          console.error(error);
          this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
        }
      })
    });
  }
  pauseSpeakers(tp: TalkingPointDataDto) {
    if (this.isViewMode)
      return;
    //let ref = this.dialog.open(SpeakerSwitchDialogComponent, { data: { groups: this.groups } });
    //ref.afterClosed().subscribe(result => {
      this.previousSpeaker = this.currentSpeakers;
      this.currentSpeakers = [];
      let body: LogTalkingPointUpdateDto[] = [];
      let timestamp = new Date();
      if (this.currentSpeakers.length > 0) {
        this.currentSpeakers.forEach(speaker => {
          body.push({
            entry: {
              start: timestamp
            },
            tId: tp.id,
            cId: speaker
          });
        });
      } else if (this.previousSpeaker.length > 0) {
        this.previousSpeaker.forEach(speaker => {
          body.push({
            entry: {
              end: timestamp
            },
            tId: tp.id,
            cId: speaker
          });
        });
      } else {
        return;
      }

      this.logService.updateTalkingPoint(body, this.log.id).subscribe({
        next: data => {
          this.log = data;
          this.groups.forEach(group => this.currentSpeakerOfGroup[group.id] = this.getCurrentSpeakerOfGroup(group));
        },
        error: error => {
          console.error(error);
          this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
        }
      })
    //});
    //todo

  }

  addInterruption() {
    if (this.isViewMode)
      return;
    let dialogData: InterruptionAddData = {
      possibleSpeakers: this.groups
    }
    let ref = this.dialog.open(InterruptionAddComponent, { data: dialogData });
    ref.afterClosed().subscribe(result => {
      if (result) {
        let timestamp = new Date();
        let body: LogCategoryUpdateDto[] = [];
        result.speakers.forEach(category => {
          if (!category)
            return;
          body.push({
            entry: {
              start: timestamp,
              end: timestamp,
              value: result.text
            },
            cId: category.id
          })
        });

        this.logService.updateCategory(body, this.log.id).subscribe({
          next: data => {
            this.log = data;
            this.getInterruptionsFromLog();
          },
          error: error => {
            console.error(error);
            this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
          }
        })
      }
    });
  }

  updateNotesView() {
    this.log.notes.forEach(x => {
      x.createdAt = new Date(x.createdAt);
      x.updatedAt = new Date(x.updatedAt);
    })
    this.datasourceNote = new MatTableDataSource<NoteDetailDto>(this.log.notes);
    this.datasourceNote.paginator = this.notePaginator;
    this.observableNotes = this.datasourceNote.connect();
  }

  addNote() {
    if (this.isViewMode)
      return;
    let ref = this.dialog.open(NoteAddDialogComponent);
    ref.afterClosed().subscribe(result => {
      if (result !== false) {
        this.noteService.createNote(result, this.log.id).subscribe({
          next: data => {
            this.log.notes.push(data);
            this.updateNotesView();
            this.notificationService.open('Notiz hinzugefügt!', 'ok', 5);
          },
          error: error => {
            console.error(error);
            this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
          }
        })
      }
    });
  }

  updateNote(id: string) {
    if (this.isViewMode)
      return;
    let note: NoteDetailDto = this.log.notes.find(x => x.id === id);
    let ref = this.dialog.open(NoteAddDialogComponent, { data: { text: note.note } });
    ref.afterClosed().subscribe(result => {
      if (result !== false) {
        this.noteService.updateNote(result, id).subscribe({
          next: data => {
            note.createdAt = data.createdAt;
            note.note = data.note;
            note.updatedAt = data.updatedAt;
            this.updateNotesView();
            this.notificationService.open('Notiz aktualisiert!', 'ok', 5);
          },
          error: error => {
            console.error(error);
            this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
          }
        })
      }
    });
  }

  deleteNote(id: string) {
    if (this.isViewMode)
      return;
    let note: NoteDetailDto = this.log.notes.find(x => x.id === id);
    let dialogData: ConfirmDialogData = {
      message: 'Wollen Sie diese Notiz wirklich permanent löschen?',
      denyButtonText: 'Abbrechen',
      acceptButtonText: 'Löschen'
    }
    let ref = this.dialog.open(ConfirmDialogComponent, { data: dialogData });
    ref.afterClosed().subscribe(result => {
      if (result === true) {
        this.noteService.deleteNote(id).subscribe({
          next: () => {
            this.log.notes = this.log.notes.filter(x => x.id !== id);
            this.notificationService.open('Notiz erfolgreich gelöscht!', 'ok', 5);
            this.updateNotesView();
          },
          error: error => {
            console.error(error);
            this.notificationService.open('Es ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
          }
        })
      }
    });
  }


  getCurrentSpeakerOfGroup(group: CategoryGroupDataDto): string {
    let rv = '';
    group.categories.forEach(category => {
      let res = this.currentSpeakers.find(x => x === category.id);
      if (res)
        rv = res;
    });
    return rv;
  }

  hasChild = (_: number, node: GroupTreeNode) => node.expandable;


  isProtocolFinished() {
    return this.log?.state !== LogDetailDto.StateEnum.RUNNING;
  }
}


export interface InterruptionsPanelData {
  timestamp: Date,
  text: string,
  byGroupName: string
}

interface GroupTreeNode {
  id: string;
  name: string;
  expandable: boolean;
  level: number;
}
