import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Globals {
  readonly backendUri: string = this.findBackendUrl();

  private findBackendUrl(): string {
    if (window.location.port === '4200') { // local `ng serve`, backend at localhost:8080
      return 'http://localhost:8080';
    } else {
      // assume deployed somewhere and backend is available at same host/port as frontend
      let temp = window.location.protocol + '//' + window.location.host + window.location.pathname + '';
      //return temp[temp.length - 1] == '/' ? temp.slice(0, -1) : temp;
      return 'https://fairmeeting.ha1r.eu'
    }
  }
}


