import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MeetingDto } from 'src/app/api/model/meetingDto';
import { PublicEndpointService } from 'src/app/api/api/publicEndpoint.service';
import { NotificationServiceService } from 'src/app/services/notification-service.service';
import { LogListDto } from 'src/app/api/model/logListDto';
import { Subscription, interval } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { LogDetailDto } from 'src/app/api/model/logDetailDto';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { LogCompareViewMode } from '../log-compare-view/log-compare-view.component';
import { ThemeServiceService } from 'src/app/services/theme-service.service';

@Component({
  selector: 'app-meeting-public-view',
  templateUrl: './meeting-public-view.component.html',
  styleUrls: ['./meeting-public-view.component.scss']
})
export class MeetingPublicViewComponent {

  meetingId: string = null;
  meeting: MeetingDto;
  logs: LogListDto[] = [];

  logsInterval: Subscription;

  displayedColumns: string[] = ['name', 'state', 'actions'];
  datasource = new MatTableDataSource();
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private notificationService: NotificationServiceService, private publicService: PublicEndpointService,
     private route: ActivatedRoute, private router: Router, private themeService: ThemeServiceService) {}

  ngOnInit() {
    this.themeService.changeTheme(this.themeService.getActiveTheme());
    this.meetingId = this.route.snapshot.params['id'];
    if(!this.meetingId) {
      this.notificationService.open('Dieser Link ist nicht valide', 'ok', 15);
      return;
    }

    this.publicService.getPublicMeetingById(this.meetingId).subscribe({
      next: meeting => {
        this.meeting = meeting;
      },
      error: error => {
        console.error(error);
        this.notificationService.open('Dieser Link ist nicht valide', 'ok', 15);
      }
    });

    this.getLogs();
    this.logsInterval = interval(5000).subscribe(() => this.getLogs());
  }

  ngOnDestroy() {
    if(this.logsInterval)
      this.logsInterval.unsubscribe();
  }

  getLogs() {
    this.publicService.getPublicLogsByMeetingId(this.meetingId).subscribe({
      next: logs => {
        this.logs = logs;
        this.datasource.data = this.logs;
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort;
      },
      error: error => {
        console.error(error);
        this.notificationService.open('Dieser Link ist nicht valide', 'ok', 15);
      }
    });
  }

  navigateToView(logId: string) {
    this.router.navigateByUrl('/public/log/' + logId, {state: {mode:  LogCompareViewMode.COMPARE}});
  }

  logIsViewable(log: LogListDto) {
    return log.state !== LogDetailDto.StateEnum.RUNNING;
  }

  stateDisplayName(state: LogDetailDto.StateEnum) {
    if(state === LogDetailDto.StateEnum.FINISHED)
      return 'Beendet';
    if(state === LogDetailDto.StateEnum.RUNNING)
      return 'Protokoll läuft';
    if(state === LogDetailDto.StateEnum.IGNORED)
      return 'Ausgeblendet';
  }
}
