import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { CategoryDto } from 'src/app/api/model/categoryDto';
import { NotificationServiceService } from 'src/app/services/notification-service.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { CategoryMinimalDto } from 'src/app/api/model/categoryMinimalDto';
import { GroupDetailDto } from 'src/app/api/model/groupDetailDto';
import { GroupEndpointService } from 'src/app/api/api/groupEndpoint.service';
import { ConfirmDialogComponent, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-group-create-update-dialog',
  templateUrl: './group-create-update-dialog.component.html',
  styleUrls: ['./group-create-update-dialog.component.scss']
})
export class GroupCreateUpdateDialogComponent implements AfterViewInit {
  @ViewChild('form') form: NgForm;
  @ViewChild('table', { static: true }) table: MatTable<CategoryDto>;

  displayedColumns: string[] = ['name', 'action'];
  categories: CategoryMinimalDto[] = [];
  categoriesToAdd: CategoryMinimalDto[] = [];
  categoriesToDelete: CategoryMinimalDto[] = [];

  constructor(private dialog: MatDialog, public dialogRef: MatDialogRef<GroupCreateUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GroupDetailDto, private notificationService: NotificationServiceService,
    private groupService: GroupEndpointService){
  }

  dynamicValidator(){
    if(this.categories.length == 0){
      this.form.controls['category'].addValidators(Validators.required);
    }else{
      this.form.controls['category'].removeValidators(Validators.required);
    }
  }

  ngAfterViewInit() {
    if(this.data?.name) {
      setTimeout(() => {
        this.form.controls['name'].setValue(this.data.name);
        this.data.categories.forEach(x => this.categories.push(x));
        this.dynamicValidator();
        this.table.renderRows();
      }, 0);
    }
    this.dialogRef.disableClose = true;
  }

  save() {
    if(this.categories.length==0){
      this.notificationService.open('Bitte stellen Sie sicher, dass sie mindestens eine Kategorie eingeben', 'ok', 15);
      return;
    }
    if(!this.form.valid) {
      this.notificationService.open('Bitte stellen Sie sicher, dass der Name den Anforderungen entspricht', 'ok', 15);
      return;
    }

    let body: GroupDetailDto = {
      id: this.data?.id ? this.data.id : null,
      name: this.form.controls['name'].value,
      categories: this.categories
    }

    if(this.data?.id) {
      if(this.categoriesToAdd.length > 0 && (this.categoriesToDelete.length == 0)){
        this.groupService.createCategories(this.categoriesToAdd.map(x => x.name), this.data.id).subscribe({
          next: () => {
            this.notificationService.open('Kategorien erfolgreich bearbeitet', 'ok', 5);
            this.dialogRef.close(true);
          },
          error: error => {
            this.notificationService.open('Beim hinzufügen von Kategorien ist etwas schiefgegangen', 'ok', 15);
            console.error(error);
          }
        });
      }
      if(this.categoriesToDelete.length > 0){
        this.groupService.deleteCategory(this.categoriesToDelete.map(x => x.id), this.data.id).subscribe({
          next: () => {
            this.notificationService.open('Kategorien erfolgreich gelöscht', 'ok', 5);
            
            if(this.categoriesToAdd.length > 0){
              this.groupService.createCategories(this.categoriesToAdd.map(x => x.name), this.data.id).subscribe({
                next: () => {
                  this.notificationService.open('Kategorien erfolgreich bearbeitet', 'ok', 5);
                  this.dialogRef.close(true);
                },
                error: error => {
                  this.notificationService.open('Beim hinzufügen von Kategorien ist etwas schiefgegangen', 'ok', 15);
                  console.error(error);
                }
              });
            }

            this.dialogRef.close(true);
          },
          error: error => {
            this.notificationService.open('Sie können keine Kategorien löschen welche bereits verwendet werden somit wurden die Änderungen verworfen', 'ok', 15);
            console.error(error);
          }
        });
      }
      if(this.data.name !== body.name){
        this.groupService.update1(body, this.data.id).subscribe({
          next: () => {
            this.notificationService.open('Gruppe erfolgreich bearbeitet', 'ok', 5);
            this.dialogRef.close(true);
          },
          error: error => {
            this.notificationService.open('Beim Bearbeiten von der Gruppe ist etwas schiefgegangen', 'ok', 15);
            console.error(error);
          }
        });
      }else{
        this.notificationService.open('Bearbeitung der Gruppe abgeschlossen', 'ok', 5);
        this.dialogRef.close(true);
      }
    } else {
      this.groupService.create2(body).subscribe({
        next: () => {
          this.notificationService.open('Gruppe erfolgreich erstellt', 'ok', 5);
          this.dialogRef.close(true);
        },
        error: error => {
          this.notificationService.open('Beim Erstellen ist etwas schiefgegangen', 'ok', 15);
          console.error(error);
        }
      });
    }
  }

  getHeading() {
    return (this.data?.id ? 'Bearbeite ' + this.data.name : 'Erstelle eine') + ' Gruppe';
  }

  deleteCategory(category){
    const index = this.categories.indexOf(category);
    if (index >= 0) {
      let catDel = this.categories.splice(index, 1)[0];
      if(catDel.id){
        this.categoriesToDelete.push(catDel);
      }
      let idx = this.categoriesToAdd.findIndex(x=>x.name == catDel.name);
      if(idx != -1){
        this.categoriesToAdd.splice(idx, 1);
      }
    }
    this.dynamicValidator();
    this.table.renderRows();
  }

  addCategory(event: MatChipInputEvent){
    if (this.categories.length > 3){
      this.notificationService.open("Es können nicht mehr als 4 Kategorien festgelegt werden", "ok", 5);
      return;
    }
    if (!this.form.controls["category"].valid || event.value == "") {
      return;
    }
    if(this.categories.find(x => x.name == event.value)){
      this.notificationService.open('Es gibt bereits eine Kategorie ' + event.value, 'ok', 5);
      return;
    }
    let newCat = {
      name: event.value
    }
    let idx = this.categoriesToDelete.findIndex(x=>x.name == newCat.name);
    if(idx != -1){
      this.categoriesToDelete.splice(idx, 1);
    }else{
      this.categoriesToAdd.push(newCat);
    }
    this.categories.push(newCat);
    this.dynamicValidator();
    this.form.controls['category'].setValue("");
    this.table.renderRows();
  }


  cancel() {
    let dialogData: ConfirmDialogData = {
      message: 'Wollen Sie das ' + (this.data?.id ? 'Bearbeiten ' : 'Erstellen') + ' wirklich abbrechen?',
      denyButtonText: 'Nein',
      acceptButtonText: 'Ja'
    }
    let ref = this.dialog.open(ConfirmDialogComponent, {data: dialogData});
    ref.afterClosed().subscribe(result => {
      if (result === true) {
        this.dialogRef.close(false);
      }
    });
  }
}
