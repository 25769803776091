<mat-form-field>
    <mat-label>Gruppen durchsuchen</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Name suchen" #groupInput>
</mat-form-field>
<div class="mat-elevation-z8">
    <table mat-table [dataSource]="groupDataSource" #groupTableSort="matSort" matSort #groupTable>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let group"> {{group?.name}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Bearbeiten</th>
            <td mat-cell *matCellDef="let group">
                <button mat-mini-fab color="accent" (click)="update(group)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-mini-fab color="warn" (click)="delete(group?.id, group?.name)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">Keine Eintrage für "{{groupInput.value}}" gefunden</td>
        </tr>
    </table>
    <mat-paginator #paginatorGroup="matPaginator" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="select page"></mat-paginator>
</div>
<div class="singleButtonContainer">
    <button mat-raised-button color="primary" (click)="createNewOfType()">
        neue Gruppe erstellen <mat-icon>add</mat-icon>
    </button>
</div>