import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { NotificationServiceService } from 'src/app/services/notification-service.service';
import { ThemeServiceService } from 'src/app/services/theme-service.service';
import { GroupCreateUpdateDialogComponent } from './group-create-update-dialog/group-create-update-dialog.component';
import { ConfirmDialogComponent, ConfirmDialogData } from '../../confirm-dialog/confirm-dialog.component';
import { GroupDetailDto } from 'src/app/api/model/groupDetailDto';
import { GroupMinimalDto } from 'src/app/api/model/groupMinimalDto';
import { LoginEndpointService } from 'src/app/api/api/loginEndpoint.service';
import { GroupEndpointService } from 'src/app/api/api/groupEndpoint.service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['name', 'actions'];

  groupDataSource = new MatTableDataSource();
  groups: GroupDetailDto[] = [];
  
  @ViewChild('paginatorGroup') groupPaginator: MatPaginator;
  @ViewChild('groupTableSort') groupTableSort: MatSort;
  @ViewChild(MatTable) groupTable: MatTable<GroupMinimalDto>;

  constructor(private notificationService: NotificationServiceService, private loginService: LoginEndpointService, 
    private dialog: MatDialog, private themeService: ThemeServiceService, private groupService: GroupEndpointService) {}

  ngOnInit() {
    this.themeService.changeTheme(this.themeService.getActiveTheme());
    this.reload();
  }

  ngAfterViewInit() {
    this.groupDataSource.paginator = this.groupPaginator;
    this.groupDataSource.sort = this.groupTableSort;
  }

  reload() {
    this.loginService.authenticated().subscribe({
      next: data => {
        this.groupService.getAll3().subscribe({
          next: groups => {
            this.groups = groups;
            this.groupDataSource.data = this.groups;
          },
          error: error => {
            this.notificationService.open('Etwas ist schiefgegangen', 'ok', 15);
            console.error(error);
          }
        });
      },
      error: error => {
        this.notificationService.open('Etwas ist schiefgegangen', 'ok', 15);
        console.error(error);
      }
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.groupDataSource.filter = filterValue.trim().toLowerCase();

    if (this.groupDataSource.paginator) {
      this.groupDataSource.paginator.firstPage();
    }
  }
  createNewOfType() {
    let ref = this.dialog.open(GroupCreateUpdateDialogComponent);

    ref.afterClosed().subscribe(result => {
      if(result)
        this.reload();
    });
  }

  update(group) {
    let ref = this.dialog.open(GroupCreateUpdateDialogComponent, {data: group});

    ref.afterClosed().subscribe(result => {
      if(result)
        this.reload();
    });
  }


  delete(id: string, name: string) {
    let dialogData: ConfirmDialogData = {
      message: 'Wollen Sie ' + name + ' dauerhaft löschen?',
      denyButtonText: 'Nein',
      acceptButtonText: 'Ja'
    }
    let ref = this.dialog.open(ConfirmDialogComponent, {data: dialogData});

    ref.afterClosed().subscribe(result => {
      if(result === true) {
        this.groupService.deleteGroup(id).subscribe({
          next: () => {
            this.notificationService.open(name + ' wurde gelöscht', 'ok', 5);
            this.reload();
          },
          error: error => {
            console.error(error);
            this.notificationService.open('Beim Löschen von ' + name +' ist etwas schiefgegangen', 'ok', 15);
          }
        });
      }
    });
  }
}
