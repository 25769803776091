/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OrganisationDto } from './organisationDto';
import { ThemeDto } from './themeDto';

export interface UserDetailDto { 
    id?: string;
    username?: string;
    role?: UserDetailDto.RoleEnum;
    organisation?: OrganisationDto;
    theme?: ThemeDto;
}
export namespace UserDetailDto {
    export type RoleEnum = 'USER' | 'ADMIN' | 'DISABLED';
    export const RoleEnum = {
        USER: 'USER' as RoleEnum,
        ADMIN: 'ADMIN' as RoleEnum,
        DISABLED: 'DISABLED' as RoleEnum
    };
}