import {Component, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {NotificationServiceService } from 'src/app/services/notification-service.service';
import {LogEndpointService} from 'src/app/api/api/logEndpoint.service'
import {LoginEndpointService} from 'src/app/api/api/loginEndpoint.service'
import {MeetingEndpointService} from 'src/app/api/api/meetingEndpoint.service'
import {MeetingListDto} from 'src/app/api/model/meetingListDto';
import {OrganisationDto} from 'src/app/api/model/organisationDto';
import { ConfirmDialogComponent, ConfirmDialogData } from '../../confirm-dialog/confirm-dialog.component';
import {ThemeServiceService} from 'src/app/services/theme-service.service';
import {AuthService} from 'src/app/services/auth.service';
import { MeetingCreateDialogComponent, MeetingDialogData } from '../meeting-create-dialog/meeting-create-dialog.component';
import { LogDetailDto } from 'src/app/api/model/logDetailDto';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import { MeetingPublicDialogComponent, MeetingPublicDialogData } from '../../meeting-public-dialog/meeting-public-dialog.component';
import { CloneExistingMeetingDialogComponent } from '../meeting-create-dialog/clone-existing-meeting-dialog/clone-existing-meeting-dialog.component';

@Component({
  selector: 'app-meeting-detail-view',
  templateUrl: './meeting-detail-view.component.html',
  styleUrls: ['./meeting-detail-view.component.scss']
})
export class MeetingDetailViewComponent {

  displayedColumns: string[] = ['name', 'actions'];
  organisation: OrganisationDto = null;

  meetings: MeetingTemplate[] = [];
  meetingDtos: MeetingListDto[] = [];

  datasource = new MatTableDataSource();

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('tableSort') sort: MatSort;
  constructor(private dialog: MatDialog, private notificationService: NotificationServiceService,
              private meetingService: MeetingEndpointService, private loginService: LoginEndpointService,
              private themeService: ThemeServiceService, private logService: LogEndpointService,
              private  authService: AuthService) {
  }

  reload() {
    this.loginService.authenticated().subscribe({
      next: data => {
        this.organisation = data.organisation;
        this.meetingService.getAll1().subscribe({
          next: data => {
            this.meetingDtos = data;
            this.convertMeeting(data, this.authService.getId());
          },
          error: error => {
            console.error(error)
            this.notificationService.open('Beim Laden ist ein unerwarteter Fehler aufgetreten', 'ok', 15);
          }
        });
      },
      error: error => {
        console.error(error)
        this.notificationService.open('Ein unerwarteter Fehler ist aufgetreten', 'ok', 15);
      }
    });
  }

  getAllLogsForMeeting(meeting):number{
    return this.meetingDtos.find(x => x.id==meeting.id)?.logs?.length;
  }

  logIsRunning(logState){
    if(logState == LogDetailDto.StateEnum.RUNNING) return true;
    return false;
  }

  convertMeeting(meetings: MeetingListDto[], userId: String) {
    this.meetings = [];
    meetings.forEach(x =>{
      let m = {id: x.id, name: x.name, idlog: x.logs.filter(s => s.user.id === userId)[0]?.id, logState: undefined, public: x['public']};
      if (m.idlog != undefined){
        this.logService.getById2(m.idlog).subscribe(data => {
        m.logState = data.state;
        });
      }
      this.meetings.push(m);
    })
    this.datasource.data = this.meetings;
  }

  ngOnInit() {
    this.themeService.changeTheme(this.themeService.getActiveTheme());
    this.reload();
  }

  ngAfterViewInit() {
    this.datasource.paginator = this.paginator;
    this.datasource.sort = this.sort;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.datasource.filter = filterValue.trim().toLowerCase();

    if (this.datasource.paginator) {
      this.datasource.paginator.firstPage();
    }
  }

  addMeeting() {
    let ref = this.dialog.open(CloneExistingMeetingDialogComponent, {data: {organisationId: this.organisation.id}});
    ref.afterClosed().subscribe(result => {
        this.reload();
    });
  }

  updateMeeting(id: string) {
    this.meetingService.getById1(id).subscribe(data => {
      let meetingData: MeetingDialogData = {
        meeting: data,
        organisationId: this.organisation.id,
        templateData: false
      }
      let ref = this.dialog.open(MeetingCreateDialogComponent, {data: meetingData});
      ref.afterClosed().subscribe(result => {
        if(result) {
          this.reload();
        }
      });
    });
  }

  createLog(id: string, name: string) {
    let dialogData: ConfirmDialogData = {
      message: 'Sind Sie sicher, dass ' + name + ' gestartet werden soll?  Die Sitzung kann dann nicht mehr bearbeitet werden.',
      denyButtonText: 'Nein',
      acceptButtonText: 'Ja'
    }
    let ref = this.dialog.open(ConfirmDialogComponent, {data: dialogData});
    ref.afterClosed().subscribe(result => {
      if (result === true) {
        this.logService.create1(id).subscribe({
          next: data => {
            this.reload();
          },
          error: error => {
            console.error(error);
            this.notificationService.open(name + ' konnte wegen eines unerwarteten Fehlers nicht gestarted werden', 'ok', 15)
          }
        });
      }
    });
  }

  deleteMeeting(id: string, name: string) {
    let dialogData: ConfirmDialogData = {
      message: 'Sind Sie sicher, dass ' + name + ' gelöscht werden soll?',
      denyButtonText: 'Nein',
      acceptButtonText: 'Ja'
    }
    let ref = this.dialog.open(ConfirmDialogComponent, {data: dialogData});
    ref.afterClosed().subscribe(result => {
      if (result === true) {
        this.meetingService.deleteMeeting(id).subscribe({
          next: () => {
            this.notificationService.open(name + ' erfoglreich gelöscht', 'ok', 5);
            this.reload();
          },
          error: error => {
            console.error(error);
            this.notificationService.open(name + ' konnte wegen eines unerwarteten Fehlers nicht gelöscht werden', 'ok', 15);
          }
        });
      }
    });
  }

  openPublicDialog(meeting: MeetingListDto) {
    let dialogData: MeetingPublicDialogData = {
      meetingId: meeting.id,
      meetingName: meeting.name
    }
    this.dialog.open(MeetingPublicDialogComponent, {data: dialogData});
  }

  isMeetingPublic(meeting: MeetingTemplate) {
    return meeting.public;
  }
}

interface MeetingTemplate{
  id: string,
  name: string,
  idlog: string,
  public: boolean
}
