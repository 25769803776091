<div class="dialogWrapper">
    <strong>{{data.message}}</strong>
    <div class="buttonContainer">
        <button (click)="deny()" mat-flat-button color="accent">
            {{data.denyButtonText}}
        </button>
        <button (click)="confirm()" mat-flat-button color="primary">
            {{data.acceptButtonText}}
        </button>
    </div>
</div>


