import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {Globals} from "./global/globals";
import {BASE_PATH} from "./api/variables";

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {HomeComponent} from './components/home/home.component';
import {LoginComponent} from './components/login/login.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {httpInterceptorProviders} from './interceptors';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LoginEndpointService } from './api/api/loginEndpoint.service';
import { UserdetailviewComponent } from './components/userdetailview/userdetailview.component';
import { UserListComponent } from './components/organisation-detail-view/user-list/user-list.component';
import { UserEndpointService } from './api/api/userEndpoint.service';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { NotificationServiceService } from './services/notification-service.service';
import { ThemeServiceService } from './services/theme-service.service';
import { PasswordDialogComponent } from './components/password-dialog/password-dialog.component';
import { OrganisationDetailViewComponent } from './components/organisation-detail-view/organisation-detail-view.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ValidatorService } from './services/validator.service';
import { ThemeEndpointService } from './api/api/themeEndpoint.service';
import { OrganisationEndpointService } from './api/api/organisationEndpoint.service';
import { CategoriesComponent } from './components/categories/categories.component';
import { CategoryEndpointService } from './api/api/categoryEndpoint.service';
import { MeetingTemplateEndpointService } from './api/api/meetingTemplateEndpoint.service';
import { CustomMatPaginatorIntl } from './overwriters/customPaginator';
import { Router } from '@angular/router';
import { ForbiddenInterceptor } from './interceptors/forbidden-interceptor';
import { LogviewComponent } from './components/logview/logview.component';
import { LogEndpointService } from './api/api/logEndpoint.service';
import {MatStepperModule} from '@angular/material/stepper';
import {MatListModule} from '@angular/material/list';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatExpansionModule} from '@angular/material/expansion';
import {NgPipesModule} from 'ngx-pipes';
import { InterruptionAddComponent } from './components/interruption-add/interruption-add.component';
import { NoteAddDialogComponent } from './components/note-add-dialog/note-add-dialog.component';
import { BasicChartComponent } from './components/basic-chart/basic-chart.component';
import { MeetingCreateDialogComponent } from './components/meeting-view/meeting-create-dialog/meeting-create-dialog.component';

import {CustomDateAdapter} from './components/meeting-view/meeting-create-dialog/custom.date.adapter';

import { MeetingEndpointService } from './api/api/meetingEndpoint.service';
import { MeetingDetailViewComponent } from './components/meeting-view/meeting-detail-view/meeting-detail-view.component';
import { LogDetailViewComponent } from './components/log-detail-view/log-detail-view.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule, DateAdapter } from '@angular/material/core';
import { NoteEndpointService } from './api/api/noteEndpoint.service';
import { DataManagementViewComponent } from './components/data-management-view/data-management-view.component';
import { GroupEndpointService } from './api/api/groupEndpoint.service';
import { GroupCreateUpdateDialogComponent } from './components/data-management-view/groups/group-create-update-dialog/group-create-update-dialog.component';
import { MeetingViewComponent } from './components/meeting-view/meeting-view.component';
import { GroupsComponent } from './components/data-management-view/groups/groups.component';
import { MeetingTemplatesComponent } from './components/meeting-view/meeting-templates/meeting-templates.component';
import { MeetingTemplatesCreateUpdateDialogComponent } from './components/meeting-view/meeting-templates/meeting-templates-create-update-dialog/meeting-templates-create-update-dialog.component';
import { ParticipantViewComponent } from './components/participant-view/participant-view.component';
import { ParticipantEndpointService } from './api/api/participantEndpoint.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ParticipantDialogComponent } from './components/participant-dialog/participant-dialog.component';
import { LogCompareViewComponent } from './components/log-compare-view/log-compare-view.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import { SpeakerSwitchDialogComponent } from './components/speaker-switch-dialog/speaker-switch-dialog.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import { InterruptionChartComponent } from './components/interruption-chart/interruption-chart.component';
import { MeetingPublicViewComponent } from './components/meeting-public-view/meeting-public-view.component';
import { MeetingPublicDialogComponent } from './components/meeting-public-dialog/meeting-public-dialog.component';
import { PublicEndpointService } from './api/api/publicEndpoint.service';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { QRCodeModule } from 'angularx-qrcode';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SideComponent } from './components/header/side/side.component';
import { LogMultiSessinStatisticComponent } from './components/log-multi-sessin-statistic/log-multi-sessin-statistic.component';
import { InterruptionBarCharComponent } from './components/interruption-bar-char/interruption-bar-char.component';
import { NewLogoService } from './services/new-logo.service';
import { CloneExistingMeetingDialogComponent } from './components/meeting-view/meeting-create-dialog/clone-existing-meeting-dialog/clone-existing-meeting-dialog.component';
import { CrossTypeStatisticsComponent } from './components/charts/cross-type-statistics/cross-type-statistics.component';
import { CrossInterruptionChartComponent } from './components/charts/cross-type-statistics/cross-interruption-chart/cross-interruption-chart.component';
import { SpeakerTimeChartComponent } from './components/charts/cross-type-statistics/speaker-time-chart/speaker-time-chart.component';

const backendUrl = new Globals().backendUri;

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LoginComponent,
    UserdetailviewComponent,
    SnackbarComponent,
    UserListComponent,
    PasswordDialogComponent,
    OrganisationDetailViewComponent,
    ConfirmDialogComponent,
    CategoriesComponent,
    LogviewComponent,
    InterruptionAddComponent,
    NoteAddDialogComponent,
    BasicChartComponent,
    MeetingDetailViewComponent,
    LogDetailViewComponent,
    MeetingCreateDialogComponent,
    DataManagementViewComponent,
    GroupCreateUpdateDialogComponent,
    GroupsComponent,
    MeetingTemplatesComponent,
    MeetingTemplatesCreateUpdateDialogComponent,
    MeetingViewComponent,
    ParticipantViewComponent,
    ParticipantDialogComponent,
    LogCompareViewComponent,
    SpeakerSwitchDialogComponent,
    InterruptionChartComponent,
    MeetingPublicViewComponent,
    MeetingPublicDialogComponent,
    SideComponent,
    LogMultiSessinStatisticComponent,
    InterruptionBarCharComponent,
    CloneExistingMeetingDialogComponent,
    CrossTypeStatisticsComponent,
    CrossInterruptionChartComponent,
    SpeakerTimeChartComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatStepperModule,
    NgPipesModule,
    MatListModule,
    MatSlideToggleModule,
    MatExpansionModule,
    DragDropModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatTabsModule,
    MatTreeModule,
    MatTooltipModule,
    ClipboardModule,
    QRCodeModule,
    MatToolbarModule,
    MatSidenavModule,
    FlexLayoutModule
  ],
  providers: [
    { provide: BASE_PATH, useValue: backendUrl },
    {provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl},
    {provide: MAT_DATE_LOCALE, useValue: 'de-AT'},
    {provide: DateAdapter, useClass: CustomDateAdapter },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function(router: Router) {
        return new ForbiddenInterceptor(router);
      },
      multi: true,
      deps: [Router]
   },
    httpInterceptorProviders, LoginEndpointService,
    UserEndpointService, SnackbarComponent,
    NotificationServiceService, ValidatorService,
    ThemeEndpointService, OrganisationEndpointService,
    CategoryEndpointService, MeetingTemplateEndpointService,
    LogEndpointService, MeetingEndpointService, NoteEndpointService,
    GroupEndpointService, ParticipantEndpointService, PublicEndpointService, NewLogoService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
